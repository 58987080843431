import { Button, Divider, Group, SimpleGrid, Skeleton, Stack, Title } from "@mantine/core";
import { API, DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { List as ListIcon, Search } from "tabler-icons-react";
import Statistics from "../../components/Statistics";
import { nearbyJobOffers } from "../../graphql/queries";
import { ROUTE_JOBS, ROUTE_USER_APPLICATIONS, ROUTE_USER_APPLICATIONS_SHOW } from "../../helpers/Routes";
import { fetchStats } from "../../helpers/Datastore";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useUserState } from "../../helpers/GlobalUserState";
import { JobApplication, User } from "../../models";
import { useTranslation } from 'react-i18next';
import UserDataAlert from "../../components/UserDataAlert";
import JobsCarousel from "../../components/JobsCarousel";
import ApplicationsCarousel, { APPLICATION_CAROUSEL_TYPE_USER } from "../../components/ApplicationsCarousel";

/**
 * user home page
 * @returns JSX
 */
export default function PageUserHome() {

    const user = useUserState();
    const setError = useErrorDispatch();
    const [jobs, setJobs] = useState(undefined);
    const [applications, setApplications] = useState(undefined);
    const [applicationStats, setApplicationStats] = useState(undefined);
    const [userData, setUserData] = useState(null);
    const { t } = useTranslation();

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        try {
            // fetch data
            const userData = await DataStore.query(User, user.id);
            const jobs = await fetchJobs(userData);
            const applications = await fetchApplications();
            const applicationStats = await fetchStats(JobApplication, user.id, "userId");

            // set data
            setJobs(jobs);
            setApplications(applications);
            setApplicationStats(applicationStats);
            setUserData(userData);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /**
     * fetches the latest jobs
     */
    const fetchJobs = async (userData) => {
        const result = await API.graphql({
            query: nearbyJobOffers,
            variables: {
                location: userData ? {
                    lat: userData.location.lat,
                    lon: userData.location.lon
                } : null,
                km: userData ? 500 : null,
                sortId: "created_desc",
                limit: 20,
            },
        });
        return result.data.nearbyJobOffers.items;
    }

    /**
     * fetches the applications
     */
    const fetchApplications = async () => {
        // fetch applications
        const applications = await DataStore.query(
            JobApplication,
            (c) => c.and(c => [
                c.userId.eq(user.id),
            ]),
            {
                sort: s => s.createdAt(SortDirection.DESCENDING),
                limit: 20
            }
        );

        // fetch details
        const applicationsData = [];
        for (const application of applications) {
            const jobOffer = await application.jobOffer;
            const company = await jobOffer.company;
            applicationsData.push({
                ...application,
                jobOffer: jobOffer,
                company: company,
            })
        }

        // return fetched items
        return applicationsData;
    }

    // skeletons for loading
    if (!jobs || !applications || !applicationStats || userData === null) {
        return (
            <Stack>
                <Skeleton height={36} />
                <Skeleton height={465.5} />
                <Skeleton height={1} />
                <Skeleton height={36} />
                <Skeleton height={185} />
                <SimpleGrid
                    cols={{ base: 1, md: 2, lg: 3 }}
                >
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
            </Stack>
        )
    }

    return (
        <Stack>
            <UserDataAlert />
            <Group justify="space-between">
                <Title order={3}>{t("job.new")}</Title>
                <Link to={ROUTE_JOBS}><Button leftSection={<Search size={14} />} color="blue" variant="outline">{t("job.find")}</Button></Link>
            </Group>
            <JobsCarousel jobs={jobs} />
            <Divider />

            <Group justify="space-between">
                <Title order={3}>{t("application.applications")}</Title>
                <Link to={ROUTE_USER_APPLICATIONS}><Button leftSection={<ListIcon size={14} />} color="blue" variant="outline">{t("application.applications")}</Button></Link>
            </Group>
            <ApplicationsCarousel 
                applications={applications} 
                listRoute={ROUTE_USER_APPLICATIONS} 
                detailRoute={ROUTE_USER_APPLICATIONS_SHOW} 
                type={APPLICATION_CAROUSEL_TYPE_USER}
            />
            <Statistics stats={applicationStats} />
        </Stack>
    )
}