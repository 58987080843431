import { API, Auth } from "aws-amplify";

// globals
export const API_MODE_GET = "GET";
export const API_MODE_POST = "POST";

// admin queries API
export const ADMIN_API_NAME = "AdminQueries";
export const ADMIN_API_COMPANY_GETUSER = "/company/getUser";
export const ADMIN_API_COMPANY_LISTUSERS = "/company/listUsers";
export const ADMIN_API_COMPANY_CREATEUSER = "/company/createUser";
export const ADMIN_API_COMPANY_SETUSERADMIN = "/company/setUserAdmin";
export const ADMIN_API_COMPANY_SETUSERENABLED = "/company/setUserEnabled";
export const ADMIN_API_COMPANYFILES_URL_DOWNLOAD = "/companyfiles/url/download";
export const ADMIN_API_COMPANYFILES_URL_UPLOAD = "/companyfiles/url/upload";
export const ADMIN_API_COMPANYFILES_DELETE = "/companyfiles/delete";
export const ADMIN_API_COMPANYFILES_APPLICATION_URL = "/companyfiles/application/file/url";
export const ADMIN_API_SUPPORT_GETUSER = "/support/getUser";
export const ADMIN_API_SUPPORT_LISTUSERSFORCOMPANY = "/support/listUsersForCompany";
export const ADMIN_API_SUPPORT_SETCOMPANYADMIN = "/support/setCompanyAdmin";
export const ADMIN_API_SUPPORT_ENABLEUSER = "/support/enableUser";
export const ADMIN_API_SUPPORT_DISABLEUSER = "/support/disableUser";
export const ADMIN_API_SUPPORT_CREATECOMPANYUSER = "/support/createCompanyUser";
export const ADMIN_API_ADMIN_LISTSUPPORTUSERS = "/admin/listSupportUsers";
export const ADMIN_API_ADMIN_ENABLEUSER = "/enableUser";
export const ADMIN_API_ADMIN_DISABLEUSER = "/disableUser";
export const ADMIN_API_ADMIN_ADDUSERTOGROUP = "/addUserToGroup";
export const ADMIN_API_ADMIN_REMOVEUSERFROMGROUP = "/removeUserFromGroup";
export const ADMIN_API_ADMIN_GETUSER = "/getUser";
export const ADMIN_API_ADMIN_CREATESUPPORTUSER = "/admin/createSupportUser";

// payment queries API
export const QUERIES_API_NAME = "PaymentQueries";
export const QUERIES_API_PRODUCTS_LIST = "/products/list";
export const QUERIES_API_CHECKOUT_SESSION = "/checkout/session";
export const QUERIES_API_CUSTOMERPORTAL_SESSION = "/customerportal/session";

export async function executeApiCall(api, mode, route, body) {
    // construct params
    let params = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
        },
        body: mode === API_MODE_POST ? body : null,
        queryStringParameters: mode === API_MODE_GET ? body : null,
    }

    // execute call
    if (mode === API_MODE_GET) {
        return await API.get(api, route, params);
    }
    else if (mode === API_MODE_POST) {
        return await API.post(api, route, params);
    }
    else {
        throw new Error("API mode unknown");
    }
}