import { Text, Button, Stack, Title, Paper, TextInput, PasswordInput, Group } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth, DataStore } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_STRING } from "../helpers/Validation";
import { useForm } from "./Form";
import { ROUTE_HOME, ROUTE_PASSWORD_FORGOT, ROUTE_PASSWORD_FORGOT_CONFIRM, ROUTE_SIGNIN_CHANGEPASSWORD } from "../helpers/Routes";
import { BC_TYPE_USERCHANGE, MFA_TOTP } from "../helpers/Constants";
import { ROUTE_SIGNUP_CONFIRM } from "../helpers/Routes";
import { useLocation } from "react-router-dom";
import { USER_SET, useUserDispatch, useUserState } from "../helpers/GlobalUserState";
import { useEffect, useState } from "react";
import { clearDataStore } from "../helpers/Datastore";
import Broadcaster from "./Broadcaster";
import { useTranslation } from 'react-i18next';
import { fetchUserDetails } from "./AppShellWrapper";

// validation schema with yup
const validationSchemaLogin = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    password: VALIDATION_SCHEMA_STRING,
});
const validationSchemaMfa = Yup.object().shape({
    code: VALIDATION_SCHEMA_STRING
});

/**
 * social provider sign in buttons
 * @returns JSX
 */
const SignInEmail = () => {

    // globals
    const navigate = useNavigate();
    const location = useLocation();
    const user = useUserState();
    const { t } = useTranslation();
    const setUser = useUserDispatch();
    const [mfaNeeded, setMfaNeeded] = useState(false);

    /** 
     * use effect hook 
     */
    useEffect(() => {
        if (user.id) {
            redirectAfterLogin()
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * submit callback of login form
     * @param {object} values form values
     */
    const submitCallbackLogin = async (values) => {
        try {
            const loginResult = await Auth.signIn(values.email.toLowerCase(), values.password);

            if (loginResult.challengeName === "NEW_PASSWORD_REQUIRED") {
                showNotification({ message: t("auth.password.change.pending"), color: 'green', icon: <Check /> });
                navigate(ROUTE_SIGNIN_CHANGEPASSWORD, { state: { email: values.email }});
            }
            else if (loginResult.challengeName === MFA_TOTP) {
                setMfaNeeded(true);
            }
            else {
                await clearDataStore();

                Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: "new" });
                await DataStore.start();

                const user = await fetchUserDetails();
                setUser({
                    action: USER_SET,
                    values: user
                });
                showNotification({ message: t("auth.signin.message"), color: 'green', icon: <Check /> });
                redirectAfterLogin();
            }
        }
        catch (e) {
            if (e.code === "NotAuthorizedException" || e.code === "UserNotFoundException") {
                showNotification({ message: t("auth.error.credentials_invalid"), color: 'red', icon: <X /> });
            }
            else if (e.code === "PasswordResetRequiredException") {
                navigate(ROUTE_PASSWORD_FORGOT_CONFIRM, { state: { email: values.email }});
            }
            else if (e.code === "UserNotConfirmedException") {
                showNotification({ message: t("auth.confirmation.email"), color: 'red', icon: <X /> });
                navigate(ROUTE_SIGNUP_CONFIRM, { state: { email: values.email }});
            }
            else {
                throw e;
            }
        }
    }

    /**
     * submit callback to confirm sign in with mfa
     * @param {object} values form values
     */
    const submitCallbackMfa = async (values) => {
        try {
            // confirm with mfa
            const user = await Auth.signIn(formLogin.values.email.toLowerCase(), formLogin.values.password);
            await clearDataStore();
            await Auth.confirmSignIn(user, values.code, MFA_TOTP);
            await DataStore.start();

            // redirect
            showNotification({ message: t("auth.signin.message"), color: 'green', icon: <Check /> });
            navigate(ROUTE_HOME);
        }
        catch (e) {
            if (e.code === "CodeMismatchException") {
                showNotification({ message: t("auth.error.mfa"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    /**
     * wrapper to redirect to next page after login
     */
    const redirectAfterLogin = () => {
        if (location.state?.from) {
            navigate(location.state.from);
        }
        else {
            navigate(ROUTE_HOME);
        }
    }

    // form hook for login
    const formLogin = useForm({
        validationSchema: validationSchemaLogin,
        initialValues: {
            email: location.state?.email ? location.state.email : "",
            password: "",
        },
        submitCallback: submitCallbackLogin
    });

    // form hook for mfa
    const formMfa = useForm({
        validationSchema: validationSchemaMfa,
        initialValues: {
            code: ""
        },
        submitCallback: submitCallbackMfa
    })

    return (
        <>
            {mfaNeeded === false ?
                <form
                    onSubmit={formLogin.onSubmit()}
                    onReset={formLogin.onReset}
                >
                    <Stack>
                        <TextInput
                            withAsterisk
                            label={t("general.email")}
                            placeholder="name@email.com"
                            {...formLogin.getInputProps('email')}
                        />
                        <PasswordInput
                            withAsterisk
                            label={t("auth.password.password")}
                            placeholder={t("auth.password.password")}
                            {...formLogin.getInputProps('password')}
                        />
                    </Stack>
                    <Group justify="flex-end" mt={5}>
                        <Link to={ROUTE_PASSWORD_FORGOT} state={{ email: formLogin.values.email }}>
                            <Text className="pointer" td="underline" c="dimmed" size="sm" align="center">
                                {t("auth.password.forgot")}
                            </Text>
                        </Link>
                    </Group>
                    <Button type="submit" fullWidth mt="md">{t("auth.signin.text")}</Button>
                </form>
                :
                <form
                    onSubmit={formMfa.onSubmit()}
                    onReset={formMfa.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center" mb="md">{t("auth.mfa.confirm.title")}</Title>
                        <Stack>
                            <Text size="sm" c="dimmed" align="center">
                                {t("auth.mfa.confirm.confirm")}
                            </Text>
                            <PasswordInput
                                withAsterisk
                                label={t("auth.mfa.code")}
                                placeholder={t("auth.mfa.code")}
                                {...formMfa.getInputProps('code')}
                            />
                        </Stack>
                        <Button type="submit" fullWidth mt="md">{t("auth.signin.text")}</Button>
                    </Paper>
                </form>
            }
        </>
    );
}

// Default export of the class
export default SignInEmail;