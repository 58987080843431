import { ActionIcon, Box, Divider, Fieldset, Grid, Stack, Text, Title, UnstyledButton } from "@mantine/core";
import { useTranslation } from "react-i18next"
import { useUserState } from "../../helpers/GlobalUserState";
import { useEffect, useState } from "react";
import { Auth, DataStore } from "aws-amplify";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { Company, CompanyUser, User } from "../../models";
import { BuildingSkyscraper, Check, Edit, UserCircle } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTE_COMPANY_DETAILS, ROUTE_COMPANY_USER_PROFILE_EDIT, ROUTE_USER_PROFILE_EDIT } from "../../helpers/Routes";
import Avatar from "../../components/Avatar";
import { MFA_NONE, MFA_TOTP, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_USER } from "../../helpers/Constants";
import { formatDate } from "../../helpers/Moment";
import ModalChangeEmail from "../../components/ModalChangeEmail";
import ModalChangePassword from "../../components/ModalChangePassword";
import ModalActivateMfa from "../../components/ModalActivateMfa";
import { showNotification } from "@mantine/notifications";

/**
 * implementation of user profile page
 * @returns JSX
 */
export default function PageGeneralUserProfile() {

    // globals
    const { t } = useTranslation();
    const user = useUserState();
    const [userData, setUserData] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [mfaMode, setMfaMode] = useState(null);
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();
    const userIsCompany = (user.userGroup === USERGROUP_COMPANY || user.userGroup === USERGROUP_COMPANYADMIN);
    const userIsUser = (user.userGroup === USERGROUP_USER);
    const [changeEmailModalOpened, setChangeEmailModalOpened] = useState(false);
    const [changePasswordModalOpened, setChangePasswordModalOpened] = useState(false);
    const [activateMfaModalOpened, setActivateMfaModalOpened] = useState(false);

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (userIsCompany) {
                // get company user
                const userData = await DataStore.query(CompanyUser, user.id);
                if (!userData) {
                    // company user data not found
                    throw new Error(t("error.user"));
                }

                // get company
                const companyData = await DataStore.query(Company, user.companyId);
                if (!companyData) {
                    throw new Error(t("error.company"));
                }

                setUserData(userData);
                setCompanyData(companyData);
            }
            else if (userIsUser) {
                // get user
                const userData = await DataStore.query(User, user.id);
                if (!userData) {
                    // user data not found
                    navigate(ROUTE_USER_PROFILE_EDIT);
                }
                setUserData(userData);
            }

            // get mfa
            const authUser = await Auth.currentAuthenticatedUser();
            const mfaMode = await Auth.getPreferredMFA(authUser, { bypassCache: false });
            setMfaMode(mfaMode);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        preparePage().finally(() => {
            setLoading(LOADING_RESET);
        });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to render data
     * @param {string} title title of section
     * @param {array} items items to render
     * @param {callback?} action optional action callback to show action button and call callback if pressed
     * @returns 
     */
    const renderData = (title, items, action) => {
        return (
            <Fieldset legend={t(title)}>
                <Grid>
                    <Grid.Col span="auto">
                        <Stack>
                            {items.map((e, i) => (
                                <Grid gutter="xs" key={i}>
                                    <Grid.Col span={{ base: 12, md: 4, lg: 3 }}>
                                        <Text fw={700}>{t(e.label)}</Text>
                                    </Grid.Col>
                                    <Grid.Col span="content" style={{ "--col-max-width": "100%", "--col-flex-basis": "unset" }}>
                                        <Text lineClamp={5} style={{ "wordBreak": "break-word" }} >{e.value}</Text>
                                        {e.edit &&
                                            <UnstyledButton
                                                onClick={e.edit}
                                            >
                                                <Text c="blue" size="xs">{e.editText ? t(e.editText) : t("general.change")}</Text>
                                            </UnstyledButton>
                                        }
                                    </Grid.Col>
                                </Grid>
                            ))}
                        </Stack>
                    </Grid.Col>
                    {action ?
                        <Grid.Col span="content">
                            <ActionIcon variant="outline" onClick={action}>
                                <Edit />
                            </ActionIcon>
                        </Grid.Col>
                        :
                        <Grid.Col span="content">
                            <Box w={28}></Box>
                        </Grid.Col>
                    }
                </Grid>
            </Fieldset>
        )
    }

    /**
     * wrapper to change MFA depending on current state
     */
    const changeMfa = async () => {
        if (mfaMode === MFA_NONE) {
            // show activate modal
            setActivateMfaModalOpened(true);
        }
        else {
            // deactivate
            try {
                setLoading(LOADING_SHOW);
                const user = await Auth.currentAuthenticatedUser();
                await Auth.setPreferredMFA(user, MFA_NONE);
                showNotification({ message: t("auth.mfa.deactivated"), color: 'green', icon: <Check /> });
                setMfaMode(MFA_NONE);
            }
            catch (e) {
                setError({ action: ERROR_SHOW, error: e });
            }
            finally {
                setLoading(LOADING_RESET);
            }
        }
    }

    // first load data
    if (userIsUser && !userData) {
        return null;
    }
    else if (userIsCompany && (!companyData || !userData)) {
        return null;
    }
    else if (mfaMode === null) {
        return null;
    }

    return (
        <Stack>
            <Title>{t("user.profile.profile")}</Title>
            <Divider />

            {renderData(
                "general.account",
                [
                    { label: "general.email", value: user.email, edit: () => setChangeEmailModalOpened(true) },
                    { label: "auth.password.password", value: "✲✲✲✲✲✲✲✲", edit: () => setChangePasswordModalOpened(true) },
                    { label: "auth.mfa.mfa", value: mfaMode === MFA_TOTP ? t("general.yes") : t("general.no"), edit: () => changeMfa(), editText: mfaMode === MFA_TOTP ? t("general.deactivate") : t("general.activate") },
                ],
            )}

            {userIsCompany &&
                <>
                    {renderData(
                        "auth.account.personal",
                        [
                            {
                                label: "general.picture",
                                value: <Avatar
                                    size={100}
                                    identityId={userData.companyId}
                                    fileKey={userData.avatar}
                                    fileProtectionLevel={"private"}
                                    placeholder={UserCircle}
                                />
                            },
                            { label: "general.name", value: userData.name },
                            { label: "application.notification.label", value: userData.applicationMailNotification ? t("general.yes") : t("general.no") }
                        ],
                        () => navigate(ROUTE_COMPANY_USER_PROFILE_EDIT)
                    )}

                    {renderData(
                        "company.data",
                        [
                            {
                                label: "general.picture",
                                value: <Avatar
                                    size={100}
                                    identityId={companyData.id}
                                    fileKey={companyData.logo}
                                    fileProtectionLevel={"protected"}
                                    placeholder={BuildingSkyscraper}
                                />
                            },
                            { label: "general.name", value: companyData.name },
                            { label: "general.email", value: companyData.email },
                            { label: "user.phone", value: companyData.phonenumber },
                            { label: "general.address", value: companyData.addressText },
                        ],
                        () => navigate(ROUTE_COMPANY_DETAILS)
                    )}
                </>
            }

            {userIsUser &&
                renderData(
                    "auth.account.personal",
                    [
                        {
                            label: "general.picture",
                            value: <Avatar
                                size={100}
                                identityId={userData.identityId}
                                fileKey={userData.avatar}
                                fileProtectionLevel={"private"}
                                placeholder={UserCircle}
                            />
                        },
                        { label: "general.name", value: userData.name },
                        { label: "date.birth", value: formatDate(userData.birthdate, "L") },
                        { label: "user.phone", value: userData.phonenumber },
                        { label: "general.address", value: userData.addressText },
                        { label: "user.documents.documents", value: (userData.documents && userData.documents.length > 0) ? t("general.yes") : t("general.no") },
                    ],
                    () => navigate(ROUTE_USER_PROFILE_EDIT)
                )
            }

            <ModalChangeEmail opened={changeEmailModalOpened} closeCallback={() => setChangeEmailModalOpened(false)} />
            <ModalChangePassword opened={changePasswordModalOpened} closeCallback={() => setChangePasswordModalOpened(false)} />
            <ModalActivateMfa
                opened={activateMfaModalOpened}
                closeCallback={(mfaMode) => {
                    setMfaMode(mfaMode);
                    setActivateMfaModalOpened(false);
                }}
            />
        </Stack>
    )
}