import { ActionIcon, Divider, Grid, Group, Input, Select, TextInput } from "@mantine/core"
import InputBackground from "./InputBackground";
import { Trash } from "tabler-icons-react";
import { useEffect, useRef, useState } from "react";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { Language } from "../models";
import { DataStore, SortDirection } from "aws-amplify";
import { useTranslation } from "react-i18next";

/**
 * input wrapper for translations
 * @param {object} form the form object to render the input within
 * @param {string} label The label for the input 
 * @param {string} description the description to show for the input 
 * @returns 
 */
const TranslationsInput = ({ form, label, description }) => {

    // globals
    const [languages, setLanguages] = useState([]);
    const [filteredLanguages, setFilteredLanguages] = useState([]);
    const setError = useErrorDispatch();
    const subscription = useRef(null);
    const { t } = useTranslation();

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        subscription.current = DataStore.observeQuery(
            Language,
            null,
            { sort: s => s.code(SortDirection.ASCENDING) }
        ).subscribe({
            next(snapshot) {
                setLanguages(snapshot.items);
            },
            error(err) {
                setError({ action: ERROR_SHOW, error: err });
            }
        });

        return () => {
            subscription.current?.unsubscribe();
            subscription.current = null;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * Use effect hook to update selectable languages, when languages ref and form values get set or updated
     */
    useEffect(() => {
        if (languages) {
            filterLanguages(languages);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [languages, form.values.translations]
    );

    /**
     * filters the already added languages
     * @param {array} languages array of languages
     */
    const filterLanguages = (languages) => {
        const filteredLanguages = languages.filter(language => {
            const foundTranslation = (form.values.translations || []).find((translation) => {
                return language.code === translation.languageCode;
            });
            return foundTranslation ? false : true;
        });
        const mappedLanguages = filteredLanguages.map((language) => {
            return { value: language.code, label: `${language.code} (${language.name}, ${language.nameNative})` }
        })
        setFilteredLanguages(mappedLanguages);
    }

    // wrapper to render items
    const renderItems = (form.values.translations || []).map((item, index) => {

        // get language info by language code
        const language = (languages || []).find(language => language.code === item.languageCode);

        // render item
        return (
            <div key={item.languageCode}>
                <Grid>
                    <Grid.Col span="auto">
                        <Grid>
                            <Grid.Col span={{ base: 12, md: 12, lg: "content" }}>
                                <TextInput
                                    placeholder={t("general.code")}
                                    withAsterisk
                                    {...form.getInputProps(`translations.${index}.languageCode`)}
                                    value={language ? `${language.code} (${language.name}, ${language.nameNative})` : item.languageCode}
                                    disabled
                                    styles={() => ({
                                        input: {
                                            color: "inherit !important"
                                        },

                                    })}
                                />
                            </Grid.Col>
                            <Grid.Col span={{ base: 12, md: 12, lg: "auto" }}>
                                <TextInput
                                    placeholder={t("translation.translation")}
                                    withAsterisk
                                    {...form.getInputProps(`translations.${index}.text`)}
                                />
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                    <Grid.Col span="content">
                        <ActionIcon
                            color="red"
                            onClick={() => form.removeListItem("translations", index)}
                            mt={5}
                            variant="outline"
                        >
                            <Trash />
                        </ActionIcon>
                    </Grid.Col>
                </Grid>
                {index < form.values.translations.length - 1 &&
                    <Divider my="sm" />
                }
            </div>
        )
    });

    return (
        <Input.Wrapper error={form.errors?.translations}>
            <Input.Label>{label || t("translation.translations")}</Input.Label>
            {description &&
                <Input.Description mb={5}>{description}</Input.Description>
            }
            <InputBackground error={form.errors?.translations}>
                <Group>
                    <Select
                        label={t("translation.add")}
                        description={t("translation.description")}
                        styles={() => ({
                            root: {
                                flexGrow: 1
                            }
                        })}
                        placeholder={languages.length === 0 ? "" : "..."}
                        data={filteredLanguages}
                        value={null}
                        onChange={(value) => {
                            form.insertListItem('translations', { languageCode: value, text: "" });
                        }}
                        mb={(form.values.translations && form.values.translations.length > 0) ? "md" : 0}
                        disabled={filteredLanguages.length === 0}
                    />
                </Group>
                {(form.values.translations && form.values.translations.length > 0) &&
                    <InputBackground>
                        {renderItems}
                    </InputBackground>
                }

            </InputBackground>
        </Input.Wrapper>
    )
}

// default export of the class
export default TranslationsInput;