import { Clock } from "tabler-icons-react";
import BadgeAutoHeight from "./BadgeAutoHeight";
import { formatDate } from "../helpers/Moment";

/**
 * timestamp badge
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeTimestamp(props) {
    return (
        <BadgeAutoHeight
            color="gray"
            variant="outline"
            leftSection={<Clock size={14} />}
            size="xs"
            style={props.style}
        >
            {props.label && `${props.label} `}
            {formatDate(props.time, "L LT")}
        </BadgeAutoHeight>
    )
}