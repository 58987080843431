import { TimeUnit, JobApplicationCompanyDataStatus } from "../models";

// HTML tags
export const HTML_TITLE = "UnitedTalents24";

// URLs
export const URL_SUPPORT_PORTAL = "https://genius-tech.atlassian.net/servicedesk/customer/portal/14";

// user groups
export const USERGROUP_USER = "User";
export const USERGROUP_COMPANY = "Company";
export const USERGROUP_COMPANYADMIN = "CompanyAdmin";
export const USERGROUP_SUPPORT = "Support";
export const USERGROUP_ADMIN = "Admin";

// user group select options
export const USERGROUPS_ALL = "general.all";
export const USERGROUPS_COMPANY = [
    USERGROUP_COMPANY,
    USERGROUP_COMPANYADMIN,
]
export const USERGORUPS_COMPANY_WITHALL = [
    USERGROUPS_ALL,
    ...USERGROUPS_COMPANY,
]
export const USERGROUPS = [
    USERGROUP_USER,
    ...USERGROUPS_COMPANY,
    USERGROUP_ADMIN
]
export const USERGROUPS_WITHALL = [
    USERGROUPS_ALL,
    ...USERGROUPS,
]

// file types
export const FILE_SOURCE_INIT = "INIT";
export const FILE_SOURCE_SELECTED = "SELECTED";
export const FILE_TYPE_IMAGE = "image";
export const FILE_TYPE_PDF = "pdf";
export const FILE_TYPE_ACCEPT = {
    "image": [ "image/jpeg", "image/jpg", "image/png" ],
    "pdf": [ "application/pdf" ],
}

// table
export const TABLE_ACTION_WIDTH = 50;
export const TABLE_ACTION_SIZE = 25;

// predicates
export const PREDICATE_CONTAINS = "CONTAINS";
export const PREDICATE_EQUALS = "EQ";
export const PREDICATE_GE = "GE";
export const PREDICATE_LT = "LT";

// avatar
export const AVATAR_SIZE = 150;
export const AVATAR_RADIUS = "md";

// time unit translations
export const TIME_UNIT_TEXT = {
    "DAY": "unit.time.day",
    "WEEK": "unit.time.week",
    "MONTH": "unit.time.month",
}
export const TIME_UNITS = () => {
    return Object.keys(TimeUnit).map((k) => {
        return { value: k, label: TIME_UNIT_TEXT[k] };
    });
}

// job application company data status texts
export const JOBAPPLICATION_COMPANYDATA_STATUS_TEXT = {
    "NEW": "application.status.new",
    "UNLOCKED": "application.status.unlocked",
    "INPROGRESS": "application.status.in_progress",
    "WAITING": "application.status.waiting",
    "INVITED": "application.status.invited",
    "DECLINED": "application.status.declined",
    "ACCEPTED": "application.status.accepted",
    "HIRED": "application.status.hired",
    "DELETED": "application.status.deleted",
}
export const JOBAPPLICATION_COMPANYDATA_STATUS_TEXTS = Object.keys(JobApplicationCompanyDataStatus).map((k) => {
    return { value: k, label: JOBAPPLICATION_COMPANYDATA_STATUS_TEXT[k] };
});
export const JOBAPPLICATION_COMPANYDATA_STATUS_COLOR = {
    "NEW": "gray",
    "UNLOCKED": "blue",
    "INPROGRESS": "yellow",
    "WAITING": "yellow",
    "INVITED": "yellow",
    "DECLINED": "red",
    "ACCEPTED": "green",
    "HIRED": "green",
    "DELETED": "gray",
}

// misc
export const OPTION_YES = "general.yes";
export const OPTION_NO = "general.no";
export const OPTION_ALL = "general.all";
export const OPTIONS_YESNO = [
    OPTION_YES,
    OPTION_NO
];
export const OPTIONS_YESNO_WITHALL = [
    OPTION_ALL,
    ...OPTIONS_YESNO,
];
export const OPTIONS_YES_NO_WITHALL_BOOLEAN = [
    { value: null, label: OPTION_ALL },
    { value: true, label: OPTION_YES },
    { value: false, label: OPTION_NO },
];

// broadcasts
export const BC_CHANNEL = "jobportal_gt_bc";
export const BC_TYPE_USERCHANGE = "bc_userchange";

// MFA options
export const MFA_NONE = "NOMFA"; 
export const MFA_TOTP = "SOFTWARE_TOKEN_MFA";
