import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalLoadingProvider from "../helpers/GlobalLoadingState";
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import AppShellWrapper from './AppShellWrapper';
import LoadingOverlay from './LoadingOverlay';
import GlobalErrorProvider from '../helpers/GlobalErrorState';
import ErrorOverlay from './ErrorOverlay';
import GlobalUserProvider, { useUserState } from '../helpers/GlobalUserState';
import { BC_TYPE_USERCHANGE } from '../helpers/Constants';
import * as Sentry from "@sentry/react";
import { ModalsProvider } from '@mantine/modals';
import { Hub, DataStore } from 'aws-amplify';
import Broadcaster from './Broadcaster';
import ScrollToTop from '../helpers/ScrollToTop';
import CookieConsent from 'react-cookie-consent';
import { Routes } from '../helpers/Routes';
import { useTranslation } from 'react-i18next';
import awsConfig from './../aws-exports';

// get amplify env
const amplifyEnv = awsConfig.aws_user_files_s3_bucket.match(/.*-(\w+)/)[1]

// sentry integration
if (process.env.NODE_ENV === "production" && amplifyEnv === "prod") {
    Sentry.init({
        dsn: "https://e50410f98ab545898c5a54776fe08099@o672392.ingest.sentry.io/4504469090992128",
        normalizeDepth: 20,
    });
}

/**
 * Implementation of main app
 */
export default function AppWrapper() {
    const [dataStoreReady, setDataStoreReady] = useState(false);
    const datastoreListenerRef = useRef(null);
    const datastoreInitialzedBefore = useRef(false);
    const user = useUserState();
    const { t } = useTranslation();

    /**
     * before we do anything, we need to make sure that the datastore is initialized
     */
    useEffect(() => {
        // listen to Datastore events 
        datastoreListenerRef.current = Hub.listen('datastore', async hubData => {
            const { event, data } = hubData.payload;
            if (event === 'ready' || (event.toLowerCase().includes("outbox") && datastoreInitialzedBefore.current === true)) {
                setDataStoreReady(true);
                datastoreInitialzedBefore.current = true;
            }
            else if (event === "nonApplicableDataReceived") {
                Sentry.withScope(function (scope) {
                    scope.setExtra("event", event);
                    if (data) {
                        scope.setExtra("data.json", JSON.stringify(data));
                    }
                    Sentry.captureException(new Error("Datastore not able to initialize."));
                });
            }
            else {
                setDataStoreReady(false);
            }
        });

        // listener for browser messages
        Broadcaster.onmessage = function (ev) {
            if (ev.data.type === BC_TYPE_USERCHANGE && ev.data.id !== user.id) {
                setTimeout(() => { window.location.reload(); }, 500);
            }
        }

        // init datastore
        DataStore.start();

        // unsubscribe from datastore listener when unmounting
        return () => {
            if (datastoreListenerRef.current) {
                datastoreListenerRef.current();
            }
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * callback to inform other windows of user change
     */
    useEffect(() => {
        // when the user id changed, we need to send an event to other pages to reload
        Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: user.id });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    return (
        <GlobalErrorProvider>
            <GlobalLoadingProvider>
                <MantineProvider
                    defaultColorScheme="auto"
                    withGlobalStyles
                    withNormalizeCSS
                    theme={{
                        fontFamily: 'Halyard Display',
                        headings: { fontFamily: 'Halyard Display' },
                    }}
                >
                    {dataStoreReady ?
                        <>
                            <CookieConsent
                                buttonText={t("general.accept")}
                                cookieName="gt_jobportal_cookie"
                                expires={365}
                            >
                                {t("general.cookies_message")}
                            </CookieConsent>
                            <Notifications />
                            <ModalsProvider>
                                <Router>
                                    <ScrollToTop />
                                    <ErrorOverlay />
                                    <LoadingOverlay>
                                        <GlobalUserProvider>
                                            <AppShellWrapper>
                                                {Routes}
                                            </AppShellWrapper>
                                        </GlobalUserProvider>
                                    </LoadingOverlay>
                                </Router>
                            </ModalsProvider>
                        </>
                        :
                        <LoadingOverlay loading={true} />
                    }
                </MantineProvider>
            </GlobalLoadingProvider>
        </GlobalErrorProvider>
    )
};
