import { Center, Divider, Paper, SimpleGrid, Skeleton, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { CartesianGrid, Cell, Legend, Line, LineChart, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import Statistics from "../../components/Statistics";
import { CustomizedPieLegend, CustomizedRechartsDot, CustomizedRechartsLineChartTooltip, CustomizedRechartsXAxisTick, CustomizedRechartsYAxisTick, RenderCustomizedLabel } from "../../components/CustomRechartsComponents";
import { fetchStats } from "../../helpers/Datastore";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useUserState } from "../../helpers/GlobalUserState";
import moment, { formatDate } from "../../helpers/Moment";
import { JobApplicationCompanyData, JobCategory } from "../../models";
import { ROUTE_COMPANY_APPLICATIONS, ROUTE_COMPANY_APPLICATIONS_EDIT } from "../../helpers/Routes";
import { useTranslation } from "react-i18next";
import { getTextFromTranslations } from "../../helpers/i18n";
import ApplicationsCarousel, { APPLICATION_CAROUSEL_TYPE_COMPANY } from "../../components/ApplicationsCarousel";

/**
 * company home page
 * @returns JSX
 */
export default function PageCompanyHome() {

    // globals
    const theme = useMantineTheme();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`);
    const user = useUserState();
    const setError = useErrorDispatch();
    const [applications, setApplications] = useState(undefined);
    const [applicationStats, setApplicationStats] = useState(undefined);
    const [applicationsPerMonth, setApplicationsPerMonth] = useState(undefined);
    const [applicationsPerCategory, setApplicationsPerCategory] = useState(undefined);
    const { t } = useTranslation();

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        try {
            // fetch data
            const applications = await fetchApplications();
            const applicationStats = await fetchStats(JobApplicationCompanyData, user.companyId, "companyId");
            const applicationsPerMonth = await fetchApplicationsPerMonth();
            const applicationsPerCategory = await fetchApplicationsPerCategory();

            // set data
            setApplications(applications);
            setApplicationStats(applicationStats);
            setApplicationsPerMonth(applicationsPerMonth);
            setApplicationsPerCategory(applicationsPerCategory);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /**
     * fetches the latest applications
     */
    const fetchApplications = async () => {
        // fetch applications
        const applications = await DataStore.query(
            JobApplicationCompanyData,
            (c) => c.and(c => [
                c.companyId.eq(user.companyId),
            ]),
            {
                sort: s => s.createdAt(SortDirection.DESCENDING),
                limit: 20
            }
        );

        // fetch details
        const applicationsData = [];
        for (const applicationCompanyData of applications) {
            const jobOffer = await applicationCompanyData.jobOffer;
            applicationsData.push({
                ...applicationCompanyData,
                jobOffer: jobOffer,
            })
        }

        // return data
        return applicationsData;
    }

    /**
     * fetches the number of applications per category
     * @returns array with data
     */
    const fetchApplicationsPerCategory = async () => {
        // fetch raw data
        const categories = await DataStore.query(
            JobCategory,
            Predicates.ALL
        );
        const applications = await DataStore.query(
            JobApplicationCompanyData,
            (c) => c.companyId.eq(user.companyId)
        );
        if (applications.length === 0) {
            return [];
        }

        // aggregate data
        const data = {};
        categories.forEach(async e => {
            const name = await getTextFromTranslations(e.translations) || e.name;
            data[e.id] = { name: name, value: 0, color: e.color };
        });
        for (const application of applications) {
            const jobOffer = await application.jobOffer;
            data[jobOffer.jobCategoryId].value += 1;
        }

        // map and return data
        var mappedData = Object.keys(data).map((key) => {
            return {
                id: key,
                name: data[key].name,
                value: data[key].value,
                color: data[key].color
            };
        });
        mappedData = mappedData.filter((e) => {
            return e.value > 0;
        })
        mappedData = mappedData.sort((a, b) => {
            if (a.value < b.value) {
                return 1;
            }

            if (a.value > b.value) {
                return -1;
            }

            return a.name.localeCompare(b.name);
        })
        return mappedData;
    }

    /**
     * fetches the applications per month
     * @returns array with results
     */
    const fetchApplicationsPerMonth = async () => {
        // // fetch data
        const months = 13;
        const applications = await DataStore.query(
            JobApplicationCompanyData,
            (c) => c.and(c => [
                c.companyId.eq(user.companyId),
                c.createdAt.ge(moment().subtract(13, "months").format("YYYY-MM")),
            ])
        );

        // aggregate data
        const data = {};
        for (let i = months; i >= 0; i--) {
            const month = moment().subtract(i, "months").format("MMM YYYY");
            data[month] = 0;
        }
        applications.forEach((e) => {
            const month = formatDate(e.createdAt, "MMM YYYY");
            data[month] += 1;
        })

        // map and return data
        const mappedData = Object.keys(data).map((key) => {
            return { name: key, value: data[key] };
        });
        return mappedData;
    }

    // skeletons for loading
    if (!applications || !applicationStats || !applicationsPerCategory || !applicationsPerMonth) {
        return (
            <Stack>
                <SimpleGrid cols={{ base: 2, lg: 3 }}>
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
                <Skeleton height={223} />
                <Skeleton height={340} />
                <Skeleton height={540} />
            </Stack>
        );
    }

    return (
        <Stack>
            <Title order={3}>{t("application.applications")}</Title>
            <Statistics stats={applicationStats} />
            <ApplicationsCarousel 
                applications={applications} 
                listRoute={ROUTE_COMPANY_APPLICATIONS} 
                detailRoute={ROUTE_COMPANY_APPLICATIONS_EDIT} 
                type={APPLICATION_CAROUSEL_TYPE_COMPANY}
            />
            <Divider />

            <Title order={3}>{t("application.statistics.month")}</Title>
            <Paper
                withBorder
                shadow="md"
                radius="md"
            >
                <ResponsiveContainer height={300} width="100%">
                    <LineChart
                        data={applicationsPerMonth}
                        margin={{
                            top: 30,
                            right: 30,
                            left: 0,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="2 6" />
                        <XAxis dataKey="name" height={80} interval={0} tick={<CustomizedRechartsXAxisTick />} />
                        <YAxis allowDecimals={false} tick={<CustomizedRechartsYAxisTick />} />
                        <Tooltip content={<CustomizedRechartsLineChartTooltip />} />
                        <Line type="monotone" dataKey="value" stroke="#008833" dot={<CustomizedRechartsDot />} />
                    </LineChart>
                </ResponsiveContainer>
            </Paper>
            <Divider />

            <Title order={3}>{t("application.statistics.jobcategory")}</Title>
            <Paper
                withBorder
                shadow="md"
                radius="md"
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                {applicationsPerCategory?.length ?
                    <ResponsiveContainer height={mediaQueryLargerSm ? 500 : 600} width="100%">
                        <PieChart>
                            <Pie
                                data={applicationsPerCategory}
                                dataKey="value"
                                nameKey="name"
                                innerRadius="30%"
                                outerRadius="70%"
                                label={<RenderCustomizedLabel />}
                                legendType="circle"
                            >
                                {applicationsPerCategory.map((entry, index) => {
                                    return (<Cell style={{ outline: 'none' }} key={`cell-${index}`} fill={entry.color} />);
                                })}
                            </Pie>
                            <Legend
                                layout={mediaQueryLargerSm ? "vertical" : "horizontal"}
                                verticalAlign={mediaQueryLargerSm ? "middle" : "bottom"}
                                align={mediaQueryLargerSm ? "right" : "center"}
                                content={<CustomizedPieLegend />}
                            />
                            <Tooltip content={<CustomizedRechartsLineChartTooltip />} />
                        </PieChart>
                    </ResponsiveContainer>
                    :
                    <Center style={{ alignSelf: "center", flexGrow: 1 }}><Text size="sm" c="dimmed" p="md">{t("general.no_data")}</Text></Center>
                }
            </Paper>
        </Stack>
    )
}