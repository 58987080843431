import * as Yup from 'yup';
import { OPTIONS_YESNO, OPTIONS_YESNO_WITHALL, USERGORUPS_COMPANY_WITHALL, USERGROUPS_COMPANY } from './Constants';
import i18next from "./i18n";

// validation error messages
const VALIDATION_ERROR_REQUIRED = "validation.required";
const VALIDATION_ERROR_EMAIL = "validation.email";
const VALIDATION_ERROR_PASSWORD = "validation.password.password";
const VALIDATION_ERROR_PASSWORD_CONFIRM = "validation.password.confirm";
const VALIDATION_ERROR_PHONE = "validation.phone";
const VALIDATION_ERROR_ADDRESS = "validation.location";
const VALIDATION_ERROR_NUMBER_MIN_ZERO = "validation.number.min0";
const VALIDATION_ERROR_CURRENCY_CODE = "validation.currency.code";
const VALIDATION_ERROR_LANGUAGE_CODE = "validation.language.code";
const VALIDATION_ERROR_COUNTRY_CODE = "validation.language.country_code";
const VALIDATION_ERROR_DATE = "validation.date";

// templates for form validation
const VALIDATION_SCHEMA_NUMBER_MIN_ZERO = Yup.number(VALIDATION_ERROR_NUMBER_MIN_ZERO).typeError(VALIDATION_ERROR_NUMBER_MIN_ZERO).min(0, VALIDATION_ERROR_NUMBER_MIN_ZERO).required(VALIDATION_ERROR_NUMBER_MIN_ZERO);

// validation exports
export const VALIDATION_SCHEMA_STRING = Yup.string(VALIDATION_ERROR_REQUIRED).required(VALIDATION_ERROR_REQUIRED);
export const VALIDATION_SCHEMA_BOOL = Yup.bool(VALIDATION_ERROR_REQUIRED).required(VALIDATION_ERROR_REQUIRED);
export const VALIDATION_SCHEMA_EMAIL = Yup.string().required(VALIDATION_ERROR_REQUIRED).email(VALIDATION_ERROR_EMAIL);
export const VALIDATION_SCHEMA_PASSWORD = Yup.string().required(VALIDATION_ERROR_REQUIRED).matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/, VALIDATION_ERROR_PASSWORD);
export const VALIDATION_SCHEMA_PASSWORD_CONFIRMATION = Yup.string().required(VALIDATION_ERROR_REQUIRED).oneOf([Yup.ref('password'), null], VALIDATION_ERROR_PASSWORD_CONFIRM);
export const VALIDATION_SCHEMA_PHONENUMBER = Yup.string().required(VALIDATION_ERROR_REQUIRED).matches(/^\+?\d{10,}$/, VALIDATION_ERROR_PHONE);
export const VALIDATION_SCHEMA_LOCATION = Yup.object().shape({ lat: Yup.number(VALIDATION_ERROR_ADDRESS).typeError(VALIDATION_ERROR_ADDRESS).required(VALIDATION_ERROR_ADDRESS), lon: Yup.number(VALIDATION_ERROR_ADDRESS).typeError(VALIDATION_ERROR_ADDRESS).required(VALIDATION_ERROR_ADDRESS) });
export const VALIDATION_SCHEMA_ADDRESSTEXT = Yup.string().required(VALIDATION_ERROR_ADDRESS);
export const VALIDATION_SCHEMA_CURRENCY_CODE = Yup.string().required(VALIDATION_ERROR_CURRENCY_CODE).min(3, VALIDATION_ERROR_CURRENCY_CODE).max(3, VALIDATION_ERROR_CURRENCY_CODE);
export const VALIDATION_SCHEMA_LANGUAGE_CODE = Yup.string().required(VALIDATION_ERROR_LANGUAGE_CODE).min(2, VALIDATION_ERROR_LANGUAGE_CODE).max(5, VALIDATION_ERROR_LANGUAGE_CODE);
export const VALIDATION_SCHEMA_COUNTRY_CODE = Yup.string().required(VALIDATION_ERROR_COUNTRY_CODE).min(2, VALIDATION_ERROR_COUNTRY_CODE).max(2, VALIDATION_ERROR_COUNTRY_CODE);
export const VALIDATION_SCHEMA_HOURS = Yup.object().shape({ hours: VALIDATION_SCHEMA_NUMBER_MIN_ZERO, timeUnit: VALIDATION_SCHEMA_STRING });
export const VALIDATION_SCHEMA_SALARY = Yup.object().shape({ salary: VALIDATION_SCHEMA_NUMBER_MIN_ZERO, currencyCode: VALIDATION_SCHEMA_CURRENCY_CODE, timeUnit: VALIDATION_SCHEMA_STRING });
export const VALIDATION_SCHEMA_DATE = Yup.string(VALIDATION_ERROR_DATE).matches(/^\d{4}(\.|-)\d{2}(\.|-)\d{2}$/, VALIDATION_ERROR_DATE).required(VALIDATION_ERROR_DATE);
export const VALIDATION_SCHEMA_OPTIONS_YESNO = Yup.mixed().oneOf(OPTIONS_YESNO, VALIDATION_ERROR_REQUIRED).required(VALIDATION_ERROR_REQUIRED);
export const VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL = Yup.mixed().oneOf(OPTIONS_YESNO_WITHALL, VALIDATION_ERROR_REQUIRED).required(VALIDATION_ERROR_REQUIRED);
export const VALIDATION_SCHEMA_USERGROUP_COMPANY = Yup.mixed().oneOf(USERGROUPS_COMPANY, VALIDATION_ERROR_REQUIRED).required(VALIDATION_ERROR_REQUIRED);
export const VALIDATION_SCHEMA_USERGROUP_COMPANY_WITHALL = Yup.mixed().oneOf(USERGORUPS_COMPANY_WITHALL, VALIDATION_ERROR_REQUIRED).required(VALIDATION_ERROR_REQUIRED);
export const VALIDATION_SCHEMA_TRANSLATIONS = Yup.array(Yup.object().shape({ languageCode: VALIDATION_SCHEMA_LANGUAGE_CODE, text: VALIDATION_SCHEMA_STRING }));

// translations formatting
Yup.ValidationError.formatError = (message, params) => {
    if (typeof message === 'string') {
        return i18next.t(message, params);
    }

    if (typeof message === 'function') {
        return message(params)
    }

    return message
}