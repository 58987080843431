import { Clock2 } from "tabler-icons-react";
import { TIME_UNIT_TEXT } from "../helpers/Constants";
import BadgeAutoHeight from "./BadgeAutoHeight";
import { useTranslation } from 'react-i18next';
import { formatNumber } from "../helpers/i18n";

/**
 * hours badge
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeHours(props) {

    // globals
    const { t } = useTranslation();

    if (props.hours.hours === 0) {
        return null;
    }

    return (
        <BadgeAutoHeight
            color="indigo"
            variant="outline"
            leftSection={<Clock2 size={14} />}
            size="xs"
        >
            {`${formatNumber(props.hours.hours, { minimumFractionDigits: 0, maximumFractionDigits: 2 })} ${t("job.hours")} / ${t(TIME_UNIT_TEXT[props.hours.timeUnit])}`}
        </BadgeAutoHeight>
    )
}