import * as Yup from 'yup';
import { VALIDATION_SCHEMA_ADDRESSTEXT, VALIDATION_SCHEMA_HOURS, VALIDATION_SCHEMA_STRING, VALIDATION_SCHEMA_BOOL, VALIDATION_SCHEMA_LOCATION, VALIDATION_SCHEMA_SALARY } from '../../helpers/Validation';
import { useNavigate, useParams } from "react-router-dom";
import { Company, JobCategory, JobOffer, PositionLevel, WorkTimeRegulation } from '../../models';
import { TIME_UNITS } from '../../helpers/Constants';
import { ERROR_SHOW, useErrorDispatch } from '../../helpers/GlobalErrorState';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../../helpers/GlobalLoadingState';
import { DataStore } from '@aws-amplify/datastore';
import { useForm } from '../../components/Form';
import { useEffect } from 'react';
import { showNotification } from '@mantine/notifications';
import { Check, File, List, Reload } from 'tabler-icons-react';
import { Button, Divider, Grid, Group, Input, Select, Stack, TextInput, Title } from '@mantine/core';
import LocationSearchInput from '../../components/LocationSearchInput';
import CheckboxInput from '../../components/CheckboxInput';
import RichTextEditor from '../../components/RichTextEditor';
import { TimeUnit } from '../../models';
import ModelSelectInput from '../../components/ModelSelectInput';
import { useUserState } from '../../helpers/GlobalUserState';
import { ROUTE_COMPANY_JOBS, ROUTE_NOT_FOUND } from '../../helpers/Routes';
import { useTranslation } from 'react-i18next';
import NumberInput from '../../components/NumberInput';
import CurrencySelect from '../../components/CurrencySelect';
import InputBackground from '../../components/InputBackground';
import { getCurrencyCodeFromLanguage } from '../../helpers/i18n';

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_STRING,
    description: VALIDATION_SCHEMA_STRING,
    visibleInSearch: VALIDATION_SCHEMA_BOOL,
    workTimeRegulationId: VALIDATION_SCHEMA_STRING,
    jobCategoryId: VALIDATION_SCHEMA_STRING,
    positionLevelId: VALIDATION_SCHEMA_STRING,
    location: VALIDATION_SCHEMA_LOCATION,
    addressText: VALIDATION_SCHEMA_ADDRESSTEXT,
    hours: VALIDATION_SCHEMA_HOURS,
    salary: VALIDATION_SCHEMA_SALARY,
});

/**
 * job create/edit page
 * @returns JSX
 */
export default function PageCompanyJob() {

    // globals
    const navigate = useNavigate();
    const { id } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const user = useUserState();
    const { t } = useTranslation();

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);
                const item = await DataStore.query(JobOffer, id);
                if (!item) {
                    navigate(ROUTE_NOT_FOUND);
                }

                // set data
                form.setInitialValues({
                    id: item.id,
                    owner: item.owner,
                    name: item.name,
                    description: item.description,
                    visibleInSearch: item.visibleInSearch,
                    workTimeRegulationId: item.workTimeRegulationId,
                    jobCategoryId: item.jobCategoryId,
                    positionLevelId: item.positionLevelId,
                    location: {
                        lat: item.location.lat,
                        lon: item.location.lon,
                    },
                    addressText: item.addressText,
                    salary: {
                        salary: item.salary.salary,
                        currencyCode: item.salary.currencyCode,
                        timeUnit: item.salary.timeUnit,
                    },
                    hours: {
                        hours: item.hours.hours,
                        timeUnit: item.hours.timeUnit,
                    }
                });
            }
            else {
                // get defaults
                const currencyCode = await getCurrencyCodeFromLanguage();
                const companyData = await DataStore.query(Company, user.companyId);
                form.setInitialValues({
                    ...form.values,
                    addressText: companyData.addressText,
                    location: {
                        lat: companyData.location.lat,
                        lon: companyData.location.lon,
                    },
                    salary: {
                        ...form.values.salary,
                        currencyCode: currencyCode
                    }
                })
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // submit callback
    const submitCallback = async (values) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // save item
            if (!id) {
                // create item
                await DataStore.save(new JobOffer({
                    name: values.name,
                    description: values.description,
                    visibleInSearch: values.visibleInSearch,
                    workTimeRegulationId: values.workTimeRegulationId,
                    jobCategoryId: values.jobCategoryId,
                    positionLevelId: values.positionLevelId,
                    location: values.location,
                    addressText: values.addressText,
                    salary: values.salary,
                    hours: values.hours,
                }));
            } else {
                // update item
                const original = await DataStore.query(JobOffer, id);
                await DataStore.save(JobOffer.copyOf(original, updated => {
                    updated.name = values.name;
                    updated.description = values.description;
                    updated.visibleInSearch = values.visibleInSearch;
                    updated.workTimeRegulationId = values.workTimeRegulationId;
                    updated.jobCategoryId = values.jobCategoryId;
                    updated.positionLevelId = values.positionLevelId;
                    updated.location = values.location;
                    updated.addressText = values.addressText;
                    updated.salary = values.salary;
                    updated.hours = values.hours;
                }));
            }

            // show success
            showNotification({ message: t("job.saved"), color: 'green', icon: <Check /> });

            // navigate to list
            navigateToList();
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * wrapper to navigate back to jobs list
     */
    const navigateToList = () => {
        if (user.companyId) {
            navigate(ROUTE_COMPANY_JOBS);
        }
        else {
            navigate(-1);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            id: "",
            name: "",
            description: "",
            visibleInSearch: true,
            workTimeRegulationId: "",
            jobCategoryId: "",
            positionLevelId: "",
            location: {
                lat: null,
                lon: null,
            },
            addressText: "",
            salary: {
                salary: 0,
                currencyCode: null,
                timeUnit: TimeUnit.MONTH,
            },
            hours: {
                hours: 0,
                timeUnit: TimeUnit.MONTH,
            },
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <Title>{form.values.id ? t("job.edit") : t("job.create")}</Title>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label={t("general.name")}
                        placeholder={t("general.name")}
                        {...form.getInputProps('name')}
                    />
                    <CheckboxInput
                        label={t("job.visibility")}
                        description={t("job.visible")}
                        {...form.getInputProps('visibleInSearch', { type: 'checkbox' })}
                    />
                    <ModelSelectInput
                        type={WorkTimeRegulation}
                        withAsterisk
                        label={t("worktimeregulation.worktimeregulation")}
                        placeholder={t("worktimeregulation.worktimeregulation")}
                        {...form.getInputProps('workTimeRegulationId')}
                    />
                    <ModelSelectInput
                        type={JobCategory}
                        withAsterisk
                        label={t("jobcategory.jobcategory")}
                        placeholder={t("jobcategory.jobcategory")}
                        {...form.getInputProps('jobCategoryId')}
                    />
                    <ModelSelectInput
                        type={PositionLevel}
                        withAsterisk
                        label={t("positionlevel.positionlevel")}
                        placeholder={t("positionlevel.positionlevel")}
                        {...form.getInputProps('positionLevelId')}
                    />
                    <LocationSearchInput
                        label={t("general.address")}
                        form={form}
                        withAsterisk={true}
                    />
                    <Input.Wrapper>
                        <Input.Label>{t("job.payment")}</Input.Label>
                        <Input.Description color="dimmed" size="sm">{t("job.hide_property")}</Input.Description>
                        <InputBackground>
                            <Grid gap="md">
                                <Grid.Col span={{ base: 12, md: 12, xl: 4 }}>
                                    <NumberInput
                                        label={t("job.payment")}
                                        withAsterisk
                                        min={0}
                                        decimalScale={2}
                                        {...form.getInputProps('salary.salary')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 12, xl: 4 }}>
                                    <CurrencySelect
                                        withAsterisk
                                        form={form}
                                        fieldName="salary.currencyCode"
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 12, xl: 4 }}>
                                    <Select
                                        label={t("job.unit")}
                                        withAsterisk
                                        {...form.getInputProps('salary.timeUnit')}
                                        data={TIME_UNITS().map(e => {
                                            return { value: e.value, label: `/ ${t(e.label)}`}
                                        })}
                                    />
                                </Grid.Col>
                            </Grid>
                        </InputBackground>
                    </Input.Wrapper>
                    <Input.Wrapper>
                        <Input.Label>{t("job.hours")}</Input.Label>
                        <Input.Description color="dimmed" size="sm">{t("job.hide_property")}</Input.Description>
                        <InputBackground>
                            <Grid gap="md">
                                <Grid.Col span={{ base: 12, md: 12, xl: 4 }}>
                                    <NumberInput
                                        label={t("job.hours")}
                                        withAsterisk
                                        min={0}
                                        decimalScale={2}
                                        {...form.getInputProps('hours.hours')}
                                    />
                                </Grid.Col>
                                <Grid.Col span={{ base: 12, md: 12, xl: 4 }}>
                                    <Select
                                        label={t("job.unit")}
                                        withAsterisk
                                        {...form.getInputProps('hours.timeUnit')}
                                        data={TIME_UNITS().map(e => {
                                            return { value: e.value, label: `/ ${t(e.label)}`}
                                        })}
                                    />
                                </Grid.Col>
                            </Grid>
                        </InputBackground>
                    </Input.Wrapper>
                    <RichTextEditor
                        label={t("job.description")}
                        placeholder={t("job.design")}
                        withAsterisk
                        form={form}
                        contentField="description"
                    />
                    <Divider />

                    <Group justify='space-between'>
                        <Group>
                            <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                            <Button leftSection={<List size={14} />} onClick={() => navigateToList()} color="yellow">{t("general.back")}</Button>
                        </Group>
                        <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.save")}</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}