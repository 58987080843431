import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { DataStore } from "aws-amplify";
import { Language } from "../models";

// constants
export const fallbackLanguage = "en";

// init of i18n
i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: fallbackLanguage,
        interpolation: {
            escapeValue: false
        }
    });

/**
 * formats the givven number with the options if set
 * @param {float} number number to format
 * @param {object} options formatting options
 * @returns formatted number
 */
export function formatNumber(number, options = {}) {
    var formatedNumber = null;

    // try all languages from i18n
    for (let index = 0; index < i18n.languages.length; index++) {
        const language = i18n.languages[index];
        try {
            formatedNumber = number.toLocaleString(language, options);
            break;
        }
        catch { }
    }

    // if the number could still not be formatted, use the fallback language
    if (!formatedNumber) {
        try {
            formatedNumber = number.toLocaleString(fallbackLanguage, options);
        }
        catch {
            formatedNumber = "";
        }
    }

    return formatedNumber;
}

/**
 * function to get and return the decimal character depending on locale
 * @returns decimal character
 */
export function getNumberSeparators() {
    var format = null;

    // try all languages from i18n
    for (let index = 0; index < i18n.languages.length; index++) {
        const language = i18n.languages[index];
        try {
            format = new Intl.NumberFormat(language).format;
            break;
        }
        catch { }
    }

    // if we could not fetch it, use the fallback language
    if (!format) {
        format = new Intl.NumberFormat(fallbackLanguage).format;
    }

    const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
    const [, thousandSign] = /^1(.)000$/.exec(format(1000));
    return [thousandSign, decimalSign];
}

/**
 * tries to find a translation according to currently set languages and returns the translated text if found
 * @param {array} translations array of translations
 * @returns either null, or translated text as string if found
 */
export async function getTextFromTranslations(translations) {
    // if no translations object, return empty
    if (!translations) {
        return null;
    }

    // if length of array is zero, return empty
    if (translations.length === 0) {
        return null;
    }

    // default text to return if not found
    var text = null;

    // get active languages
    const activeLanguages = await DataStore.query(Language, p => p.active.eq(true));

    // try to find translated text
    i18n.languages.forEach(language => {
        // check if we found the language in database 
        var foundLanguage = activeLanguages.find((foundLanguage) => foundLanguage.code === language);
        if (!foundLanguage) {
            // if we have not found the language as active, skip
            return true;
        }
        if (foundLanguage.active === false) {
            // if the language is not active, also skip
            return true;
        }

        // check if we find the translation in the array
        var foundTranslation = translations.find((translation) => translation.languageCode === language);
        if (foundTranslation && foundTranslation.text) {
            // if we found a translation, return it
            text = foundTranslation.text;
            return false;
        }
    });

    // return the text, either default or translated if a translation was found
    return text;
}

/**
 * fetches the currency code from the users language
 * @returns currency code from language
 */
export const getCurrencyCodeFromLanguage = async () => {
    for (let index = 0; index < i18n.languages.length; index++) {
        const language = await DataStore.query(Language, i18n.languages[index]);
        if (language) {
            return language.currencyCode;
        }
    }
}

export default i18n;