import { Divider, Stack, Title, Group, Button, TextInput, Accordion } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Filter, Plus, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import List, { COLUMN_TYPE_BOOLEAN, COLUMN_TYPE_TIMESTAMP } from "../../components/List";
import SortDirectionInput from "../../components/SortDirectionInput";
import SortFieldInput from "../../components/SortFieldInput";
import { PREDICATE_CONTAINS, PREDICATE_EQUALS } from "../../helpers/Constants";
import { getFilterPredicates } from "../../helpers/Predicates";
import { Language } from "../../models";
import { ROUTE_ADMIN_LANGUAGE_EDIT, ROUTE_ADMIN_LANGUAGE_NEW } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import BooleanSelect from "../../components/BooleanSelect";

/**
 * languages list
 * @returns JSX
 */
export default function PageAdminLanguages() {

    // globals
    const navigate = useNavigate();
    const { t } = useTranslation();

    // form hook
    const form = useForm({
        initialValues: {
            code: "",
            name: "",
            nameNative: "",
            currencyCode: "",
            countryCode: "",
            active: null,
            sortField: "code",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "code", value: form.values.code, type: PREDICATE_CONTAINS },
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
            { key: "nameNative", value: form.values.nameNative, type: PREDICATE_CONTAINS },
            { key: "currencyCode", value: form.values.currencyCode, type: PREDICATE_EQUALS },
            { key: "countryCode", value: form.values.countryCode, type: PREDICATE_EQUALS },
            { key: "active", value: form.values.active, type: PREDICATE_EQUALS },
        ]);
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("language.languages")}</Title>
                <Button
                    color="green"
                    leftSection={<Plus />}
                    onClick={() => navigate(ROUTE_ADMIN_LANGUAGE_NEW)}
                >
                    {t("language.new")}
                </Button>
            </Group>
            <Divider />

            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("general.code")}
                                    placeholder={t("general.code")}
                                    {...form.getInputProps('code')}
                                />

                                <TextInput
                                    label={t("general.name")}
                                    placeholder={t("general.name")}
                                    {...form.getInputProps('name')}
                                />

                                <TextInput
                                    label={t("language.native")}
                                    placeholder={t("language.native")}
                                    {...form.getInputProps('nameNative')}
                                />

                                <TextInput
                                    label={t("currency.default")}
                                    placeholder={t("currency.default")}
                                    {...form.getInputProps('currencyCode')}
                                />

                                <TextInput
                                    label={t("language.country_code")}
                                    placeholder={t("language.country_code")}
                                    {...form.getInputProps('countryCode')}
                                />

                                <BooleanSelect
                                    withAsterisk
                                    label={t("general.active")}
                                    placeholder="..."
                                    {...form.getInputProps('active')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'code', label: t("general.code") },
                                        { value: 'name', label: t("general.name") },
                                        { value: 'nameNative', label: t("language.native") },
                                        { value: 'currencyCode', label: t("currency.default") },
                                        { value: 'countryCode', label: t("language.country_code") },
                                        { value: 'active', label: t("general.active") },
                                        { value: 'createdAt', label: t("date.create_time") },
                                        { value: 'updatedAt', label: t("date.change_time") },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group justify='space-between'>
                                    <Button variant="outline" leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={Language}
                id="code"
                dataStructure={[
                    { key: "code", title: t("general.code") },
                    { key: "name", title: t("general.name") },
                    { key: "nameNative", title: t("language.native") },
                    { key: "currencyCode", title: t("currency.default") },
                    { key: "countryCode", title: t("language.country_code") },
                    { key: "active", title: t("general.active"), type: COLUMN_TYPE_BOOLEAN },
                    { key: "createdAt", title: t("date.create_time"), type: COLUMN_TYPE_TIMESTAMP },
                    { key: "updatedAt", title: t("date.change_time"), type: COLUMN_TYPE_TIMESTAMP },
                ]}
                editRoute={ROUTE_ADMIN_LANGUAGE_EDIT}
                filter={getFilter()}
                sortField={form.values.sortField}
                sortDirection={form.values.sortDirection}
            />
        </Stack>
    )
}