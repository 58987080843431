import { Divider, Stack, Title, Button, Group, Accordion, TextInput } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { Filter, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import List, { COLUMN_TYPE_JOBCATEGORY, COLUMN_TYPE_TIMESTAMP, COLUMN_TYPE_WORKTIMEREGULATION } from "../../components/List";
import SortDirectionInput from "../../components/SortDirectionInput";
import SortFieldInput from "../../components/SortFieldInput";
import { ROUTE_USER_APPLICATIONS_SHOW } from "../../helpers/Routes";
import { PREDICATE_CONTAINS } from "../../helpers/Constants";
import { getFilterPredicates } from "../../helpers/Predicates";
import { JobApplication } from "../../models";
import { useTranslation } from 'react-i18next';

/**
 * user page to list applications
 * @returns JSX
 */
export default function PageUserApplications() {

    // globals
    const { t } = useTranslation();

    // form hook
    const form = useForm({
        initialValues: {
            jobName: "",
            companyName: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });
    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "jobOffer.name", value: form.values.jobName, type: PREDICATE_CONTAINS },
            { key: "jobOffer.company.name", value: form.values.companyName, type: PREDICATE_CONTAINS },
        ]);
    }

    return (
        <Stack>
            <Title>{t("application.applications")}</Title>
            <Divider />

            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("job.job")}
                                    placeholder={t("job.job")}
                                    {...form.getInputProps('jobName')}
                                />

                                <TextInput
                                    label={t("company.name")}
                                    placeholder={t("company.name")}
                                    {...form.getInputProps('companyName')}
                                />
                                <SortFieldInput
                                    data={[
                                        { value: 'createdAt', label: t("application.applied_time") },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group justify='space-between'>
                                    <Button variant="outline" leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={JobApplication}
                id="id"
                dataStructure={[
                    { key: "jobOffer.name", title: t("job.job") },
                    { key: "jobOffer.company.name", title: t("company.name") },
                    { key: "jobOffer.jobCategoryId", title: t("jobcategory.jobcategory"), type: COLUMN_TYPE_JOBCATEGORY },
                    { key: "jobOffer.workTimeRegulationId", title: t("worktimeregulation.worktimeregulation"), type: COLUMN_TYPE_WORKTIMEREGULATION },
                    { key: "createdAt", title: t("application.applied_time"), type: COLUMN_TYPE_TIMESTAMP },
                ]}
                sortField={form.values.sortField}
                sortDirection={form.values.sortDirection}
                showRoute={ROUTE_USER_APPLICATIONS_SHOW}
                filter={getFilter()}
            />
        </Stack>
    )
}