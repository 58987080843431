import { DataStore, SortDirection } from "aws-amplify";
import { Accordion, Card, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Briefcase, BuildingSkyscraper } from "tabler-icons-react";
import Job from "../../components/Job";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { Company, JobOffer } from "../../models";
import CompanyDetails from "../../components/CompanyDetails";
import { ROUTE_JOBS_SHOW, ROUTE_NOT_FOUND } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import { HTML_TITLE } from "../../helpers/Constants";

// html parser for descriptions
const parseHtml = require('html-react-parser');

/**
 * company overview for users
 * @returns JSX
 */
export default function PageGeneralCompany() {

    const { id } = useParams();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const [company, setCompany] = useState(null);
    const [jobs, setJobs] = useState(null);
    const { t } = useTranslation();

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        // fetch job
        setLoading(LOADING_SHOW);
        try {
            // fetch company
            const company = await DataStore.query(Company, id);
            if (!company) {
                navigate(ROUTE_NOT_FOUND);
                return;
            }

            // fetch jobs
            const jobs = await DataStore.query(JobOffer, c => c.company.id.eq(id), {
                sort: s => s.createdAt(SortDirection.DESCENDING)
            });

            // fetch details
            const fetchedJobs = [];
            for await (const job of jobs) {
                const jobCategory = await job.jobCategory;
                const workTimeRegulation = await job.workTimeRegulation;
                const positionLevel = await job.positionLevel;

                fetchedJobs.push({
                    ...job,
                    jobCategory,
                    workTimeRegulation,
                    positionLevel
                });
            }

            // set state
            setJobs(fetchedJobs);
            setCompany(company);
            document.title = `${company.name} - ${HTML_TITLE}`;
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        // no job offer id provided, redirect to 404
        if (!id) {
            navigate(ROUTE_NOT_FOUND)
        }

        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    if (!company || !jobs) {
        return null;
    }

    return (
        <Stack>
            <CompanyDetails company={company} />

            <Accordion multiple={true} variant="separated" defaultValue={["company", "jobs"]}>
                {company.description &&
                    <Accordion.Item value="company">
                        <Accordion.Control icon={<BuildingSkyscraper size={20} />}>{t("company.description.description")}</Accordion.Control>
                        <Accordion.Panel>{parseHtml(company.description)}</Accordion.Panel>
                    </Accordion.Item>
                }

                <Accordion.Item value="jobs">
                    <Accordion.Control icon={<Briefcase size={20} />}>{t("job.jobs")}</Accordion.Control>
                    <Accordion.Panel>
                        <Stack>
                            {jobs && jobs.length > 0 ?
                                jobs.map(job => {
                                    return (
                                        <Link
                                            to={`${ROUTE_JOBS_SHOW}/${job.id}`}
                                            style={{
                                                textDecoration: "none"
                                            }}
                                            key={job.id}
                                        >
                                            <Card p="xs" radius="md" withBorder>
                                                <Job job={job} hideCompanyAvatar hideCompanyName />
                                            </Card>
                                        </Link>
                                    )
                                })
                                :
                                <Text>{t("general.no_data")}</Text>
                            }
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    );
}