import { Accordion, ActionIcon, Button, Divider, Group, Paper, Stack, Text, Title } from "@mantine/core";
import { DataStore } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { At, Briefcase, BuildingSkyscraper, Edit, List as ListIcon, Package, Phone, Plus, Reload, UserCircle } from "tabler-icons-react";
import { ROUTE_SUPPORT_COMPANIES_EDIT, ROUTE_SUPPORT_COMPANIES, ROUTE_SUPPORT_COMPANIES_USER_NEW } from "../../helpers/Routes";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, LOADING_SHOW_AND_HIDE_CHILDREN, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { Company, CompanyPaymentData } from "../../models";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import CompanyDetails from "../../components/CompanyDetails";
import { executeApiCall, API_MODE_GET, ADMIN_API_SUPPORT_LISTUSERSFORCOMPANY, ADMIN_API_NAME } from "../../helpers/APIHelper";
import CompanyUsersList from "../../components/CompanyUsersList";
import CompanyJobsList from "../../components/CompanyJobsList";
import CompanyApplicationsList from "../../components/CompanyApplicationsList";
import { useTranslation } from "react-i18next";

/**
 * support page for company details
 */
export default function PageSupportCompany() {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [data, setData] = useState(null);
    const [users, setUsers] = useState([]);
    const { companyId } = useParams();
    const { t } = useTranslation();
    const previousActiveAccordionItemsRef = useRef([])

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        setLoading(LOADING_SHOW_AND_HIDE_CHILDREN);

        try {
            // check if company id is provided 
            if (!companyId) {
                throw new Error("No company ID set.");
            }

            // get company data
            const company = await DataStore.query(Company, companyId);
            if (!company) {
                throw new Error(t("error.company"));
            }

            // get company payment data
            const companyPaymentData = await DataStore.query(CompanyPaymentData, companyId);
            if (!company) {
                throw new Error("Company payment data could not be loaded.");
            }

            // set fetched data
            setData({
                company: company,
                companyPaymentData: companyPaymentData,
            });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [companyId]
    );

    /**
     * callback to check if users should be loaded
     * @param {array} e array of active accordion items
     */
    const accordionValueChanges = async (e) => {
        // check if users tab was opened
        if (!previousActiveAccordionItemsRef.current.includes("users") && e.includes("users")) {
            await getUsers();
        }
        previousActiveAccordionItemsRef.current = e;
    }

    /**
     * wrapper to fetch users of company
     */
    const getUsers = async () => {
        try {
            setLoading(LOADING_SHOW);
            // get users from company
            const users = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_SUPPORT_LISTUSERSFORCOMPANY, {
                "companyId": companyId,
            });
            setUsers(users);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // company not loaded, hide
    if (!data) {
        return null;
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{data.company.name}</Title>
                <Group>
                    <Link to={`${ROUTE_SUPPORT_COMPANIES_EDIT}/${companyId}`}><Button leftSection={<Edit size={14} />} color="green">{t("company.edit")}</Button></Link>
                    <Link to={ROUTE_SUPPORT_COMPANIES}><Button leftSection={<ListIcon size={14} />} color="yellow">{t("general.back")}</Button></Link>
                </Group>
            </Group>
            <Divider />

            <Accordion multiple={true} variant="separated" defaultValue={["company"]} onChange={(e) => accordionValueChanges(e)}>
                <Accordion.Item value="company">
                    <Accordion.Control icon={<BuildingSkyscraper size={20} />}>{t("company.company")}</Accordion.Control>
                    <Accordion.Panel>
                        <CompanyDetails company={data.company} />

                        <Paper withBorder mt="xs" p="xs">
                            <Stack gap={5}>
                                <Group wrap="nowrap">
                                    <At color="gray" size={16} />
                                    <Text fz="xs">{data.company.email}</Text>
                                </Group>

                                <Group wrap="nowrap">
                                    <Phone color="gray" size={16} />
                                    <Text fz="xs">{data.company.phonenumber}</Text>
                                </Group>
                            </Stack>
                        </Paper>

                        <Group mt="xs" gap="xs">
                            <BadgeTimestamp label={`${t("date.create_time")}:`} time={data.company.createdAt} />
                            <BadgeTimestamp label={`${t("date.change_time")}:`} time={data.company.updatedAt} />
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="paymentdata">
                    <Accordion.Control icon={<Package size={20} />}>{t("company.payment_data")}</Accordion.Control>
                    <Accordion.Panel>

                        <Group wrap="nowrap">
                            <Stack gap={5}>
                                <Text fz="xs">Stripe ID</Text>
                                <Text fz="xs">{t("unlock.available")}</Text>
                            </Stack>
                            <Stack gap={5}>
                                <Text fz="xs">{data.companyPaymentData.paymentProviderId}</Text>
                                <Text fz="xs">{data.companyPaymentData.applicationsLeftToUnlock}</Text>
                            </Stack>
                        </Group>
                        <Group mt="xs" gap="xs">
                            <BadgeTimestamp label={`${t("date.create_time")}:`} time={data.companyPaymentData.createdAt} />
                            <BadgeTimestamp label={`${t("date.change_time")}:`} time={data.companyPaymentData.updatedAt} />
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="users">
                    <Accordion.Control icon={<UserCircle size={20} />}>{t("user.users")}</Accordion.Control>
                    <Accordion.Panel>
                        <Group justify="space-between" mb="md">
                            <Link to={`${ROUTE_SUPPORT_COMPANIES_USER_NEW}/${companyId}`}><Button size="compact-sm" variant="outline" leftSection={<Plus size={14} />} color="green">{t("auth.signup.invite")}</Button></Link>
                            <ActionIcon color="blue" variant="outline" onClick={() => getUsers()}>
                                <Reload size={14} />
                            </ActionIcon>
                        </Group>
                        <CompanyUsersList users={users} />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="jobs">
                    <Accordion.Control icon={<Briefcase size={20} />}>{t("job.jobs")}</Accordion.Control>
                    <Accordion.Panel>
                        <CompanyJobsList />
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="applications">
                    <Accordion.Control icon={<ListIcon size={20} />}>{t("application.applications")}</Accordion.Control>
                    <Accordion.Panel>
                        <CompanyApplicationsList />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    )
}