import SignInEmail from "../../components/SignInEmail";
import { Center, Container, Paper, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ROUTE_SIGNUP } from "../../helpers/Routes";

/**
 * component to handle company signin
 * @returns JSX
 */
export default function PageCompanySignin() {

    // globals
    const { t } = useTranslation();

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <Paper withBorder shadow="md" p="md" radius="md">
                    <Title align="center">{t("auth.signin.company.link")}</Title>
                    <Link to={ROUTE_SIGNUP}>
                        <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" mb="md">
                            {t("auth.signup.question")}
                        </Text>
                    </Link>
                    <SignInEmail />
                </Paper>
            </Container>
        </Center>
    )
}