import { Badge, Center, Text } from "@mantine/core";

/**
 * custom badge with auto height in case of text wrap
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeAutoHeight(props) {
    return (
        <Badge
            {...props}
            style={{
                height: "unset",
                padding: "5px",
                paddingRight: "12px",
                paddingLeft: "12px",
                ...props.style
            }}
            leftSection={props.leftSection ? <Center>{props.leftSection}</Center> : null}
            rightSection={props.rightSection ? <Center>{props.rightSection}</Center> : null}
        >
            <Text style={{ whiteSpace: "break-spaces", lineHeight: 1.25, textTransform: "none" }} size={props.textSize || "11"}>{props.children}</Text>
        </Badge>
    )
}