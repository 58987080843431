import React, { useEffect } from 'react';
import { HTML_TITLE, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_USER } from '../helpers/Constants';
import i18next, { t } from 'i18next';
import { useUserState } from '../helpers/GlobalUserState';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTE_COMPANY_SIGNIN, ROUTE_HOME, ROUTE_SIGNIN, ROUTE_USER_SIGNIN } from '../helpers/Routes';

/**
 * Implementation to handle route 
 * @param {array} title html title to set
 * @param {object} Component Component to render
 * @param {boolean} prevailOnUnmount flag if document title should be reset when leaving page, default true
 * @param {boolean} signedIn flag if the user should be navigated to home if signed in 
 * @param {[string]} signedIn user groups the route should be accessible by
 */
const RouteWrapper = ({ title, Component, prevailOnUnmount = false, signedIn = true, userGroups = null }) => {

    // globals
    const user = useUserState();
    const location = useLocation();

    // wrapper to set document title
    const setTitle = () => {
        if (title) {
            document.title = `${title ? t(title) : ""} - ${HTML_TITLE}`;
        }
        else {
            document.title = HTML_TITLE;
        }
    }

    // hook to set title
    useEffect(() => {
        i18next.on('languageChanged', setTitle);
        setTitle();

        return () => {
            i18next.off('languageChanged', setTitle);
        };
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [title]
    );

    // use state hook to unset title on onmount
    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = HTML_TITLE;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    // check if we redirect if logged in
    if (signedIn === false && user.id) {
        return <Navigate to={ROUTE_HOME} />
    }

    // check if no user groups allowed set, then just show the component
    if (!userGroups || (userGroups && userGroups.length === 0)){
        return <Component />
    }

    // if usergroups are set, we need to check if the current user has on of the user groups set, then show component
    if (user.userGroup && userGroups.includes(user.userGroup)) {
        return <Component />
    } 
    
    // if we reach here, we need to check if we have a user, if so, the user called a route he should not access, so redirect the user to home
    if (user.id) {
        return <Navigate to={ROUTE_HOME} />
    } 

    // otherwise route to login
    var route = ROUTE_SIGNIN;
    if (userGroups.includes(USERGROUP_COMPANY) || userGroups.includes(USERGROUP_COMPANYADMIN)) {
        route = ROUTE_COMPANY_SIGNIN;
    }
    else if (userGroups.includes(USERGROUP_USER)) {
        route = ROUTE_USER_SIGNIN;
    }

    return <Navigate to={route} state={{ from: location }} />
}

// default export of the class
export default RouteWrapper;