import React from 'react';
import { Button, Group, Modal, ScrollArea, Text, Title } from '@mantine/core';
import { ERROR_RESET, useErrorDispatch, useErrorState } from '../helpers/GlobalErrorState';
import { useNavigate } from 'react-router-dom';
import { Home, Repeat } from 'tabler-icons-react';
import * as Sentry from "@sentry/react";
import { ROUTE_HOME } from '../helpers/Routes';
import { useTranslation } from 'react-i18next';

var lastError = null;

/**
 * Error overlay implementation
 */
const ErrorOverlay = () => {
    // global states
    const state = useErrorState();
    const dispatch = useErrorDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    // wrapper for closing the modal
    const closeModal = () => {
        dispatch(ERROR_RESET);
    }

    // wrapper for retry, that either calls the callback if set, or closes the modal for the user to retry
    const retry = () => {
        if (state.callback) {
            state.callback();
        }
        else {
            closeModal();
        }
    }

    const returnToHome = () => {
        navigate(ROUTE_HOME);
        closeModal();
    }

    // if there is no error set, we do not need to render the component
    if (!state.error) {
        return null;
    }

    /**
     * Gets the errors as string array with formatted html, each error as text in a paragraph
     * @access private
     * @return {[String]} The formatted html for the errors as array of strings
     */
    const getErrors = () => {
        const error = state.error;

        var errors = [];
        if (!error) {
            return errors;
        }
        else if (typeof error === 'string' || error instanceof String) {
            errors = [new Error(error)];
        }
        else if (error instanceof Array) {
            // if the error is aleady an array, just use it
            errors = error;
        }
        else if (error.errors && error.errors instanceof Array) {
            // in case of errors from amplify, the error messages may be an array
            errors = error.errors;
        }
        else {
            errors = [error];
        }

        const errorTexts = [];
        errors.forEach(e => {
            // if the message is already in the array, discard it
            if (!errorTexts.includes(e.message)) {
                //get text for each error
                errorTexts.push(e.message);
            }
        })

        if (JSON.stringify(lastError) !== JSON.stringify(error)) {
            // errors differ, send errors to Sentry

            Sentry.withScope(function (scope) {
                // set appsync specific errors if any
                if (error?.error?.errors) {
                    scope.setExtra("errors.json", JSON.stringify(error.error.errors));
                }

                // set appsync specific data if any
                if (error?.error?.data) {
                    scope.setExtra("data.json", JSON.stringify(error.error.data));
                }

                scope.setExtra("alert.json", JSON.stringify(error));
                Sentry.captureException(JSON.stringify(error));
            });
        }

        lastError = error;
        return errorTexts;
    }

    // render with error
    return (
        <Modal
            opened={true}
            centered={true}
            closeOnClickOutside={false}
            withCloseButton={false}
            onClose={() => closeModal()}
            size="xl"
            zIndex={1000}
        >
            <ScrollArea>
                <Title order={3} mb="md">{state.title ? state.title : t("error.unexpected")}</Title>
                <Text>{getErrors()}</Text>
                <Text c="dimmed" size="sm" mt="md">{t("error.development_notified")}</Text>
            </ScrollArea>
            <Group mt="md" justify='space-between'>
                <Button onClick={() => returnToHome()} variant="outline" color="red" size="sm" leftSection={<Home size={14} />}>{t("error.home")}</Button>
                <Button onClick={() => retry()} variant="outline" color="green" size="sm" leftSection={<Repeat size={14} />}>{t("error.retry")}</Button>
            </Group>
        </Modal>
    );
}

// Default export of the class
export default ErrorOverlay;