import { Button, Card, Center, Flex, Stack, Text } from "@mantine/core";
import { ROUTE_JOBS, ROUTE_JOBS_SHOW } from "../helpers/Routes";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import { Search } from "tabler-icons-react";
import Job from "./Job";
import { useTranslation } from "react-i18next";

/**
 * custom component to render jobs in carousel
 * @param {array} jobs array of jobs to render
 * @returns 
 */
export default function JobsCarousel({ jobs }) {

    // globals
    const { t } = useTranslation();

    /**
     * wrapper to render job carousel items
     * @param {array} items array of items to render
     * @returns JSX
     */
    const renderedJobs = () => {

        // render jobs
        const renderedJobs = jobs.map(e => (
            <Link
                to={`${ROUTE_JOBS_SHOW}/${e.id}`}
                style={{
                    textDecoration: "none",
                    flexGrow: 1
                }}
                key={e.id}
            >
                <Card withBorder padding="md" radius="md" style={{ height: "100%" }}>
                    <Job job={e} />
                </Card>
            </Link>
        ));

        // add last item with link to search
        renderedJobs.push(
            <Link to={ROUTE_JOBS} style={{ textDecoration: "none" }} key="jobs_link">
                <Card withBorder padding="md" radius="md" style={{ height: "100%", display: "flex", flexDirection: "column" }}>
                    <Center style={{ paddingBottom: 20 }}>
                        <Avatar size={100} placeholder={Search} color="green" />
                    </Center>
                    <Flex style={{ alignItems: "center", flexDirection: "column", flexGrow: 1, justifyContent: "center" }}>
                        <Stack>
                            <Center>
                                <Text align="center" size="sm">{t("job.discover")}</Text>
                            </Center>
                            <Center>
                                <Button leftSection={<Search />}>{t("job.navigate")}</Button>
                            </Center>
                        </Stack>
                    </Flex>
                </Card>
            </Link>
        )

        // return items
        return renderedJobs;
    }

    return (
        <Carousel>
            {renderedJobs()}
        </Carousel>
    )
}