import { Accordion, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Briefcase, Edit, List as ListIcon, UserCircle } from "tabler-icons-react";
import { ROUTE_SUPPORT_USERS, ROUTE_SUPPORT_USERS_EDIT, ROUTE_SUPPORT_APPLICATIONS_SHOW } from "../../helpers/Routes";
import { PREDICATE_CONTAINS } from "../../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW_AND_HIDE_CHILDREN, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { JobApplication, User } from "../../models";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import List, { COLUMN_TYPE_TIMESTAMP } from "../../components/List";
import { getFilterPredicates } from "../../helpers/Predicates";
import UserDetails from "../../components/UserDetails";
import { useTranslation } from 'react-i18next';

/**
 * support page for user details
 */
export default function PageSupportUser() {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [user, setUser] = useState(false);
    const { userId } = useParams();
    const { t } = useTranslation();

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        setLoading(LOADING_SHOW_AND_HIDE_CHILDREN);

        try {
            // check if user id is provided 
            if (!userId) {
                throw new Error("No user ID set.");
            }

            // get user data
            const data = await DataStore.query(User, userId);
            if (!data) {
                throw new Error("No user found.");
            }

            // set fetched state
            setUser(data);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userId]
    );

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "userId", value: userId, type: PREDICATE_CONTAINS },
        ]);
    }

    if (!user) {
        return null;
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{user.name}</Title>
                <Group>
                    <Link to={`${ROUTE_SUPPORT_USERS_EDIT}/${userId}`}><Button leftSection={<Edit size={14} />} color="green">{t("general.edit")}</Button></Link>
                    <Link to={ROUTE_SUPPORT_USERS}><Button leftSection={<ListIcon size={14} />} color="yellow">{t("general.back")}</Button></Link>
                </Group>
            </Group>
            <Divider />

            <Accordion multiple={true} variant="separated" defaultValue={["user", "applications"]}>
                <Accordion.Item value="user">
                    <Accordion.Control icon={<UserCircle size={20} />}>{t("user.group.user")}</Accordion.Control>
                    <Accordion.Panel>
                        <UserDetails userData={user} />
                        <Group mt="xs" gap="xs">
                            <BadgeTimestamp label={`${t("date.create_time")}:`} time={user.createdAt} />
                            <BadgeTimestamp label={`${t("date.change_time")}:`} time={user.updatedAt} />
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="applications">
                    <Accordion.Control icon={<Briefcase size={20} />}>{t("application.applications")}</Accordion.Control>
                    <Accordion.Panel>
                        <List
                            type={JobApplication}
                            id="id"
                            dataStructure={[
                                { key: "jobOffer.name", title: t("job.job") },
                                { key: "jobOffer.company.name", title: t("company.company") },
                                { key: "createdAt", title: t("application.applied_time"), type: COLUMN_TYPE_TIMESTAMP },
                            ]}
                            showRoute={ROUTE_SUPPORT_APPLICATIONS_SHOW}
                            filter={getFilter()}
                            sort={s => s["createdAt"](SortDirection.DESCENDING)}
                        />
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    )
}
