import { showNotification } from "@mantine/notifications";
import { useForm } from "../../components/Form";
import { USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { VALIDATION_SCHEMA_EMAIL } from "../../helpers/Validation";
import * as Yup from 'yup';
import { Check, List, Plus, Reload } from "tabler-icons-react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Divider, Group, Select, Stack, TextInput, Title } from "@mantine/core";
import { executeApiCall, ADMIN_API_ADMIN_CREATESUPPORTUSER, ADMIN_API_ADMIN_GETUSER, API_MODE_GET, API_MODE_POST, ADMIN_API_NAME } from "../../helpers/APIHelper";
import { ROUTE_ADMIN_SUPPORT_USERS } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * implementation of page to create a new support user
 * @returns JSX
 */
export default function PageAdminSupportUserNew() {

    // globals
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const { t } = useTranslation();

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // check if user exists
            const userExists = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_ADMIN_GETUSER, {
                "username": values.email.toLowerCase(),
            })
            if (userExists) {
                setError({ action: ERROR_SHOW, error: new Error(t("user.exists")) });
                return;
            }

            // create new user
            await executeApiCall(ADMIN_API_NAME, API_MODE_POST, ADMIN_API_ADMIN_CREATESUPPORTUSER, {
                "email": values.email.toLowerCase(),
                "admin": values.group === USERGROUP_ADMIN ? true : false,
            })

            // show message
            showNotification({ message: t("user.invited"), color: 'green', icon: <Check /> });

            // redirect
            navigate(ROUTE_ADMIN_SUPPORT_USERS);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            group: USERGROUP_SUPPORT
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("support.user.create")}</Title>
                <Link to={ROUTE_ADMIN_SUPPORT_USERS}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
            </Group>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label={t("general.email")}
                        placeholder="name@email.com"
                        {...form.getInputProps('email')}
                    />
                    <Select
                        label={t("user.permissions.permissions")}
                        placeholder={t("user.permissions.permissions")}
                        data={[
                            { value: USERGROUP_SUPPORT, label: t("user.group.support") },
                            { value: USERGROUP_ADMIN, label: t("user.group.admin") },
                        ]}
                        {...form.getInputProps('group')}
                    />
                    <Divider />

                    <Group justify='space-between'>
                        <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                        <Button leftSection={<Plus size={14} />} type="submit" color="green">{t("general.create")}</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}