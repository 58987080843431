import { API, DataStore, SortDirection } from "aws-amplify";
import { OpType } from "@aws-amplify/datastore";
import { Accordion, Alert, Badge, Button, Card, Center, Divider, Group, Modal, Stack, Text, Textarea, ThemeIcon, Timeline, Title, UnstyledButton } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AlertCircle, Check, File, FileDownload, List, LockOpen, Plus, Trash, UserCircle, X } from "tabler-icons-react";
import BadgeApplicationStatusWithUpdate from "../../components/BadgeApplicationStatusWithUpdate";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import Job from "../../components/Job";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { CompanyPaymentData, CompanyUser, JobApplicationCompanyData, JobApplicationCompanyDataNote, JobApplicationCompanyDataStatus } from "../../models";
import { useForm } from "../../components/Form";
import { VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import * as Yup from 'yup';
import { showNotification } from "@mantine/notifications";
import { openConfirmModal } from "@mantine/modals";
import UserDetails from "../../components/UserDetails";
import { ROUTE_COMPANYADMIN_PACKAGES, ROUTE_COMPANY_APPLICATIONS, ROUTE_NOT_FOUND } from "../../helpers/Routes";
import { ADMIN_API_COMPANYFILES_APPLICATION_URL, ADMIN_API_NAME, API_MODE_GET, executeApiCall } from "../../helpers/APIHelper";
import { useUserState } from "../../helpers/GlobalUserState";
import { USERGROUP_COMPANYADMIN } from "../../helpers/Constants";
import { useTranslation } from 'react-i18next';
import { downloadFileFromUrl } from "../../helpers/files";
import { changeJobApplicationCompanyStatus } from "../../graphql/mutations";
import Avatar from "../../components/Avatar";

// html parser for descriptions
const parseHtml = require('html-react-parser');

// validation schema with yup
const validationSchema = Yup.object().shape({
    text: VALIDATION_SCHEMA_STRING,
});

// initial values for new note form
const newNoteInitialValues = {
    text: "",
}

/**
 * company page to view application
 * @returns JSX
 */
export default function PageCompanyApplicationEdit() {

    // globals
    const [application, setApplication] = useState(null);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [job, setJob] = useState(null);
    const [noteEdit, setNoteEdit] = useState(null);
    const [notes, setNotes] = useState([]);
    const [noUnlocksModalVisible, setNoUnlocksModalVisible] = useState(false);
    const { id } = useParams();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const applicationRef = useRef(null);
    const notesRef = useRef(null);
    const companyUserRef = useRef(null);
    const user = useUserState();
    const { t } = useTranslation();
    const openedAccordionItemsDefault = ["notes"];
    const openedAccordionItemsUnlocked = ["applicant", "documents", "applicationtext"];
    const [openedAccordionItems, setOpenedAccordionItems] = useState(openedAccordionItemsDefault);

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        // get data
        try {
            const application = await DataStore.query(JobApplicationCompanyData, id);
            const job = await application.jobOffer;
            const company = await job.company;
            const jobCategory = await job.jobCategory;
            const workTimeRegulation = await job.workTimeRegulation;
            const positionLevel = await job.positionLevel;
            await fetchNotes();
            await fetchAndSetAvatarUrl(application);

            // set data
            setApplication(application);
            setJob({ ...job, company, jobCategory, workTimeRegulation, positionLevel });

            // default open the other accordion items if the application is unlocked
            if (application.status !== JobApplicationCompanyDataStatus.NEW && application.status !== JobApplicationCompanyDataStatus.DELETED) {
                setOpenedAccordionItems([
                    ...openedAccordionItemsDefault,
                    ...openedAccordionItemsUnlocked
                ])
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    const fetchAndSetAvatarUrl = async (application) => {
        var avatarUrl = null;
        if (application.avatar) {
            avatarUrl = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_COMPANYFILES_APPLICATION_URL, { fileKey: application.avatar, jobApplicationCompanyDataId: id });
        }
        setAvatarUrl(avatarUrl)
    }

    /**
     * wrapper to fetch notes to application
     */
    const fetchNotes = async () => {
        // get data
        try {
            // get data
            const notes = await DataStore.query(
                JobApplicationCompanyDataNote,
                c => c.jobApplicationCompanyDataId.eq(id),
                {
                    sort: s => s.createdAt(SortDirection.ASCENDING)
                }
            );

            const notePromises = [];
            notes.forEach(element => {
                if (!element.owner) {
                    return;
                }

                notePromises.push(new Promise(async (resolve) => {
                    const companyUser = await DataStore.query(CompanyUser, element.owner);
                    resolve({ note: element, companyUser: companyUser })
                }));
            });
            const resolvedNotes = await Promise.all(notePromises);
            setNotes(resolvedNotes);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        // no job offer id provided, redirect to 404
        if (!id) {
            navigate(ROUTE_NOT_FOUND);
        }

        // set loading state
        setLoading(LOADING_SHOW);

        // listener for application changes
        applicationRef.current = DataStore.observe(JobApplicationCompanyData, id).subscribe(msg => {
            if (msg.opType === OpType.DELETE) {
                navigate(ROUTE_NOT_FOUND);
                return;
            }

            // update application
            setApplication(msg.element);

            // update avatar
            fetchAndSetAvatarUrl(msg.element);
        });

        // listener for notes changes
        notesRef.current = DataStore.observe(JobApplicationCompanyDataNote, c => c.jobApplicationCompanyDataId.eq(id)).subscribe(() => {
            fetchNotes();
        });

        // listener for company user data changes
        companyUserRef.current = DataStore.observe(CompanyUser, c => c.companyId.eq(user.companyId)).subscribe(() => {
            fetchNotes();
        });

        // initial fetch
        fetchData();

        // unsubscribe from listeners when unmounting
        return () => {
            applicationRef.current?.unsubscribe();
            notesRef.current?.unsubscribe();
            companyUserRef.current?.unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    /**
     * submit callback for new note
     * @param {object} values values
     */
    const submitCallbackNewNote = async (values) => {
        try {
            // save new note
            await DataStore.save(
                new JobApplicationCompanyDataNote({
                    text: values.text,
                    jobApplicationCompanyDataId: id
                })
            );

            // reset form
            newNoteForm.setInitialValues(newNoteInitialValues);

            // show success
            showNotification({ message: t("general.added"), color: 'green', icon: <Check /> });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    }

    /**
     * deletes a note
     * @param {*} note the note to delete
     */
    const deleteNote = async (note) => {
        try {
            // delete note
            await DataStore.delete(note);

            // show success
            showNotification({ message: t("general.deleted"), color: 'green', icon: <Check /> });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    }

    /**
     * callback to update note text
     * @param {*} values values
     */
    const updateNoteSubmitCallback = async (values) => {
        try {
            await DataStore.save(JobApplicationCompanyDataNote.copyOf(noteEdit, updated => {
                updated.text = values.text
            }));

            showNotification({ message: t("application.notes.edited"), color: 'green', icon: <Check /> });
            setNoteEdit(null);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /**
     * unlocks an application
     */
    const unlockApplication = async () => {
        try {
            setLoading(LOADING_SHOW);

            // check if we got unlocks left
            const companyPaymentData = await DataStore.query(CompanyPaymentData, user.companyId);
            if (companyPaymentData.applicationsLeftToUnlock <= 0) {
                setNoUnlocksModalVisible(true);
                return;
            }

            // unlock application
            await API.graphql({
                query: changeJobApplicationCompanyStatus,
                variables: {
                    jobApplicationCompanyDataId: id,
                    status: JobApplicationCompanyDataStatus.UNLOCKED,
                },
            })

            // unfold additional unlocked accordion items
            setOpenedAccordionItems(openedAccordionItems.concat(openedAccordionItemsUnlocked));
        }
        catch (err) {
            if (err.response?.data?.error?.statusText === "noUnlocks") {
                setError({ action: ERROR_SHOW, error: t("unlock.none") });
            }
            else {
                setError({ action: ERROR_SHOW, error: err });
            }
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook for new note
    const newNoteForm = useForm({
        validationSchema: validationSchema,
        initialValues: newNoteInitialValues,
        submitCallback: submitCallbackNewNote
    });

    // form hook for editing a note
    const editNoteForm = useForm({
        validationSchema: validationSchema,
        initialValues: newNoteInitialValues,
        submitCallback: updateNoteSubmitCallback
    });

    // badges
    const lockedBadge = (
        <Badge
            color="gray"
            variant="outline"
            leftSection={<Center><AlertCircle size={16} /></Center>}
        >
            {t("application.locked")}
        </Badge>
    )
    const deletedBadge = (
        <Badge
            color="gray"
            variant="outline"
            leftSection={<Center><Trash size={16} /></Center>}
        >
            {t("application.status.deleted")}
        </Badge>
    )

    /**
     * implementation to download a file
     * @param {string} fileName file key
     */
    const downloadFile = async (fileKey) => {
        setLoading(LOADING_SHOW);
        try {
            const url = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_COMPANYFILES_APPLICATION_URL, { fileKey: fileKey, jobApplicationCompanyDataId: id });
            const downloadName = fileKey.substr(fileKey.lastIndexOf("/") + 1);
            await downloadFileFromUrl(url, downloadName);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // only show page when application is loaded
    if (!application || !job) {
        return null;
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("application.application")}</Title>
                <Link to={ROUTE_COMPANY_APPLICATIONS}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
            </Group>
            <Divider />

            <Group gap="xs" justify="space-between">
                <Stack>
                    <Group gap="xs">
                        <BadgeTimestamp label={`${t("application.applied_time")}:`} time={application.createdAt} />
                        <BadgeTimestamp label={`${t("date.change_time")}:`} time={application.updatedAt} />
                    </Group>
                </Stack>

                <BadgeApplicationStatusWithUpdate application={application} />
            </Group>

            {application.status === JobApplicationCompanyDataStatus.NEW &&
                <Alert
                    variant="outline"
                    icon={<AlertCircle size={16} />}
                    color="yellow"
                    styles={{
                        label: { flex: "1 1 auto", alignSelf: "flex-start", width: "100%" },
                        body: { width: '100% !important' },
                        root: { flex: "1 1 auto", alignSelf: "flex-start", width: "100%" },
                    }}
                    title={
                        <Group justify="space-between" gutter="xs">
                            <Text>{t("application.unlock")}</Text>
                            <Button size="compact-sm" color="green" onClick={() => unlockApplication()}>{t("application.unlock")}</Button>
                        </Group>
                    }
                >
                    {t("unlock.interested")}
                </Alert>
            }

            <Accordion
                variant="separated"
                multiple
                value={openedAccordionItems}
                onChange={setOpenedAccordionItems}
            >
                <Accordion.Item value="job">
                    <Accordion.Control>{job.name}</Accordion.Control>
                    <Accordion.Panel>
                        <Job job={job} hideCompanyName />
                        <Divider mt="md" />
                        {parseHtml(job.description)}
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="applicant">
                    <Accordion.Control disabled={application.status === JobApplicationCompanyDataStatus.NEW || application.status === JobApplicationCompanyDataStatus.DELETED}>
                        <Group>
                            {t("application.applicant")}
                            {application.status === JobApplicationCompanyDataStatus.NEW && lockedBadge}
                            {application.status === JobApplicationCompanyDataStatus.DELETED && deletedBadge}
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                        {(application.status !== JobApplicationCompanyDataStatus.NEW && application.status !== JobApplicationCompanyDataStatus.DELETED) &&
                            <UserDetails userData={application} avatarUrl={avatarUrl} />
                        }
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="documents">
                    <Accordion.Control
                        disabled={!(application.documents && application.documents.length > 0)}
                    >
                        <Group>
                            {t("user.documents.documents")}
                            {application.status === JobApplicationCompanyDataStatus.NEW && lockedBadge}
                            {application.status === JobApplicationCompanyDataStatus.DELETED && deletedBadge}
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                        <Stack gap="xs">
                            {(application.documents && application.documents.length > 0) ?
                                application.documents.map((e) => (
                                    <UnstyledButton
                                        key={e}
                                        onClick={() => downloadFile(e)}
                                    >
                                        <Group gap="xs">
                                            <ThemeIcon size={24} variant="outline">
                                                <FileDownload />
                                            </ThemeIcon>
                                            <Text size="sm">{e.substr(e.lastIndexOf("/") + 1)}</Text>
                                        </Group>
                                    </UnstyledButton>
                                ))
                                :
                                <Text>{t("user.documents.none")}</Text>
                            }
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="applicationtext">
                    <Accordion.Control disabled={application.status === JobApplicationCompanyDataStatus.NEW || application.status === JobApplicationCompanyDataStatus.DELETED}>
                        <Group>
                            {t("application.letter")}
                            {application.status === JobApplicationCompanyDataStatus.NEW && lockedBadge}
                            {application.status === JobApplicationCompanyDataStatus.DELETED && deletedBadge}
                        </Group>
                    </Accordion.Control>
                    <Accordion.Panel>
                        {application.status !== JobApplicationCompanyDataStatus.NEW &&
                            parseHtml(application.text)
                        }
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="notes">
                    <Accordion.Control>{t("application.notes.notes")}</Accordion.Control>
                    <Accordion.Panel>
                        <Stack>
                            <Timeline
                                active={0}
                                lineWidth={1}
                                bulletSize={50}
                                radius="md"
                                reverseActive={true}
                            >
                                {notes.map((e) => (
                                    <Timeline.Item
                                        key={`note${e.note.id}`}
                                        bullet={
                                            <Avatar
                                                size={50}
                                                identityId={e.companyUser.companyId}
                                                fileKey={e.companyUser.avatar}
                                                fileProtectionLevel={"private"}
                                                placeholder={UserCircle}
                                            />
                                        }
                                    >
                                        <Stack gap="xs">
                                            <Title order={6}>{e.companyUser.name}</Title>

                                            <Card p="xs" radius="md" withBorder>
                                                <Text size="sm">{e.note.text}</Text>
                                            </Card>

                                            <Group gap="xs">
                                                <BadgeTimestamp label={`${t("date.create_time")}:`} time={e.note.createdAt} />
                                                <BadgeTimestamp label={`${t("date.change_time")}:`} time={e.note.updatedAt} />

                                                {(user.id === e.note.owner) &&
                                                    <>
                                                        <UnstyledButton
                                                            onClick={() => {
                                                                editNoteForm.setInitialValues({
                                                                    text: e.note.text
                                                                });
                                                                setNoteEdit(e.note);
                                                            }}
                                                        >
                                                            <Text c="blue" size="xs">{t("general.edit")}</Text>
                                                        </UnstyledButton>
                                                        <UnstyledButton
                                                            onClick={() => openConfirmModal({
                                                                title: t("application.notes.delete_message"),
                                                                labels: { confirm: t("general.delete"), cancel: t("general.cancel") },
                                                                confirmProps: {
                                                                    color: "red"
                                                                },
                                                                onConfirm: () => deleteNote(e.note),
                                                            })}
                                                        >
                                                            <Text c="red" size="xs">{t("general.delete")}</Text>
                                                        </UnstyledButton>
                                                    </>
                                                }
                                            </Group>
                                        </Stack>
                                    </Timeline.Item>

                                ))}
                                <Timeline.Item
                                    color="green"
                                    bullet={<Plus />}
                                >
                                    <form
                                        onSubmit={newNoteForm.onSubmit()}
                                    >
                                        <Textarea
                                            title={t("application.notes.new")}
                                            placeholder={t("application.notes.new")}
                                            {...newNoteForm.getInputProps('text')}
                                            autosize
                                            minRows={3}
                                            mb="xs"
                                        />
                                        <Button variant="outline" size="compact-sm" leftSection={<Plus size={14} />} type="submit" color="green">{t("application.notes.add")}</Button>
                                    </form>
                                </Timeline.Item>
                            </Timeline>
                        </Stack>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            {noteEdit &&
                <Modal
                    opened={true}
                    centered={true}
                    withCloseButton={true}
                    onClose={() => setNoteEdit(null)}
                    size="lg"
                    title={t("application.notes.edit")}
                >
                    <form
                        onSubmit={editNoteForm.onSubmit()}
                    >
                        <Stack>
                            <Textarea
                                title={t("application.notes.note")}
                                placeholder={t("application.notes.note")}
                                {...editNoteForm.getInputProps('text')}
                                autosize
                                minRows={3}
                            />
                            <Group mt="md" justify="flex-end">
                                <Button onClick={() => setNoteEdit(null)} variant="outline" color="gray" leftSection={<X size={14} />}>{t("general.cancel")}</Button>
                                <Button type="submit" variant="outline" color="green" size="sm" leftSection={<File size={14} />}>{t("general.save")}</Button>
                            </Group>
                        </Stack>
                    </form>
                </Modal>
            }

            <Modal
                opened={noUnlocksModalVisible}
                onClose={() => setNoUnlocksModalVisible(false)}
                centered
                withCloseButton={false}
                size="lg"
            >
                <Stack>
                    <Title order={3}>{t("application.unlock")}</Title>
                    <Divider />

                    <Text>{t("unlock.none")}</Text>
                    {user.userGroup === USERGROUP_COMPANYADMIN ?
                        <Text>{t("unlock.more")}</Text>
                        :
                        <Text>{t("unlock.contact")}</Text>
                    }
                    <Group mt="md" justify='space-between'>
                        <Button onClick={() => setNoUnlocksModalVisible(false)} variant="outline" color="yellow" leftSection={<X size={14} />}>{t("general.close")}</Button>
                        {user.userGroup === USERGROUP_COMPANYADMIN && <Button type="submit" color="green" size="sm" leftSection={<LockOpen size={14} />} onClick={() => navigate(ROUTE_COMPANYADMIN_PACKAGES)}>{t("unlock.get")}</Button>}
                    </Group>
                </Stack>
            </Modal>
        </Stack>
    )
}