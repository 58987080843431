import { Button, Group, Modal, PasswordInput, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "./Form";
import { VALIDATION_SCHEMA_PASSWORD, VALIDATION_SCHEMA_PASSWORD_CONFIRMATION, VALIDATION_SCHEMA_STRING } from "../helpers/Validation";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Check, X } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";

// validation schema with yup
const validationSchema = Yup.object().shape({
    currentPassword: VALIDATION_SCHEMA_STRING,
    password: VALIDATION_SCHEMA_PASSWORD,
    passwordConfirm: VALIDATION_SCHEMA_PASSWORD_CONFIRMATION
});

/**
 * modal to change password
 * @param {boolean} opened flag if modal is opened 
 * @param {callback} closeCallback call back on close
 * @returns JSX
 */
export default function ModalChangePassword({ opened, closeCallback }) {

    // globals
    const { t } = useTranslation();
    const [modalOpened, setModalOpened] = useState(opened);

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        setModalOpened(opened);
    },
        [opened]
    );

    /**
     * implementation of close to call close callback if set
     */
    const close = () => {
        if (closeCallback) {
            closeCallback();
        }
        else {
            setModalOpened(false);
        }

        form.reset();
    }

    /**
     * submit callback for change email step
     * @param {object} values form values
     */
    const submitCallback = async (values) => {
        // get current user
        const user = await Auth.currentAuthenticatedUser();

        // change password
        try {
            await Auth.changePassword(user, values.currentPassword, values.password);
            showNotification({ message: t("auth.password.change.changed"), color: 'green', icon: <Check /> });
            close();
        }
        catch (e) {
            if (e.code === "NotAuthorizedException") {
                showNotification({ message: t("auth.error.password_incorrect"), color: 'red', icon: <X /> });
            }
            else if (e.code === "LimitExceededException") {
                showNotification({ message: t("auth.error.max_attempts_reached"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form for password change
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            currentPassword: "",
            password: "",
            passwordConfirm: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Modal opened={modalOpened} onClose={close} title={t("auth.password.change.change")} centered size="lg">
            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <PasswordInput
                        withAsterisk
                        label={t("auth.password.change.current")}
                        placeholder={t("auth.password.change.current")}
                        {...form.getInputProps('currentPassword')}
                    />
                    <PasswordInput
                        withAsterisk
                        label={t("auth.password.change.new")}
                        placeholder={t("auth.password.change.new")}
                        {...form.getInputProps('password')}
                    />
                    <PasswordInput
                        withAsterisk
                        label={t("auth.password.change.confirm")}
                        placeholder={t("auth.password.change.confirm")}
                        {...form.getInputProps('passwordConfirm')}
                    />
                    <Group mt="sm" justify="space-between">
                        <Button color="yellow" type="button" onClick={() => close()}>{t("general.cancel")}</Button>
                        <Button color="green" type="submit">{t("general.change")}</Button>
                    </Group>
                </Stack>
            </form>
        </Modal>
    )
}