import { Navigate, Route, Routes as RouterRoutes } from "react-router-dom";
import { URL_SUPPORT_PORTAL, USERGROUP_ADMIN, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_SUPPORT, USERGROUP_USER } from "./Constants";
import { API_MODE_GET, QUERIES_API_CUSTOMERPORTAL_SESSION, QUERIES_API_NAME, executeApiCall } from "./APIHelper";
import { Briefcase, BuildingSkyscraper, CashBanknote, ChartArrowsVertical, Clock, FileInvoice, Help, Home, Language, List, ListCheck, LockOpen, Search, Settings, UserPlus, Users } from "tabler-icons-react";
import PageGeneralSignin from "../pages/general/PageGeneralSignin";
import PageGeneralChangePassword from "../pages/general/PageGeneralChangePassword";
import PageGeneralSignup from "../pages/general/PageGeneralSignup";
import PageGeneralSignupConfirm from "../pages/general/PageGeneralSignupConfirm";
import PageUserSignup from "../pages/user/PageUserSignup";
import PageCompanySignup from "../pages/company/PageCompanySignup";
import PageGeneralResetPassword from "../pages/general/PageGeneralResetPassword";
import PageGeneralResetpasswordConfirm from "../pages/general/PageGeneralResetPasswordConfirm";
import PageGeneralJob from "../pages/general/PageGeneralJob";
import PageGeneralCompany from "../pages/general/PageGeneralCompany";
import PageUserApplicationNew from "../pages/user/PageUserApplicationNew";
import PageUserApplicationShow from "../pages/user/PageUserApplicationShow";
import PageCompanyJob from "../pages/company/PageCompanyJob";
import PageCompanyApplicationEdit from "../pages/company/PageCompanyApplicationEdit";
import PageCompanyUserNew from "../pages/company/PageCompanyUserNew";
import PageSupportUser from "../pages/support/PageSupportUser";
import PageUserProfileEdit from "../pages/user/PageUserProfileEdit";
import PageSupportCompany from "../pages/support/PageSupportCompany";
import PageCompanyDetails from "../pages/company/PageCompanyDetails";
import PageSupportApplication from "../pages/support/PageSupportApplication";
import PageAdminWorktimeRegulation from "../pages/admin/PageAdminWorktimeRegulation";
import PageAdminJobCategory from "../pages/admin/PageAdminJobCategory";
import PageAdminSupportUserNew from "../pages/admin/PageAdminSupportUserNew";
import PageGeneralNotFound from "../pages/general/PageGeneralNotFound";
import PageGeneralJobs from "../pages/general/PageGeneralJobs";
import PageGeneralHome from "../pages/general/PageGeneralHome";
import PageUserHome from "../pages/user/PageUserHome";
import PageUserApplications from "../pages/user/PageUserApplications";
import PageCompanyHome from "../pages/company/PageCompanyHome";
import PageCompanyJobs from "../pages/company/PageCompanyJobs";
import PageCompanyApplications from "../pages/company/PageCompanyApplications";
import PageCompanyUsers from "../pages/company/PageCompanyUsers";
import PageCompanyPackages from "../pages/company/PageCompanyPackages"; 
import PageSupportHome from "../pages/support/PageSupportHome";
import PageSupportJobs from "../pages/support/PageSupportJobs";
import PageSupportUsers from "../pages/support/PageSupportUsers";
import PageSupportCompanies from "../pages/support/PageSupportCompanies";
import PageSupportApplications from "../pages/support/PageSupportApplications";
import PageAdminJobCategories from "../pages/admin/PageAdminJobCategories";
import PageAdminWorktimeRegulations from "../pages/admin/PageAdminWorktimeRegulations";
import PageAdminSupportUsers from "../pages/admin/PageAdminSupportUsers";
import PageAdminCurrencies from "../pages/admin/PageAdminCurrencies";
import PageAdminCurrency from "../pages/admin/PageAdminCurrency";
import PageAdminLanguages from "../pages/admin/PageAdminLanguages";
import PageAdminLanguage from "../pages/admin/PageAdminLanguage";
import PageAdminPositionLevels from "../pages/admin/PageAdminPositionLevels";
import PageAdminPositionLevel from "../pages/admin/PageAdminPositionLevel";
import RouteWrapper from "../components/RouteWrapper";
import PageGeneralUserProfile from "../pages/general/PageGeneralUserProfile";
import PageCompanyUserEdit from "../pages/company/PageCompanyUserProfileEdit";
import PageCompanySignin from "../pages/company/PageCompanySignin";
import PageUserSignin from "../pages/user/PageUserSignin";

// standard routes
export const ROUTE_ROOT = "/";
export const ROUTE_SIGNIN = "/signin";
export const ROUTE_SIGNIN_CHANGEPASSWORD = "/changepassword"
export const ROUTE_SIGNUP = "/signup";
export const ROUTE_SIGNUP_CONFIRM = `${ROUTE_SIGNUP}/confirm`;
export const ROUTE_PASSWORD_FORGOT = "/forgotpassword";
export const ROUTE_PASSWORD_FORGOT_CONFIRM = `${ROUTE_PASSWORD_FORGOT}/confirm`;
export const ROUTE_HOME = "/home";
export const ROUTE_JOBS = "/jobs";
export const ROUTE_JOBS_SHOW = `${ROUTE_JOBS}/show`;

// user routes
export const ROUTE_USER_ROOT = "/user"
export const ROUTE_USER_SIGNIN = `${ROUTE_USER_ROOT}/signin`;
export const ROUTE_USER_SIGNUP = `${ROUTE_USER_ROOT}/signup`;
export const ROUTE_USER_HOME = `${ROUTE_USER_ROOT}/home`;
export const ROUTE_USER_APPLICATIONS = `${ROUTE_USER_ROOT}/applications`;
export const ROUTE_USER_APPLICATIONS_NEW = `${ROUTE_USER_APPLICATIONS}/new`;
export const ROUTE_USER_APPLICATIONS_SHOW = `${ROUTE_USER_APPLICATIONS}/show`;
export const ROUTE_USER_PROFILE = `${ROUTE_USER_ROOT}/profile`;
export const ROUTE_USER_PROFILE_EDIT = `${ROUTE_USER_PROFILE}/edit`;

// company routes
export const ROUTE_COMPANY_ROOT = "/company";
export const ROUTE_COMPANY_SIGNIN = `${ROUTE_COMPANY_ROOT}/signin`;
export const ROUTE_COMPANY_SIGNUP = `${ROUTE_COMPANY_ROOT}/signup`;
export const ROUTE_COMPANY_HOME = `${ROUTE_COMPANY_ROOT}/home`;
export const ROUTE_COMPANY_JOBS = `${ROUTE_COMPANY_ROOT}/jobs`;
export const ROUTE_COMPANY_JOBS_NEW = `${ROUTE_COMPANY_JOBS}/new`;
export const ROUTE_COMPANY_JOBS_EDIT = `${ROUTE_COMPANY_JOBS}/edit`;
export const ROUTE_COMPANY_APPLICATIONS = `${ROUTE_COMPANY_ROOT}/applications`;
export const ROUTE_COMPANY_APPLICATIONS_EDIT = `${ROUTE_COMPANY_APPLICATIONS}/edit`;
export const ROUTE_COMPANY_USER_PROFILE = `${ROUTE_COMPANY_ROOT}/profile`;
export const ROUTE_COMPANY_USER_PROFILE_EDIT = `${ROUTE_COMPANY_USER_PROFILE}/edit`;
export const ROUTE_COMPANY_DETAILS = `${ROUTE_COMPANY_ROOT}/details`;
export const ROUTE_COMPANY_SHOW = `${ROUTE_COMPANY_ROOT}/show`;

// company admin routes
export const ROUTE_COMPANYADMIN_USERS = "/company/users";
export const ROUTE_COMPANYADMIN_USERS_NEW = `${ROUTE_COMPANYADMIN_USERS}/new`;
export const ROUTE_COMPANYADMIN_PACKAGES = "/company/packages";

// support routes
export const ROUTE_SUPPORT_ROOT = "/support";
export const ROUTE_SUPPORT_HOME = `${ROUTE_SUPPORT_ROOT}/home`;
export const ROUTE_SUPPORT_PROFILE = `${ROUTE_SUPPORT_ROOT}/profile`;
export const ROUTE_SUPPORT_COMPANIES = `${ROUTE_SUPPORT_ROOT}/companies`;
export const ROUTE_SUPPORT_COMPANIES_SHOW = `${ROUTE_SUPPORT_COMPANIES}/show`;
export const ROUTE_SUPPORT_COMPANIES_EDIT = `${ROUTE_SUPPORT_COMPANIES}/edit`;
export const ROUTE_SUPPORT_COMPANIES_USER_NEW = `${ROUTE_SUPPORT_COMPANIES}/user/new`;
export const ROUTE_SUPPORT_COMPANIES_USER_EDIT = `${ROUTE_SUPPORT_COMPANIES}/user/edit`;
export const ROUTE_SUPPORT_USERS = `${ROUTE_SUPPORT_ROOT}/users`;
export const ROUTE_SUPPORT_USERS_SHOW = `${ROUTE_SUPPORT_USERS}/show`;
export const ROUTE_SUPPORT_USERS_EDIT = `${ROUTE_SUPPORT_USERS}/edit`;
export const ROUTE_SUPPORT_JOBS_SEARCH = `${ROUTE_SUPPORT_ROOT}/search`;
export const ROUTE_SUPPORT_JOBS_LIST = `${ROUTE_SUPPORT_ROOT}/jobs`;
export const ROUTE_SUPPORT_JOBS_EDIT = `${ROUTE_SUPPORT_JOBS_LIST}/edit`;
export const ROUTE_SUPPORT_APPLICATIONS = `${ROUTE_SUPPORT_ROOT}/applications`;
export const ROUTE_SUPPORT_APPLICATIONS_SHOW = `${ROUTE_SUPPORT_APPLICATIONS}/show`;

// admin routes
export const ROUTE_ADMIN_ROOT = "/admin";
export const ROUTE_ADMIN_WORKTIMEREGULATIONS = `${ROUTE_ADMIN_ROOT}/worktimeregulations`;
export const ROUTE_ADMIN_WORKTIMEREGULATIONS_NEW = `${ROUTE_ADMIN_WORKTIMEREGULATIONS}/new`;
export const ROUTE_ADMIN_WORKTIMEREGULATIONS_EDIT = `${ROUTE_ADMIN_WORKTIMEREGULATIONS}/edit`;
export const ROUTE_ADMIN_JOBCATEGORY = `${ROUTE_ADMIN_ROOT}/jobcategories`;
export const ROUTE_ADMIN_JOBCATEGORY_NEW = `${ROUTE_ADMIN_JOBCATEGORY}/new`;
export const ROUTE_ADMIN_JOBCATEGORY_EDIT = `${ROUTE_ADMIN_JOBCATEGORY}/edit`;
export const ROUTE_ADMIN_POSITIONLEVEL = `${ROUTE_ADMIN_ROOT}/positionlevels`;
export const ROUTE_ADMIN_POSITIONLEVEL_NEW = `${ROUTE_ADMIN_POSITIONLEVEL}/new`;
export const ROUTE_ADMIN_POSITIONLEVEL_EDIT = `${ROUTE_ADMIN_POSITIONLEVEL}/edit`;
export const ROUTE_ADMIN_CURRENCY = `${ROUTE_ADMIN_ROOT}/currencies`;
export const ROUTE_ADMIN_CURRENCY_NEW = `${ROUTE_ADMIN_CURRENCY}/new`;
export const ROUTE_ADMIN_CURRENCY_EDIT = `${ROUTE_ADMIN_CURRENCY}/edit`;
export const ROUTE_ADMIN_LANGUAGE = `${ROUTE_ADMIN_ROOT}/languages`;
export const ROUTE_ADMIN_LANGUAGE_NEW = `${ROUTE_ADMIN_LANGUAGE}/new`;
export const ROUTE_ADMIN_LANGUAGE_EDIT = `${ROUTE_ADMIN_LANGUAGE}/edit`;
export const ROUTE_ADMIN_SUPPORT_USERS = `${ROUTE_ADMIN_ROOT}/supportusers`;
export const ROUTE_ADMIN_SUPPORTUSERS_NEW = `${ROUTE_ADMIN_SUPPORT_USERS}/new`;

// 404 routes
export const ROUTE_NOT_FOUND = "/404";
export const ROUTE_CATCH = "*";

// wrapper to create url for customer portal and redirect
const getCustomerPortalSessionUrl = async () => {
    const url = await executeApiCall(QUERIES_API_NAME, API_MODE_GET, QUERIES_API_CUSTOMERPORTAL_SESSION, {
        returnUrl: window.location.href,
    });
    return url;
}

// definition of navigation items
export const navigationItems = [
    // general, without auth
    { path: ROUTE_HOME, element: PageGeneralHome, routeType: null, label: "general.dashboard", icon: <Home size={16} /> },
    { path: ROUTE_JOBS, element: PageGeneralJobs, routeType: null, label: "job.find", icon: <Search size={16} /> },

    // users
    { path: ROUTE_USER_HOME, element: PageUserHome, routeType: [USERGROUP_USER], label: "general.dashboard", icon: <Home size={16} /> },
    { path: ROUTE_JOBS, element: PageGeneralJobs, routeType: [USERGROUP_USER], label: "job.find", icon: <Search size={16} /> },
    { path: ROUTE_USER_APPLICATIONS, element: PageUserApplications, routeType: [USERGROUP_USER], label: "application.applications", icon: <ListCheck size={16} /> },

    // company users
    { path: ROUTE_COMPANY_HOME, element: PageCompanyHome, routeType: [USERGROUP_COMPANY, USERGROUP_COMPANYADMIN], label: "general.dashboard", icon: <Home size={16} /> },
    { path: ROUTE_COMPANY_JOBS, element: PageCompanyJobs, routeType: [USERGROUP_COMPANY, USERGROUP_COMPANYADMIN], label: "job.jobs", icon: <Search size={16} /> },
    { path: ROUTE_COMPANY_APPLICATIONS, element: PageCompanyApplications, routeType: [USERGROUP_COMPANY, USERGROUP_COMPANYADMIN], label: "application.applications", icon: <Users size={16} /> },

    // company settings
    {
        label: "general.settings",
        icon: <Settings size={16} />,
        items: [
            { path: ROUTE_COMPANY_DETAILS, element: PageCompanyDetails, routeType: [USERGROUP_COMPANY, USERGROUP_COMPANYADMIN], label: "company.data", icon: <BuildingSkyscraper size={16} /> },
            { redirect: getCustomerPortalSessionUrl, routeType: [USERGROUP_COMPANYADMIN], label: "company.invoices", icon: <FileInvoice size={16} /> },
            { path: ROUTE_COMPANYADMIN_USERS, element: PageCompanyUsers, routeType: [USERGROUP_COMPANYADMIN], label: "company.user.administration", icon: <UserPlus size={16} /> },
            { path: ROUTE_COMPANYADMIN_PACKAGES, element: PageCompanyPackages, routeType: [USERGROUP_COMPANYADMIN], label: "unlock.unlocks", icon: <LockOpen size={16} /> },
        ]
    },


    // support
    { path: ROUTE_SUPPORT_HOME, element: PageSupportHome, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "general.dashboard", icon: <Home size={16} /> },
    {
        label: "job.jobs",
        icon: <Settings size={16} />,
        items: [
            { path: ROUTE_SUPPORT_JOBS_SEARCH, element: PageGeneralJobs, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "job.find", icon: <Search size={16} /> },
            { path: ROUTE_SUPPORT_JOBS_LIST, element: PageSupportJobs, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "job.jobs", icon: <List size={16} /> },
        ]
    },
    { path: ROUTE_SUPPORT_USERS, element: PageSupportUsers, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "user.users", icon: <Users size={16} /> },
    { path: ROUTE_SUPPORT_COMPANIES, element: PageSupportCompanies, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "company.companies", icon: <BuildingSkyscraper size={16} /> },
    { path: ROUTE_SUPPORT_APPLICATIONS, element: PageSupportApplications, routeType: [USERGROUP_SUPPORT, USERGROUP_ADMIN], label: "application.applications", icon: <ListCheck size={16} /> },

    // admin
    {
        label: "general.settings",
        icon: <Settings size={16} />,
        items: [
            { path: ROUTE_ADMIN_LANGUAGE, element: PageAdminLanguages, routeType: [USERGROUP_ADMIN], label: "language.languages", icon: <Language size={16} /> },
            { path: ROUTE_ADMIN_CURRENCY, element: PageAdminCurrencies, routeType: [USERGROUP_ADMIN], label: "currency.currencies", icon: <CashBanknote size={16} /> },
            { path: ROUTE_ADMIN_JOBCATEGORY, element: PageAdminJobCategories, routeType: [USERGROUP_ADMIN], label: "jobcategory.jobcategories", icon: <Briefcase size={16} /> },
            { path: ROUTE_ADMIN_WORKTIMEREGULATIONS, element: PageAdminWorktimeRegulations, routeType: [USERGROUP_ADMIN], label: "worktimeregulation.worktimeregulations", icon: <Clock size={16} /> },
            { path: ROUTE_ADMIN_POSITIONLEVEL, element: PageAdminPositionLevels, routeType: [USERGROUP_ADMIN], label: "positionlevel.positionlevels", icon: <ChartArrowsVertical size={16} /> },
            { path: ROUTE_ADMIN_SUPPORT_USERS, element: PageAdminSupportUsers, routeType: [USERGROUP_ADMIN], label: "support.user.users", icon: <Help size={16} /> },
        ]
    },

    // support
    { url: URL_SUPPORT_PORTAL, routeType: null, label: "general.support", icon: <Help size={16} /> },
    { url: URL_SUPPORT_PORTAL, routeType: [USERGROUP_USER, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN], label: "general.support", icon: <Help size={16} /> },
];

/**
 * renders routes for the provided navigation item
 * @param {object} item the navigation item to render routes for
 * @returns array of routes
 */
const getRouteFromNavigationItem = (item) => {
    if (item.items) {
        return item.items.map((item) => getRouteFromNavigationItem(item));
    }

    return (<Route exact path={item.path} element={<RouteWrapper title={item.label} Component={item.element} userGroups={item.routeType} />} key={item.path} />);
}

// all routes
export const Routes = (
    <RouterRoutes>
        <Route exact path={ROUTE_ROOT} element={<Navigate to={ROUTE_HOME} />} />
        <Route exact path={ROUTE_USER_ROOT} element={<Navigate to={ROUTE_USER_HOME} />} />
        <Route exact path={ROUTE_COMPANY_ROOT} element={<Navigate to={ROUTE_COMPANY_HOME} />} />
        <Route exact path={ROUTE_SUPPORT_ROOT} element={<Navigate to={ROUTE_SUPPORT_HOME} />} />

        {navigationItems.map((item) => getRouteFromNavigationItem(item))};

        <Route exact path={ROUTE_SIGNIN} element={<RouteWrapper title="auth.signin.text" Component={PageGeneralSignin} />} />
        <Route exact path={ROUTE_SIGNIN_CHANGEPASSWORD} element={<RouteWrapper title="auth.password.change.change" signedIn={false} Component={PageGeneralChangePassword} />} />
        <Route exact path={ROUTE_SIGNUP} element={<RouteWrapper title="auth.signup.text" signedIn={false} Component={PageGeneralSignup} />} />
        <Route exact path={ROUTE_SIGNUP_CONFIRM} element={<RouteWrapper title="auth.password.confirm" signedIn={false} Component={PageGeneralSignupConfirm} />} />
        <Route exact path={ROUTE_USER_SIGNUP} element={<RouteWrapper title="auth.signup.text" signedIn={false} Component={PageUserSignup} />} />
        <Route exact path={ROUTE_COMPANY_SIGNUP} element={<RouteWrapper title="auth.signup.text" signedIn={false} Component={PageCompanySignup} />} />
        <Route exact path={ROUTE_PASSWORD_FORGOT} element={<RouteWrapper title="auth.password.forgot" signedIn={false} Component={PageGeneralResetPassword} />} />
        <Route exact path={ROUTE_PASSWORD_FORGOT_CONFIRM} element={<RouteWrapper title="auth.password.change.change" signedIn={false} Component={PageGeneralResetpasswordConfirm} />} />
        <Route exact path={ROUTE_JOBS_SHOW + "/:id"} element={<RouteWrapper title="job.job" Component={PageGeneralJob} />} />
        <Route exact path={ROUTE_COMPANY_SHOW + "/:id"} element={<RouteWrapper title="company.company" Component={PageGeneralCompany} />} />
        <Route exact path={ROUTE_COMPANY_USER_PROFILE} element={<RouteWrapper title="user.profile.profile" userGroups={[USERGROUP_COMPANY, USERGROUP_COMPANYADMIN]} Component={PageGeneralUserProfile} />} />

        <Route exact path={ROUTE_USER_SIGNIN} element={<RouteWrapper title="auth.signin.text" Component={PageUserSignin} />} />
        <Route exact path={ROUTE_USER_PROFILE} element={<RouteWrapper title="user.profile.profile" userGroups={[USERGROUP_USER]} Component={PageGeneralUserProfile} />} />
        <Route exact path={ROUTE_USER_PROFILE_EDIT} element={<RouteWrapper title="user.profile.profile" userGroups={[USERGROUP_USER]} Component={PageUserProfileEdit} />} />
        <Route exact path={ROUTE_USER_APPLICATIONS_NEW + "/:id"} element={<RouteWrapper title="application.send" userGroups={[USERGROUP_USER]} Component={PageUserApplicationNew} />} />
        <Route exact path={ROUTE_USER_APPLICATIONS_SHOW + "/:id"} element={<RouteWrapper title="application.application" userGroups={[USERGROUP_USER]} Component={PageUserApplicationShow} />} />

        <Route exact path={ROUTE_COMPANY_SIGNIN} element={<RouteWrapper title="auth.signin.text" Component={PageCompanySignin} />} />
        <Route exact path={ROUTE_COMPANY_JOBS_NEW} element={<RouteWrapper title="job.create" userGroups={[USERGROUP_COMPANY, USERGROUP_COMPANYADMIN]} Component={PageCompanyJob} />} />
        <Route exact path={ROUTE_COMPANY_JOBS_EDIT + "/:id"} element={<RouteWrapper title="job.edit" userGroups={[USERGROUP_COMPANY, USERGROUP_COMPANYADMIN]} Component={PageCompanyJob} />} />
        <Route exact path={ROUTE_COMPANY_APPLICATIONS_EDIT + "/:id"} element={<RouteWrapper title="application.application" userGroups={[USERGROUP_COMPANY, USERGROUP_COMPANYADMIN]} Component={PageCompanyApplicationEdit} />} />
        <Route exact path={ROUTE_COMPANY_USER_PROFILE} element={<RouteWrapper title="user.profile.profile" userGroups={[USERGROUP_COMPANY, USERGROUP_COMPANYADMIN]} Component={PageGeneralUserProfile} />} />
        <Route exact path={ROUTE_COMPANY_USER_PROFILE_EDIT} element={<RouteWrapper title="user.profile.profile" userGroups={[USERGROUP_COMPANY, USERGROUP_COMPANYADMIN]} Component={PageCompanyUserEdit} />} />
        <Route exact path={ROUTE_COMPANYADMIN_USERS_NEW} element={<RouteWrapper title="auth.signup.invite" userGroups={[USERGROUP_COMPANYADMIN]} Component={PageCompanyUserNew} />} />

        <Route exact path={ROUTE_SUPPORT_PROFILE} element={<RouteWrapper title="user.profile.profile" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageGeneralUserProfile} />} />
        <Route exact path={ROUTE_SUPPORT_USERS_SHOW + "/:userId"} element={<RouteWrapper title="user.group.user" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageSupportUser} />} />
        <Route exact path={ROUTE_SUPPORT_USERS_EDIT + "/:userId"} element={<RouteWrapper title="user.group.user" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageUserProfileEdit} />} />
        <Route exact path={ROUTE_SUPPORT_COMPANIES_SHOW + "/:companyId"} element={<RouteWrapper title="company.company" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageSupportCompany} />} />
        <Route exact path={ROUTE_SUPPORT_COMPANIES_EDIT + "/:companyId"} element={<RouteWrapper title="company.company" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageCompanyDetails} />} />
        <Route exact path={ROUTE_SUPPORT_COMPANIES_USER_NEW + "/:companyId"} element={<RouteWrapper title="auth.signup.invite" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageCompanyUserNew} />} />
        <Route exact path={ROUTE_SUPPORT_COMPANIES_USER_EDIT + "/:userId"} element={<RouteWrapper title="user.group.user" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageCompanyUserEdit} />} />
        <Route exact path={ROUTE_SUPPORT_JOBS_EDIT + "/:id"} element={<RouteWrapper title="job.edit" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageCompanyJob} />} />
        <Route exact path={ROUTE_SUPPORT_APPLICATIONS_SHOW + "/:id"} element={<RouteWrapper title="application.application" userGroups={[USERGROUP_SUPPORT, USERGROUP_ADMIN]} Component={PageSupportApplication} />} />

        <Route exact path={ROUTE_ADMIN_LANGUAGE_NEW} element={<RouteWrapper title="language.new" userGroups={[USERGROUP_ADMIN]} Component={PageAdminLanguage} />} />
        <Route exact path={ROUTE_ADMIN_LANGUAGE_EDIT + "/:code"} element={<RouteWrapper title="language.edit" userGroups={[USERGROUP_ADMIN]} Component={PageAdminLanguage} />} />
        <Route exact path={ROUTE_ADMIN_CURRENCY_NEW} element={<RouteWrapper title="currency.new" userGroups={[USERGROUP_ADMIN]} Component={PageAdminCurrency} />} />
        <Route exact path={ROUTE_ADMIN_CURRENCY_EDIT + "/:code"} element={<RouteWrapper title="currency.edit" userGroups={[USERGROUP_ADMIN]} Component={PageAdminCurrency} />} />
        <Route exact path={ROUTE_ADMIN_WORKTIMEREGULATIONS_NEW} element={<RouteWrapper title="worktimeregulation.new" userGroups={[USERGROUP_ADMIN]} Component={PageAdminWorktimeRegulation} />} />
        <Route exact path={ROUTE_ADMIN_WORKTIMEREGULATIONS_EDIT + "/:id"} element={<RouteWrapper title="worktimeregulation.edit" userGroups={[USERGROUP_ADMIN]} Component={PageAdminWorktimeRegulation} />} />
        <Route exact path={ROUTE_ADMIN_JOBCATEGORY_NEW} element={<RouteWrapper title="jobcategory.new" userGroups={[USERGROUP_ADMIN]} Component={PageAdminJobCategory} />} />
        <Route exact path={ROUTE_ADMIN_JOBCATEGORY_EDIT + "/:id"} element={<RouteWrapper title="jobcategory.edit" userGroups={[USERGROUP_ADMIN]} Component={PageAdminJobCategory} />} />
        <Route exact path={ROUTE_ADMIN_POSITIONLEVEL_NEW} element={<RouteWrapper title="positionlevel.new" userGroups={[USERGROUP_ADMIN]} Component={PageAdminPositionLevel} />} />
        <Route exact path={ROUTE_ADMIN_POSITIONLEVEL_EDIT + "/:id"} element={<RouteWrapper title="positionlevel.edit" userGroups={[USERGROUP_ADMIN]} Component={PageAdminPositionLevel} />} />
        <Route exact path={ROUTE_ADMIN_SUPPORTUSERS_NEW} element={<RouteWrapper title="support.user.create" userGroups={[USERGROUP_ADMIN]} Component={PageAdminSupportUserNew} />} />

        <Route path={ROUTE_NOT_FOUND} element={<PageGeneralNotFound />} />
        <Route path={ROUTE_CATCH} element={<PageGeneralNotFound />} />
    </RouterRoutes>
)