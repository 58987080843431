/**
 * Custom input component to render background as mantine input group
 * @param {object} props properties
 * @returns JSX
 */
export default function InputBackground(props) {
    return (
        <div className="input-background" style={props.style}>
            {props.children}
        </div>
    )
}
