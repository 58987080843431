import { Accordion, Button, Group, Stack, TextInput, ThemeIcon } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { useParams } from "react-router-dom";
import { Filter, QuestionMark, Reload } from "tabler-icons-react";
import { PREDICATE_CONTAINS, PREDICATE_EQUALS, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../helpers/Constants";
import { useUserState } from "../helpers/GlobalUserState";
import { getFilterPredicates } from "../helpers/Predicates";
import { JobApplicationCompanyData } from "../models";
import { useForm } from "./Form";
import List, { COLUMN_TYPE_APPLICATIONSTATUS, COLUMN_TYPE_TIMESTAMP } from "./List";
import SortDirectionInput from "./SortDirectionInput";
import SortFieldInput from "./SortFieldInput";
import { ROUTE_COMPANY_APPLICATIONS_EDIT, ROUTE_SUPPORT_APPLICATIONS_SHOW } from "../helpers/Routes";
import { useTranslation } from 'react-i18next';
import ApplicationStatusSelect from "./ApplicationStatusSelect";

/**
 * list of applications of a company
 * @returns JSX
 */
export default function CompanyApplicationsList() {

    // globals
    const user = useUserState();
    const { companyId } = useParams();
    const { t } = useTranslation();

    // form hook
    const form = useForm({
        initialValues: {
            jobName: "",
            applicantName: "",
            status: null,
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "jobOffer.name", value: form.values.jobName, type: PREDICATE_CONTAINS },
            { key: "name", value: form.values.applicantName, type: PREDICATE_CONTAINS },
            { key: "status", value: form.values.status, type: PREDICATE_CONTAINS },
            { key: "companyId", value: companyId ? companyId : user.companyId, type: PREDICATE_EQUALS },
        ]);
    }

    return (
        <Stack>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("job.job")}
                                    placeholder={t("job.job")}
                                    {...form.getInputProps('jobName')}
                                />

                                <TextInput
                                    label={t("application.applicant")}
                                    placeholder={t("application.applicant")}
                                    {...form.getInputProps('applicantName')}
                                />

                                <ApplicationStatusSelect
                                    label={t("general.status")}
                                    placeholder={t("general.status")}
                                    {...form.getInputProps('status')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'status', label: t('general.status') },
                                        { value: 'createdAt', label: t('date.create_time') },
                                        { value: 'updatedAt', label: t('date.change_time') },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group justify='space-between'>
                                    <Button variant="outline" leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={JobApplicationCompanyData}
                id={"id"}
                dataStructure={[
                    { key: "jobOffer.name", title: t("job.job") },
                    { key: "name", title: t("application.applicant"), placeholder: <ThemeIcon variant="outline" size={16}><QuestionMark /></ThemeIcon> },
                    { key: "status", title: t("general.status"), type: COLUMN_TYPE_APPLICATIONSTATUS },
                    { key: "createdAt", title: t("date.create_time"), type: COLUMN_TYPE_TIMESTAMP },
                    { key: "updatedAt", title: t("date.change_time"), type: COLUMN_TYPE_TIMESTAMP },
                ]}
                showRoute={(user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) ? ROUTE_SUPPORT_APPLICATIONS_SHOW : null}
                editRoute={user.companyId ? ROUTE_COMPANY_APPLICATIONS_EDIT : null}
                filter={getFilter()}
                sortField={form.values.sortField}
                sortDirection={form.values.sortDirection}
            />
        </Stack>
    )
}