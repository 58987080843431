import { Divider, Stack, Title } from "@mantine/core";
import CompanyApplicationsList from "../../components/CompanyApplicationsList";
import { useTranslation } from 'react-i18next';

/**
 * company page to view applications
 * @returns JSX
 */
export default function PageCompanyApplications() {

    // globals
    const { t } = useTranslation();

    return (
        <Stack>
            <Title>{t("application.applications")}</Title>
            <Divider />

            <CompanyApplicationsList />
        </Stack>
    )
}