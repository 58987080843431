import { NumberInput as MantineNumberInput } from '@mantine/core';
import { getNumberSeparators } from '../helpers/i18n';

/**
 * Custom number input component
 * @param {object} props properties
 * @returns JSX
 */
export default function NumberInput(props) {

    // globals
    const [ thousandSeparator, decimalSeparator] = getNumberSeparators();

    return (
        <MantineNumberInput
            hideControls
            allowNegative={false}
            allowDecimal
            decimalSeparator={decimalSeparator}
            thousandSeparator={thousandSeparator}
            {...props}
            onChange={(val) => {
                if (!val) {
                    props.onChange(null);
                    return;
                }
                else if (typeof val === "string") {
                    props.onChange(parseFloat(val));
                    return;
                }
                else {
                    props.onChange(val);
                }
            }}
        />
    )
}



