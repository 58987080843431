import { Paper, Text } from "@mantine/core";
import { Carousel as MantineCarousel } from "@mantine/carousel";
import { uuid4 } from "@sentry/utils";

/**
 * custom carousel renderer
 * @param {object} props component props
 */
export default function Carousel(props) {

    return (
        <Paper
            withBorder
            shadow="md"
            radius="md"
        >
            {props.title && <Text size="sm" color="dimmed" p="md" pb={0}>{props.title}</Text>}
            <MantineCarousel
                align="start"
                containScroll="trimSnaps"
                slideGap="md"
                slideSize={{ base: '100%', lg: '33.333333%' }}
                includeGapInSize
                withIndicators={props.children && props.children.length > 1}
                style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingBottom: (props.children && props.children.length > 1) ? 35 : 15,
                    paddingTop: 15
                }}
                controlSize={20}
                controlsOffset={5}
            >
                {props.children.map(e => {
                    return (
                        <MantineCarousel.Slide key={uuid4()}>
                            {e}
                        </MantineCarousel.Slide>
                    )
                })}
            </MantineCarousel>
        </Paper>
    );
}