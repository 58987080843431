import { ReportMoney } from "tabler-icons-react";
import { TIME_UNIT_TEXT } from "../helpers/Constants";
import BadgeAutoHeight from "./BadgeAutoHeight";
import { formatNumber } from "../helpers/i18n";
import { useTranslation } from "react-i18next";

/**
 * salary badge
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeSalary(props) {

    // globals
    const { t } = useTranslation();

    // if no salary set, do not render
    if (props.salary.salary === 0) {
        return null;
    }

    return (
        <BadgeAutoHeight
            color="green"
            variant="outline"
            leftSection={<ReportMoney size={14} />}
            size="xs"
        >
            {`${formatNumber(props.salary.salary, { minimumFractionDigits: 0, maximumFractionDigits: 2, style: 'currency', currency: props.salary.currencyCode })} / ${t(TIME_UNIT_TEXT[props.salary.timeUnit])}`}
        </BadgeAutoHeight>
    )
}