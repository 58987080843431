// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TimeUnit = {
  "DAY": "DAY",
  "WEEK": "WEEK",
  "MONTH": "MONTH"
};

const JobApplicationCompanyDataStatus = {
  "NEW": "NEW",
  "UNLOCKED": "UNLOCKED",
  "INPROGRESS": "INPROGRESS",
  "WAITING": "WAITING",
  "INVITED": "INVITED",
  "DECLINED": "DECLINED",
  "ACCEPTED": "ACCEPTED",
  "HIRED": "HIRED",
  "DELETED": "DELETED"
};

const { Currency, Language, Company, CompanyPaymentData, CompanyUser, User, JobApplication, JobApplicationCompanyData, JobApplicationCompanyDataNote, WorkTimeRegulation, JobCategory, PositionLevel, JobOffer, Translation, Location, Salary, Hours, ElasticSearchJobOffersConnection } = initSchema(schema);

export {
  Currency,
  Language,
  Company,
  CompanyPaymentData,
  CompanyUser,
  User,
  JobApplication,
  JobApplicationCompanyData,
  JobApplicationCompanyDataNote,
  WorkTimeRegulation,
  JobCategory,
  PositionLevel,
  JobOffer,
  TimeUnit,
  JobApplicationCompanyDataStatus,
  Translation,
  Location,
  Salary,
  Hours,
  ElasticSearchJobOffersConnection
};