import React, { useEffect, useState } from 'react';
import { Combobox, Group, Loader, Text, TextInput, useCombobox } from '@mantine/core';
import { CurrentLocation, Search } from 'tabler-icons-react';
import { Geo } from 'aws-amplify';
import debounce from 'lodash.debounce';
import * as Sentry from "@sentry/react";
import { useTranslation } from 'react-i18next';

/**
 * location search input
 */
const LocationSearchInput = (props) => {

    // globals
    const [searchResults, setSearchResults] = useState([]);
    const [searchError, setSearchError] = useState(false);
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const combobox = useCombobox();

    // field names from props
    var fieldNames = {
        addressText: props.addressTextFieldName ? props.addressTextFieldName : "addressText",
        location: props.locationFieldName ? props.locationFieldName : "location",
    };

    /**
     * fetches location results by text
     * @param {string}   value location text to search locations for
     * @param {function} cb    the callback to call with results
     * @returns array of results
     */
    const fetchData = async (value) => {
        try {
            setLoading(true);

            // check if value was input
            if (!value || value === "") {
                setSearchResults([]);
                return;
            }

            // get locations
            const results = await Geo.searchByText(value);

            // if no locations found, return
            if (results.count === 0) {
                setSearchResults([]);
                return;
            }

            // map results to expected format
            const mappedResults = results.map((e, i) => {
                return {
                    key: i,
                    lat: e.geometry.point[1],
                    lon: e.geometry.point[0],
                    label: e.label,
                }
            });

            // set results
            setSearchResults(mappedResults);
            setSearchError(false);
        }
        catch (e) {
            setSearchResults([]);
            setSearchError(true);
            Sentry.captureException(e);
        }
        finally {
            setLoading(false);
        }

    };

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = React.useRef(debounce(async (value) => {
        fetchData(value);
    }, 500)).current;

    /**
     * wrapper for input value change
     * @param {string} value text to search locations for
     */
    const onSearchChange = (value) => {
        setFormValues(value, null, null);
    }

    /**
     * handler for the select event
     * @param {object} value the selected entry
     */
    const onSelect = (value) => {
        setFormValues(value.label, value.lat, value.lon);
    }

    /**
     * use effect hook to listen to search text changes
     */
    useEffect(() => {
        debouncedFetchData(props.form.values[fieldNames.addressText]);
    },
        [props.form.values, fieldNames.addressText, debouncedFetchData]
    );

    /**
     * checks if there is an error in the validation for the location fields
     * @returns errortext or false
     */
    const getError = () => {
        if (props.form.errors[`${fieldNames.location}.lon`]) {
            return props.form.errors[`${fieldNames.location}.lon`];
        }

        if (props.form.errors[`${fieldNames.location}.lat`]) {
            return props.form.errors[`${fieldNames.location}.lat`];
        }

        if (props.form.errors[fieldNames.addressText]) {
            return props.form.errors[fieldNames.addressText];
        }

        return false;
    }

    /**
     * gets the correct placeholder text depending on state
     * @returns placeholder text
     */
    const getSearchPlaceholder = () => {
        if (searchError) {
            return t("error.unexpected");
        }

        if (loading) {
            return t("search.loading");
        }

        if (!props.form.values[fieldNames.addressText]) {
            return t("general.address");
        }

        if (searchResults.length === 0) {
            return t("search.none");
        }

        return "";
    }

    /**
     * wrapper to set values in form respecting form input field names
     * @param {string} addressText address text to set
     * @param {float}  lat         latitude to set
     * @param {float}  lon         longitude to set
     */
    const setFormValues = (addressText, lat, lon) => {
        var values = {};
        values[fieldNames.addressText] = addressText;
        values[fieldNames.location] = { lat: lat, lon: lon };
        props.form.setValues({ ...values });
    }

    return (
        <Combobox
            store={combobox}
            onOptionSubmit={(val) => {
                onSelect(val);
                combobox.closeDropdown();
            }}
        >
            <Combobox.Target>
                <TextInput
                    label={props.label ? props.label : t("general.address")}
                    placeholder={props.placeholder ? props.placeholder : `${t("general.address")}...`}
                    value={props.form.values[fieldNames.addressText]}
                    onChange={(event) => {
                        setLoading(true);
                        onSearchChange(event.target.value);
                        combobox.openDropdown();
                        combobox.updateSelectedOptionIndex();
                    }}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                    error={getError()}
                    rightSection={loading ? <Loader size="1rem" /> : null}
                    leftSection={<Search size={14} />}
                    withAsterisk={props.withAsterisk}
                />
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Options>
                    {searchResults.length === 0 ?
                        <Combobox.Empty><Group><Text size="sm">{getSearchPlaceholder()}</Text></Group></Combobox.Empty>
                        :
                        searchResults.map((item, index) => (
                            <Combobox.Option value={item} key={index}>
                                <Group wrap="nowrap">
                                    <CurrentLocation size={14} />
                                    <Text size="sm">{item.label}</Text>
                                </Group>
                            </Combobox.Option>
                        ))
                    }
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    )
}

// Default export of the class
export default LocationSearchInput;