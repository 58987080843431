import { Accordion, ActionIcon, Button, Center, Divider, Group, Menu, Paper, Select, Stack, Table, TextInput, Title, UnstyledButton } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Check, Filter, Plus, Reload, Settings, Star, StarOff, UserCheck, UserOff, Search } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import { OPTIONS_YESNO_WITHALL, OPTION_ALL, OPTION_NO, OPTION_YES, TABLE_ACTION_SIZE, TABLE_ACTION_WIDTH, USERGROUPS_ALL, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL } from "../../helpers/Validation";
import { showNotification } from "@mantine/notifications";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import { executeApiCall, ADMIN_API_ADMIN_ADDUSERTOGROUP, ADMIN_API_ADMIN_DISABLEUSER, ADMIN_API_ADMIN_ENABLEUSER, ADMIN_API_ADMIN_LISTSUPPORTUSERS, ADMIN_API_ADMIN_REMOVEUSERFROMGROUP, API_MODE_GET, API_MODE_POST, ADMIN_API_NAME } from "../../helpers/APIHelper";
import { ROUTE_ADMIN_SUPPORTUSERS_NEW } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';

// validation schema with yup for user filter
const validationSchemaUserFilter = Yup.object().shape({
    enabled: VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL,
});

// initial values for user filter
const initialValuesUserFilter = {
    name: "",
    email: "",
    enabled: OPTION_ALL,
    group: USERGROUPS_ALL,
};

/**
 * support page to list support users
 */
export default function PageAdminSupportUsers() {

    // globals
    const usersRef = useRef([]);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const { t } = useTranslation();

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        try {
            const users = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_ADMIN_LISTSUPPORTUSERS);
            usersRef.current = users;
            filterUsers();
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * submit callback for user filter form
     * @param {object} values form values
     */
    const filterUserSubmitCallback = async () => {
        try {
            setLoading(LOADING_SHOW);
            filterUsers();

        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * filters users depending on set filter in filter form
     */
    const filterUsers = () => {
        // start with all users
        var filteredUsers = [...usersRef.current];
        const values = form.values;

        // filter email
        if (values.email) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.email.toLowerCase().includes(values.email.toLowerCase());
            });
        }

        // filter enabled state
        if (values.enabled === OPTION_YES) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === true;
            });
        }
        else if (values.enabled === OPTION_NO) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === false;
            });
        }

        // filter group
        if (values.group !== USERGROUPS_ALL) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.groups.includes(values.group);
            });
        }

        setFilteredUsers(filteredUsers);
    }

    /**
     * reset callback for user filter
     */
    const resetUserFilterCallback = () => {
        setFilteredUsers(usersRef.current);
    }

    /**
     * changes the users enabled status
     * @param {string}  username    username to update
     * @param {boolean} enabled     enabled status
     */
    const changeUserEnabled = async (username, enabled) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update user
            await executeApiCall(
                ADMIN_API_NAME,
                API_MODE_POST,
                enabled ? ADMIN_API_ADMIN_ENABLEUSER : ADMIN_API_ADMIN_DISABLEUSER,
                {
                    "username": username,
                }
            )
            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                newUsers[index].enabled = enabled;
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            showNotification({ message: enabled ? t("user.status.activated") : t("user.status.deactivated"), color: 'green', icon: <Check /> });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * changes the admin status of a user
     * @param {string}  username  username to update
     * @param {boolean} admin     admin status
     */
    const changeAdminPermission = async (username, admin) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // set new status
            await executeApiCall(
                ADMIN_API_NAME,
                API_MODE_POST,
                admin ? ADMIN_API_ADMIN_ADDUSERTOGROUP : ADMIN_API_ADMIN_REMOVEUSERFROMGROUP,
                {
                    "username": username,
                    "groupname": USERGROUP_ADMIN
                }
            )

            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                if (admin) {
                    newUsers[index].groups = [USERGROUP_ADMIN, USERGROUP_SUPPORT];
                }
                else {
                    newUsers[index].groups = [USERGROUP_SUPPORT];
                }
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            if (admin) {
                showNotification({ message: t("user.permissions.admin_added"), color: 'green', icon: <Check /> });
            } else {
                showNotification({ message: t("user.permissions.admin_removed"), color: 'green', icon: <Check /> });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook for user filter
    const form = useForm({
        validationSchema: validationSchemaUserFilter,
        initialValues: initialValuesUserFilter,
        submitCallback: filterUserSubmitCallback,
        resetCallback: resetUserFilterCallback,
    });

    /**
     * wrapper to map users in table
     */
    const rows = filteredUsers.map((element) => {
        return (
            <Table.Tr key={element.username}>
                <Table.Td
                    style={{
                        padding: 5,
                        width: TABLE_ACTION_WIDTH
                    }}
                >
                    <Menu position="right-start" withinPortal>
                        <Menu.Target>
                            <Center><ActionIcon size={TABLE_ACTION_SIZE} variant="outline"><Settings /></ActionIcon></Center>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {element.enabled === true ?
                                <Menu.Item
                                    leftSection={<UserOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, false)}
                                >
                                    {t("user.status.deactivate")}
                                </Menu.Item>
                                :
                                <Menu.Item
                                    leftSection={<UserCheck size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, true)}
                                >
                                    {t("user.status.activate")}
                                </Menu.Item>
                            }

                            {element.groups.includes(USERGROUP_ADMIN) ?
                                <Menu.Item
                                    leftSection={<StarOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, false)}
                                >
                                    {t("user.permissions.admin_remove")}
                                </Menu.Item>
                                :
                                <Menu.Item
                                    leftSection={<Star size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, true)}
                                >
                                    {t("user.permissions.admin_add")}
                                </Menu.Item>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </Table.Td>
                <Table.Td>{element.email}</Table.Td>
                <Table.Td>{element.enabled ? t("general.yes") : t("general.no")}</Table.Td>
                <Table.Td>{element.groups.join(", ")}</Table.Td>
                <Table.Td>{element.status}</Table.Td>
                <Table.Td>{<BadgeTimestamp timestamp={element.createdAt} />}</Table.Td>
                <Table.Td>{<BadgeTimestamp timestamp={element.updatedAt} />}</Table.Td>
            </Table.Tr>
        )
    });

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("support.user.users")}</Title>
                <Button
                    color="green"
                    leftSection={<Plus />}
                    onClick={() => navigate(ROUTE_ADMIN_SUPPORTUSERS_NEW)}
                >
                    {t("support.user.create")}
                </Button>
            </Group>
            <Divider />

            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onSubmit={form.onSubmit()}
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("general.email")}
                                    placeholder={t("general.email")}
                                    {...form.getInputProps('email')}
                                />

                                <Select
                                    label={t("general.active")}
                                    placeholder="..."
                                    data={OPTIONS_YESNO_WITHALL}
                                    {...form.getInputProps('enabled')}
                                />

                                <Select
                                    label={t("user.permissions.permissions")}
                                    placeholder={t("user.permissions.permissions")}
                                    data={[
                                        { value: USERGROUPS_ALL, label: t("general.all") },
                                        { value: USERGROUP_SUPPORT, label: t("user.group.support") },
                                        { value: USERGROUP_ADMIN, label: t("user.group.admin") },
                                    ]}
                                    {...form.getInputProps('group')}
                                />

                                <Group mt="sm" justify="space-between">
                                    <Button variant="outline" color="yellow" leftSection={<Reload />} type="reset">{t("general.reset")}</Button>
                                    <Button variant="outline" color="green" leftSection={<Search />} type="submit">{t("search.search")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Paper radius="sm" withBorder>
                <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover withRowBorders>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th></Table.Th>
                            <Table.Th>{t("general.email")}</Table.Th>
                            <Table.Th>{t("general.active")}</Table.Th>
                            <Table.Th>{t("user.permissions.permissions")}</Table.Th>
                            <Table.Th>{t("general.status")}</Table.Th>
                            <Table.Th>{t("date.create_time")}</Table.Th>
                            <Table.Th>{t("date.change_time")}</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Paper>
        </Stack>
    )
}