/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createCurrency = /* GraphQL */ `
  mutation CreateCurrency(
    $input: CreateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    createCurrency(input: $input, condition: $condition) {
      code
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCurrency = /* GraphQL */ `
  mutation UpdateCurrency(
    $input: UpdateCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    updateCurrency(input: $input, condition: $condition) {
      code
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCurrency = /* GraphQL */ `
  mutation DeleteCurrency(
    $input: DeleteCurrencyInput!
    $condition: ModelCurrencyConditionInput
  ) {
    deleteCurrency(input: $input, condition: $condition) {
      code
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createLanguage = /* GraphQL */ `
  mutation CreateLanguage(
    $input: CreateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    createLanguage(input: $input, condition: $condition) {
      code
      name
      nameNative
      countryCode
      currencyCode
      currency {
        code
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateLanguage = /* GraphQL */ `
  mutation UpdateLanguage(
    $input: UpdateLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    updateLanguage(input: $input, condition: $condition) {
      code
      name
      nameNative
      countryCode
      currencyCode
      currency {
        code
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteLanguage = /* GraphQL */ `
  mutation DeleteLanguage(
    $input: DeleteLanguageInput!
    $condition: ModelLanguageConditionInput
  ) {
    deleteLanguage(input: $input, condition: $condition) {
      code
      name
      nameNative
      countryCode
      currencyCode
      currency {
        code
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      owner
      name
      location {
        lat
        lon
        __typename
      }
      addressText
      phonenumber
      email
      logo
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      owner
      name
      location {
        lat
        lon
        __typename
      }
      addressText
      phonenumber
      email
      logo
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      owner
      name
      location {
        lat
        lon
        __typename
      }
      addressText
      phonenumber
      email
      logo
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCompanyPaymentData = /* GraphQL */ `
  mutation CreateCompanyPaymentData(
    $input: CreateCompanyPaymentDataInput!
    $condition: ModelCompanyPaymentDataConditionInput
  ) {
    createCompanyPaymentData(input: $input, condition: $condition) {
      id
      owner
      paymentProviderId
      applicationsLeftToUnlock
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCompanyPaymentData = /* GraphQL */ `
  mutation UpdateCompanyPaymentData(
    $input: UpdateCompanyPaymentDataInput!
    $condition: ModelCompanyPaymentDataConditionInput
  ) {
    updateCompanyPaymentData(input: $input, condition: $condition) {
      id
      owner
      paymentProviderId
      applicationsLeftToUnlock
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCompanyPaymentData = /* GraphQL */ `
  mutation DeleteCompanyPaymentData(
    $input: DeleteCompanyPaymentDataInput!
    $condition: ModelCompanyPaymentDataConditionInput
  ) {
    deleteCompanyPaymentData(input: $input, condition: $condition) {
      id
      owner
      paymentProviderId
      applicationsLeftToUnlock
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createCompanyUser = /* GraphQL */ `
  mutation CreateCompanyUser(
    $input: CreateCompanyUserInput!
    $condition: ModelCompanyUserConditionInput
  ) {
    createCompanyUser(input: $input, condition: $condition) {
      id
      owner
      companyId
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      applicationMailNotification
      language
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateCompanyUser = /* GraphQL */ `
  mutation UpdateCompanyUser(
    $input: UpdateCompanyUserInput!
    $condition: ModelCompanyUserConditionInput
  ) {
    updateCompanyUser(input: $input, condition: $condition) {
      id
      owner
      companyId
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      applicationMailNotification
      language
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteCompanyUser = /* GraphQL */ `
  mutation DeleteCompanyUser(
    $input: DeleteCompanyUserInput!
    $condition: ModelCompanyUserConditionInput
  ) {
    deleteCompanyUser(input: $input, condition: $condition) {
      id
      owner
      companyId
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      applicationMailNotification
      language
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      owner
      identityId
      name
      birthdate
      phonenumber
      location {
        lat
        lon
        __typename
      }
      addressText
      documents
      avatar
      language
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      owner
      identityId
      name
      birthdate
      phonenumber
      location {
        lat
        lon
        __typename
      }
      addressText
      documents
      avatar
      language
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      owner
      identityId
      name
      birthdate
      phonenumber
      location {
        lat
        lon
        __typename
      }
      addressText
      documents
      avatar
      language
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createJobApplication = /* GraphQL */ `
  mutation CreateJobApplication(
    $input: CreateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    createJobApplication(input: $input, condition: $condition) {
      id
      userId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateJobApplication = /* GraphQL */ `
  mutation UpdateJobApplication(
    $input: UpdateJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    updateJobApplication(input: $input, condition: $condition) {
      id
      userId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteJobApplication = /* GraphQL */ `
  mutation DeleteJobApplication(
    $input: DeleteJobApplicationInput!
    $condition: ModelJobApplicationConditionInput
  ) {
    deleteJobApplication(input: $input, condition: $condition) {
      id
      userId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createJobApplicationCompanyData = /* GraphQL */ `
  mutation CreateJobApplicationCompanyData(
    $input: CreateJobApplicationCompanyDataInput!
    $condition: ModelJobApplicationCompanyDataConditionInput
  ) {
    createJobApplicationCompanyData(input: $input, condition: $condition) {
      id
      companyId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateJobApplicationCompanyData = /* GraphQL */ `
  mutation UpdateJobApplicationCompanyData(
    $input: UpdateJobApplicationCompanyDataInput!
    $condition: ModelJobApplicationCompanyDataConditionInput
  ) {
    updateJobApplicationCompanyData(input: $input, condition: $condition) {
      id
      companyId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteJobApplicationCompanyData = /* GraphQL */ `
  mutation DeleteJobApplicationCompanyData(
    $input: DeleteJobApplicationCompanyDataInput!
    $condition: ModelJobApplicationCompanyDataConditionInput
  ) {
    deleteJobApplicationCompanyData(input: $input, condition: $condition) {
      id
      companyId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createJobApplicationCompanyDataNote = /* GraphQL */ `
  mutation CreateJobApplicationCompanyDataNote(
    $input: CreateJobApplicationCompanyDataNoteInput!
    $condition: ModelJobApplicationCompanyDataNoteConditionInput
  ) {
    createJobApplicationCompanyDataNote(input: $input, condition: $condition) {
      id
      owner
      companyId
      jobApplicationCompanyDataId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateJobApplicationCompanyDataNote = /* GraphQL */ `
  mutation UpdateJobApplicationCompanyDataNote(
    $input: UpdateJobApplicationCompanyDataNoteInput!
    $condition: ModelJobApplicationCompanyDataNoteConditionInput
  ) {
    updateJobApplicationCompanyDataNote(input: $input, condition: $condition) {
      id
      owner
      companyId
      jobApplicationCompanyDataId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteJobApplicationCompanyDataNote = /* GraphQL */ `
  mutation DeleteJobApplicationCompanyDataNote(
    $input: DeleteJobApplicationCompanyDataNoteInput!
    $condition: ModelJobApplicationCompanyDataNoteConditionInput
  ) {
    deleteJobApplicationCompanyDataNote(input: $input, condition: $condition) {
      id
      owner
      companyId
      jobApplicationCompanyDataId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createWorkTimeRegulation = /* GraphQL */ `
  mutation CreateWorkTimeRegulation(
    $input: CreateWorkTimeRegulationInput!
    $condition: ModelWorkTimeRegulationConditionInput
  ) {
    createWorkTimeRegulation(input: $input, condition: $condition) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateWorkTimeRegulation = /* GraphQL */ `
  mutation UpdateWorkTimeRegulation(
    $input: UpdateWorkTimeRegulationInput!
    $condition: ModelWorkTimeRegulationConditionInput
  ) {
    updateWorkTimeRegulation(input: $input, condition: $condition) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteWorkTimeRegulation = /* GraphQL */ `
  mutation DeleteWorkTimeRegulation(
    $input: DeleteWorkTimeRegulationInput!
    $condition: ModelWorkTimeRegulationConditionInput
  ) {
    deleteWorkTimeRegulation(input: $input, condition: $condition) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createJobCategory = /* GraphQL */ `
  mutation CreateJobCategory(
    $input: CreateJobCategoryInput!
    $condition: ModelJobCategoryConditionInput
  ) {
    createJobCategory(input: $input, condition: $condition) {
      id
      name
      color
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateJobCategory = /* GraphQL */ `
  mutation UpdateJobCategory(
    $input: UpdateJobCategoryInput!
    $condition: ModelJobCategoryConditionInput
  ) {
    updateJobCategory(input: $input, condition: $condition) {
      id
      name
      color
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteJobCategory = /* GraphQL */ `
  mutation DeleteJobCategory(
    $input: DeleteJobCategoryInput!
    $condition: ModelJobCategoryConditionInput
  ) {
    deleteJobCategory(input: $input, condition: $condition) {
      id
      name
      color
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createPositionLevel = /* GraphQL */ `
  mutation CreatePositionLevel(
    $input: CreatePositionLevelInput!
    $condition: ModelPositionLevelConditionInput
  ) {
    createPositionLevel(input: $input, condition: $condition) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updatePositionLevel = /* GraphQL */ `
  mutation UpdatePositionLevel(
    $input: UpdatePositionLevelInput!
    $condition: ModelPositionLevelConditionInput
  ) {
    updatePositionLevel(input: $input, condition: $condition) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deletePositionLevel = /* GraphQL */ `
  mutation DeletePositionLevel(
    $input: DeletePositionLevelInput!
    $condition: ModelPositionLevelConditionInput
  ) {
    deletePositionLevel(input: $input, condition: $condition) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createJobOffer = /* GraphQL */ `
  mutation CreateJobOffer(
    $input: CreateJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    createJobOffer(input: $input, condition: $condition) {
      id
      owner
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      description
      visibleInSearch
      workTimeRegulationId
      workTimeRegulation {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobCategoryId
      jobCategory {
        id
        name
        color
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      positionLevelId
      positionLevel {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      location {
        lat
        lon
        __typename
      }
      addressText
      salary {
        salary
        currencyCode
        timeUnit
        __typename
      }
      hours {
        hours
        timeUnit
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const updateJobOffer = /* GraphQL */ `
  mutation UpdateJobOffer(
    $input: UpdateJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    updateJobOffer(input: $input, condition: $condition) {
      id
      owner
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      description
      visibleInSearch
      workTimeRegulationId
      workTimeRegulation {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobCategoryId
      jobCategory {
        id
        name
        color
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      positionLevelId
      positionLevel {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      location {
        lat
        lon
        __typename
      }
      addressText
      salary {
        salary
        currencyCode
        timeUnit
        __typename
      }
      hours {
        hours
        timeUnit
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const deleteJobOffer = /* GraphQL */ `
  mutation DeleteJobOffer(
    $input: DeleteJobOfferInput!
    $condition: ModelJobOfferConditionInput
  ) {
    deleteJobOffer(input: $input, condition: $condition) {
      id
      owner
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      description
      visibleInSearch
      workTimeRegulationId
      workTimeRegulation {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobCategoryId
      jobCategory {
        id
        name
        color
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      positionLevelId
      positionLevel {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      location {
        lat
        lon
        __typename
      }
      addressText
      salary {
        salary
        currencyCode
        timeUnit
        __typename
      }
      hours {
        hours
        timeUnit
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const createApplication = /* GraphQL */ `
  mutation CreateApplication($jobOfferId: ID!, $applicationText: String!) {
    createApplication(
      jobOfferId: $jobOfferId
      applicationText: $applicationText
    ) {
      id
      userId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const changeJobApplicationCompanyStatus = /* GraphQL */ `
  mutation ChangeJobApplicationCompanyStatus(
    $jobApplicationCompanyDataId: ID!
    $status: JobApplicationCompanyDataStatus!
  ) {
    changeJobApplicationCompanyStatus(
      jobApplicationCompanyDataId: $jobApplicationCompanyDataId
      status: $status
    ) {
      id
      companyId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
