import { Select } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { useTranslation } from 'react-i18next';

/**
 * input for sort direction
 * @param {object} props component props
 * @returns JSX
 */
export default function SortDirectionInput(props) {

    // globals
    const { t } = useTranslation();

    return (
        <Select
            label={t("sort.order")}
            placeholder="..."
            clearable={false}
            data={[
                { value: SortDirection.ASCENDING, label: t("sort.asc") },
                { value: SortDirection.DESCENDING, label: t("sort.desc") },
            ]}
            {...props}
        />
    )
}