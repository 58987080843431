import { Divider, Stack, Title, Group, Button, TextInput } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../components/Form";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { VALIDATION_SCHEMA_TRANSLATIONS, VALIDATION_SCHEMA_BOOL, VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import * as Yup from 'yup';
import { showNotification } from "@mantine/notifications";
import { Check, File, List, Reload } from "tabler-icons-react";
import { DataStore } from "aws-amplify";
import { WorkTimeRegulation } from "../../models";
import { useEffect } from "react";
import { ROUTE_ADMIN_WORKTIMEREGULATIONS } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import TranslationsInput from "../../components/TranslationsInput";
import CheckboxInput from "../../components/CheckboxInput";

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_STRING,
    active: VALIDATION_SCHEMA_BOOL,
    translations: VALIDATION_SCHEMA_TRANSLATIONS
});

/**
 * work time regulation new/edit page
 * @returns JSX
 */
export default function PageAdminWorktimeRegulation() {

    // globals
    const navigate = useNavigate();
    const { id } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const { t } = useTranslation();

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (id) {
                // get item
                setLoading(LOADING_SHOW);
                const item = await DataStore.query(WorkTimeRegulation, id);
                if (!item) {
                    // not found
                    throw new Error("Work time regulation could not be loaded.");
                }

                // set data
                form.setInitialValues({
                    id: item.id,
                    name: item.name,
                    active: item.active,
                    translations: item.translations.map((e) => ({ ...e })) || []
                });
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // submit callback
    const submitCallback = async (values) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // save item
            if (!id) {
                // create item
                await DataStore.save(new WorkTimeRegulation({
                    name: values.name,
                    active: values.active,
                    translations: values.translations
                }));
            } else {
                // update item
                const original = await DataStore.query(WorkTimeRegulation, id);
                await DataStore.save(WorkTimeRegulation.copyOf(original, updated => {
                    updated.name = values.name;
                    updated.active = values.active;
                    updated.translations = values.translations;
                }));
            }

            // show success
            showNotification({ message: t("general.saved"), color: 'green', icon: <Check /> });

            // navigate to list
            navigate(ROUTE_ADMIN_WORKTIMEREGULATIONS);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            id: "",
            name: "",
            active: false,
            translations: []
        },
        submitCallback: submitCallback
    });

    // if there is an ID provided and we have not fetched the values, just return
    if (id && !form.values.id) {
        return null;
    }

    return (
        <Stack>
            <Title>{form.values.id ? t("worktimeregulation.edit") : t("worktimeregulation.create")}</Title>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label={t("general.name")}
                        placeholder={t("general.name")}
                        {...form.getInputProps('name')}
                    />
                    <CheckboxInput
                        label={t("general.active")}
                        {...form.getInputProps('active', { type: 'checkbox' })}
                    />
                    <TranslationsInput
                        form={form}
                    />
                    <Divider />

                    <Group justify='space-between'>
                        <Group>
                            <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                            <Link to={ROUTE_ADMIN_WORKTIMEREGULATIONS}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
                        </Group>
                        <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.save")}</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}