import { API } from "aws-amplify";
import { Badge, Menu, Text, UnstyledButton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { Check, ChevronDown } from "tabler-icons-react";
import { JOBAPPLICATION_COMPANYDATA_STATUS_COLOR, JOBAPPLICATION_COMPANYDATA_STATUS_TEXTS } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { JobApplicationCompanyDataStatus } from "../models";
import BadgeApplicationStatus from "./BadgeApplicationStatus";
import { useTranslation } from "react-i18next";
import { changeJobApplicationCompanyStatus } from "../graphql/mutations";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { modals } from '@mantine/modals';

/**
 * application status badge with update functionality
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeApplicationStatusWithUpdate(props) {

    // globals
    const setError = useErrorDispatch();
    const { t } = useTranslation();
    const setLoading = useLoadingDispatch();

    // if application is not set, just return
    if (!props.application) {
        return null;
    }

    /**
     * wrapper for triggering a status update on UI
     * @param {string} newStatus new status
     */
    const updateStatusTriggered = async (newStatus) => {
        // show question for user if application should be deleted
        if (newStatus === JobApplicationCompanyDataStatus.DELETED) {
            modals.openConfirmModal({
                title: t("application.delete.title"),
                children: (<Text size="sm">{t("application.delete.text")}</Text>),
                labels: { confirm: t("general.delete"), cancel: t("general.cancel") },
                onConfirm: () => updateStatus(newStatus),
                confirmProps: {
                    color: "red"
                },
            });
        }
        else {
            await updateStatus(newStatus);
        }
    }

    /**
     * sets the job application status
     * @param {*} newStatus new status to set
     */
    const updateStatus = async (newStatus) => {
        try {
            setLoading(LOADING_SHOW);
            await API.graphql({
                query: changeJobApplicationCompanyStatus,
                variables: {
                    jobApplicationCompanyDataId: props.application.id,
                    status: newStatus,
                },
            })

            showNotification({ message: t("application.status.changed"), color: 'green', icon: <Check /> });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // only show badge if the user should not update the status
    if (props.application.status === JobApplicationCompanyDataStatus.NEW || props.application.status === JobApplicationCompanyDataStatus.DELETED) {
        return (
            <BadgeApplicationStatus
                size="xl"
                application={props.application}
                variant="filled"
                textSize={"md"}
            />
        )
    }

    return (
        <Menu shadow="md" position="bottom-end">
            <Menu.Target>
                <UnstyledButton>
                    <BadgeApplicationStatus
                        rightSection={<ChevronDown size={25} />}
                        size="xl"
                        application={props.application}
                        variant="filled"
                        textSize={"md"}
                    />
                </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown style={{ marginLeft: "10px", marginRight: "10px" }}>
                <Menu.Label>{t("application.status.change")}</Menu.Label>
                {JOBAPPLICATION_COMPANYDATA_STATUS_TEXTS
                    .filter(element => {
                        return element.value !== JobApplicationCompanyDataStatus.NEW && element.value !== JobApplicationCompanyDataStatus.UNLOCKED;
                    }).map(element => {
                        return (
                            <Menu.Item
                                key={element.value}
                                style={{ padding: "5px" }}
                                onClick={() => updateStatusTriggered(element.value)}
                            >
                                <Badge
                                    style={{ width: "100%" }}
                                    color={JOBAPPLICATION_COMPANYDATA_STATUS_COLOR[element.value]}
                                    variant="outline"
                                    size="lg"
                                >
                                    {t(element.label)}
                                </Badge>
                            </Menu.Item>
                        )
                    })}
            </Menu.Dropdown>
        </Menu>
    )
}