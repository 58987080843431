import { Button, Divider, Group, Select, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "../../components/Form";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_LANGUAGE_CODE, VALIDATION_SCHEMA_STRING, VALIDATION_SCHEMA_USERGROUP_COMPANY } from "../../helpers/Validation";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { ArrowLeft, Check, File, List, Reload } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { USERGROUP_COMPANY, USERGROUP_COMPANYADMIN } from "../../helpers/Constants";
import { Link, useNavigate, useParams } from "react-router-dom";
import { executeApiCall, ADMIN_API_COMPANY_CREATEUSER, ADMIN_API_COMPANY_GETUSER, API_MODE_GET, API_MODE_POST, ADMIN_API_SUPPORT_CREATECOMPANYUSER, ADMIN_API_SUPPORT_GETUSER, ADMIN_API_NAME } from "../../helpers/APIHelper";
import { useUserState } from "../../helpers/GlobalUserState";
import { ROUTE_COMPANYADMIN_USERS, ROUTE_SUPPORT_COMPANIES_SHOW } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import i18n from "../../helpers/i18n";
import LanguageSelectInput from "../../components/LangaugeSelectInput";

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_STRING,
    email: VALIDATION_SCHEMA_EMAIL,
    group: VALIDATION_SCHEMA_USERGROUP_COMPANY,
    language: VALIDATION_SCHEMA_LANGUAGE_CODE
});

/**
 * company page to create new company user
 * @returns JSX
 */
export default function PageCompanyUserNew() {

    // globals
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const navigate = useNavigate();
    const { companyId } = useParams();
    const user = useUserState();
    const { t } = useTranslation();

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // check if user exists
            var userExists = false;
            if (user.userGroup === USERGROUP_COMPANYADMIN) {
                userExists = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_COMPANY_GETUSER, {
                    "username": values.email.toLowerCase(),
                })
            }
            else {
                userExists = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_SUPPORT_GETUSER, {
                    "username": values.email.toLowerCase(),
                })
            }
            if (userExists) {
                setError({ action: ERROR_SHOW, error: new Error(t("auth.account.exists")) });
                return;
            }

            // create new user
            if (user.userGroup === USERGROUP_COMPANYADMIN) {
                await executeApiCall(ADMIN_API_NAME, API_MODE_POST, ADMIN_API_COMPANY_CREATEUSER, {
                    "name": values.name,
                    "email": values.email.toLowerCase(),
                    "admin": values.group === USERGROUP_COMPANYADMIN,
                    "language": values.language,
                })
            }
            else {
                await executeApiCall(ADMIN_API_NAME, API_MODE_POST, ADMIN_API_SUPPORT_CREATECOMPANYUSER, {
                    "name": values.name,
                    "email": values.email.toLowerCase(),
                    "admin": values.group === USERGROUP_COMPANYADMIN,
                    "companyId": companyId,
                    "language": values.language,
                })
            }

            // show message
            showNotification({ message: t("user.invited"), color: 'green', icon: <Check /> });

            // redirect
            if (user.userGroup === USERGROUP_COMPANYADMIN) {
                navigate(ROUTE_COMPANYADMIN_USERS);
            }
            else {
                navigate(`${ROUTE_SUPPORT_COMPANIES_SHOW}/${companyId}`);
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            email: "",
            language: i18n.language,
            group: USERGROUP_COMPANY
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("auth.signup.invite")}</Title>
                <Group>
                    {user.userGroup === USERGROUP_COMPANYADMIN ?
                        <Link to={ROUTE_COMPANYADMIN_USERS}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
                        :
                        <Link to={`${ROUTE_SUPPORT_COMPANIES_SHOW}/${companyId}`}><Button leftSection={<ArrowLeft size={14} />} color="yellow">{t("general.back")}</Button></Link>
                    }
                </Group>
            </Group>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label={t("user.name.name")}
                        placeholder={t("user.name.name")}
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        withAsterisk
                        label={t("general.email")}
                        placeholder="name@company.com"
                        {...form.getInputProps('email')}
                    />
                    <LanguageSelectInput
                        label={t("language.language")}
                        placeholder={t("language.language")}
                        description={t("language.select_description")}
                        {...form.getInputProps('workTimeRegulationIds')}
                        {...form.getInputProps('language')}
                    />
                    <Select
                        withAsterisk
                        label={t("user.permissions.permissions")}
                        placeholder={t("user.permissions.permissions")}
                        data={[
                            { value: USERGROUP_COMPANY, label: t("user.group.user") },
                            { value: USERGROUP_COMPANYADMIN, label: t("user.group.admin") },
                        ]}
                        {...form.getInputProps('group')}
                    />
                    <Divider />

                    <Group justify='space-between'>
                        <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                        <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.create")}</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}