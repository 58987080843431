import { CheckIcon, Combobox, Group, Input, InputBase, useCombobox } from "@mantine/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { OPTIONS_YES_NO_WITHALL_BOOLEAN } from "../helpers/Constants";

/**
 * custom boolean select
 * @param {object} error error object for the inputs validation
 * @param {string?} label label to show
 * @param {cb} onChange cb for change of selection
 * @param {string?} placeholder placeholder text to show
 * @param {string || null} value current value
 * @param {boolean?} withAsterisk flag if asterisk should be shown
 * @returns JSX
 */
export default function BooleanSelect({ error, label, onChange, placeholder, value, withAsterisk }) {

    // globals
    const { t } = useTranslation();
    const data = OPTIONS_YES_NO_WITHALL_BOOLEAN.map(e => {
        return { value: e.value, label: t(e.label) }
    });
    const [comboboxValue, setComboboxValue] = useState(value);
    const combobox = useCombobox({
        onDropdownClose: () => combobox.resetSelectedOption(),
        onDropdownOpen: (eventSource) => {
            if (eventSource === 'keyboard') {
                combobox.selectActiveOption();
            } else {
                combobox.updateSelectedOptionIndex('active');
            }
        },
    });    
    const selectedOption = data.find((e) => e.value === value);

    return (
        <Combobox
            store={combobox}
            resetSelectionOnOptionHover
            withinPortal={false}
            onOptionSubmit={(val) => {
                onChange(val);
                setComboboxValue(val);
                combobox.updateSelectedOptionIndex('active');
            }}
        >
            <Combobox.Target targetType="button">
                <InputBase
                    component="button"
                    type="button"
                    pointer
                    rightSection={<Combobox.Chevron />}
                    error={error}
                    label={label}
                    value={comboboxValue}
                    withAsterisk={withAsterisk}
                    onClick={() => combobox.openDropdown()}
                    onFocus={() => combobox.openDropdown()}
                    onBlur={() => combobox.closeDropdown()}
                >
                    {selectedOption?.label || <Input.Placeholder>{placeholder || "..."}</Input.Placeholder>}
                </InputBase>
            </Combobox.Target>

            <Combobox.Dropdown>
                <Combobox.Options>
                    {data && data.map((e) => (
                        <Combobox.Option value={e.value} key={e.value} active={e.value === comboboxValue}>
                            <Group gap="xs">
                                {e.value === comboboxValue && <CheckIcon size={12} />}
                                <span>{e.label}</span>
                            </Group>
                        </Combobox.Option>
                    ))}
                </Combobox.Options>
            </Combobox.Dropdown>
        </Combobox>
    );
}