import { Accordion } from "@mantine/core";
import { Briefcase, BuildingSkyscraper } from "tabler-icons-react";

// html parser for descriptions
const parseHtml = require('html-react-parser');

/**
 * component to render job details
 * @param {object} props component props
 * @returns JSX
 */
export default function JobDetails(props) {

    // globals
    const { job, defaultValue } = props;

    return (
        <Accordion multiple={true} variant="separated" defaultValue={defaultValue ? defaultValue : ["job", "company"]}>
            {job.company.description &&
                <Accordion.Item value="company">
                    <Accordion.Control icon={<BuildingSkyscraper size={20} />}>{job.company.name}</Accordion.Control>
                    <Accordion.Panel>{parseHtml(job.company.description)}</Accordion.Panel>
                </Accordion.Item>
            }

            <Accordion.Item value="job">
                <Accordion.Control icon={<Briefcase size={20} />}>{job.name}</Accordion.Control>
                <Accordion.Panel>{parseHtml(job.description)}</Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    )
}