import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container, Group } from "@mantine/core";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth } from 'aws-amplify';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import { useForm } from "../../components/Form";
import { ROUTE_SIGNIN } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    code: VALIDATION_SCHEMA_STRING,
});

/**
 * component to handle sign up confirmation
 * @returns JSX
 */
export default function PageGeneralSignupConfirm() {

    // globals
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const { t } = useTranslation();
    const location = useLocation();

    // submit callback
    const submitCallback = async (values) => {
        try {
            await Auth.confirmSignUp(values.email.toLowerCase(), values.code);
            showNotification({ message: t("auth.confirmation.confirmed"), color: 'green', icon: <Check /> });
            navigate(ROUTE_SIGNIN, { state: { email: values.email }});
        }
        catch (e) {
            if (e.code === "CodeMismatchException") {
                showNotification({ message: t("auth.confirmation.code.invalid"), color: 'red', icon: <X /> });
            }
            else if (e.code === "ExpiredCodeException") {
                showNotification({ message: t("auth.confirmation.code.expired"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    /**
     * sends a code
     * @param {string} email user email
     */
    const sendNewCode = async (email) => {
        try {
            setLoading(LOADING_SHOW);
            await Auth.resendSignUp(email);
            showNotification({ message: t("auth.confirmation.code.sent"), color: 'green', icon: <Check /> });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: location.state?.email ? location.state.email : "",
            code: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center" mb="md">{t("auth.confirmation.confirm")}</Title>
                        <Stack>
                            <Text size="sm" c="dimmed" align="center">
                                {t("auth.confirmation.code.sent")}
                            </Text>
                            <TextInput
                                withAsterisk
                                label={t("general.email")}
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                                disabled={true}
                            />
                            <PasswordInput
                                withAsterisk
                                label={t("auth.confirmation.code.code")}
                                placeholder={t("auth.confirmation.code.code")}
                                {...form.getInputProps('code')}
                            />
                        </Stack>
                        <Group justify="flex-end" mt={5}>
                            <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" onClick={() => sendNewCode(form.values.email)}>
                                {t("auth.confirmation.code.request")}
                            </Text>
                        </Group>
                        <Button type="submit" fullWidth mt="xl">{t("auth.confirmation.confirm")}</Button>
                    </Paper>
                </form>
            </Container>
        </Center>
    );
}