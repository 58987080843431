import { Location } from "tabler-icons-react";
import BadgeAutoHeight from "./BadgeAutoHeight";

/**
 * location badge
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeLocation(props) {
    return (
        <BadgeAutoHeight
            color="blue"
            variant="outline"
            leftSection={<Location size={14} />}
            size="xs"
        >
            {props.addressText}
        </BadgeAutoHeight>
    )
}