import { Input, Text, ColorPicker as MantineColorPicker, useMantineTheme } from '@mantine/core';
import InputBackground from './InputBackground';
import { useTranslation } from 'react-i18next';

/**
 * custom ColorPicker with form wrapper
 * @param {object} props component properties
 * @returns 
 */
export default function ColorPicker(props) {
    
    // globals
    const theme = useMantineTheme();
    const { t } = useTranslation();

    var colors = [];
    Object.keys(theme.colors).forEach(key => {
        colors = colors.concat(theme.colors[key]);
    });

    return (
        <Input.Wrapper error={props.error}>
            <Input.Label>{props.label ? props.label : "Farbe"}</Input.Label>
            <InputBackground error={props.error}>
                <Text size="sm" mb="sm">{`${t("general.color")}: ${props.value ? props.value : "..."}`}</Text>

                <MantineColorPicker
                    format="hexa"
                    size="xl"
                    swatches={colors}
                    fullWidth
                    swatchesPerRow={20}
                    {...props}
                    
                />
            </InputBackground>
        </Input.Wrapper>
    )
}