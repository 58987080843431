import React from 'react'
import { Link } from 'react-router-dom';
import NotFoundImage from '../../images/404.png';
import { Button, Center, Container, Image, SimpleGrid, Text, Title } from "@mantine/core";
import { Home } from 'tabler-icons-react';
import { ROUTE_HOME } from '../../helpers/Routes';
import { useTranslation } from 'react-i18next';

/**
 * 404 page
 * @returns JSX
 */
export default function PageGeneralNotFound() {

    // globals
    const { t } = useTranslation();

    return (
        <Center h="100dvh">
            <Container>
                <SimpleGrid cols={{ base: 1, md: 2 }} >
                    <Image src={NotFoundImage} className="hidden-desktop" />
                    <div>
                        <Title mb="xl">{t("error.404.title")}</Title>
                        <Text c="dimmed" size="lg">
                            {t("error.404.message")}
                        </Text>
                        <Link to={ROUTE_HOME}><Button variant="outline" size="md" mt="xl" leftSection={<Home size={14} />}>{t("error.home")}</Button></Link>
                    </div>
                    <Image src={NotFoundImage} className="hidden-mobile" />
                </SimpleGrid>
            </Container>
        </Center>
    );
}