import { Center, Paper, SimpleGrid, Text } from "@mantine/core";
import { IconArrowDownRight, IconArrowRight, IconArrowUpRight } from "@tabler/icons-react";
import { formatNumber } from "../helpers/i18n";
import { useTranslation } from "react-i18next";

/**
 * wrapper to render application statistics
 * @param {object} props component props
 * @returns JSX
 */
export default function Statistics(props) {

    // globals
    const { t } = useTranslation();

    // if no stats were provided, dont render
    if (!props.stats) {
        return null;
    }

    const stats = props.stats.map((stat) => {
        // get icon and text color
        var textColor = "yellow";
        var DiffIcon = IconArrowRight;
        if (stat.diff > 0) {
            textColor = "teal";
            DiffIcon = IconArrowUpRight;
        }
        else if (stat.diff < 0) {
            textColor = "red";
            DiffIcon = IconArrowDownRight;
        }
        else if (stat.diff === undefined) {
            textColor = "blue";
        }

        return (
            <Paper
                key={`${stat.unit}_${stat.title}`}
                p="md"
                radius="md"
                shadow="md"
                withBorder
            >
                <Center>
                    <Text size="xs" mb="xs" c="dimmed">
                        {stat.unit !== 0 && <span>{stat.unit} </span>}
                        <span>{t(stat.title)}</span>
                    </Text>
                </Center>
                <Center>
                    <Text
                        c={textColor}
                        style={{
                            fontSize: "3rem",
                            fontWeight: 700,
                            lineHeight: 1,
                        }}
                    >
                        {stat.count}
                    </Text>
                </Center>
                <Center>
                    <Text
                        c={textColor}
                        fz="sm"
                        fw={500}
                        style={{
                            alignSelf: "flex-end",
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        {stat.diff !== undefined &&
                            <>
                                <DiffIcon size="1rem" stroke={1.5} />
                                <span>{formatNumber(stat.diff * 100, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %</span>
                            </>
                        }
                    </Text>
                </Center>
            </Paper>
        );
    });

    return (
        <SimpleGrid cols={{ base: 1, md: 3, lg: 6 }}>
            {stats}
        </SimpleGrid>
    );
}