import { Alert } from "@mantine/core";
import { Check } from "tabler-icons-react";
import { formatDate } from "../helpers/Moment";
import { useTranslation } from 'react-i18next';

/**
 * alert to show when user already applied to a certain job application
 * @param {object} props component props
 * @returns 
 */
export default function AlreadyAppliedAlert(props) {

    // globals
    const { t } = useTranslation();

    return (
        <Alert
            variant="outline"
            icon={<Check size={16} />}
            color="green"
            title={t("job.already_applied")}
        >
            {t("job.already_applied_time", { time: formatDate(props.application.createdAt, "L LT") })}
        </Alert>
    );
}