import React from 'react';
import ReactDOM from 'react-dom/client';
import AppWrapper from './components/AppWrapper';
import { Auth, AuthModeStrategyType } from 'aws-amplify';
import { Amplify } from "@aws-amplify/core";
import awsConfig from './aws-exports';
import * as Sentry from "@sentry/react";
import './helpers/i18n';

import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/dropzone/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/tiptap/styles.css';
import '@aws-amplify/ui-react/styles.css';
import './index.css';

// get redirect urls
const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
    window.location.hostname === "[::1]" ||
    window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);
const [
    localRedirectSignIn,
    productionRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(",");
  
  const [
    localRedirectSignOut,
    productionRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(",");

// configure amplify
Amplify.configure({
    ...awsConfig,
    oauth: {
        ...awsConfig.oauth,
        domain: "auth.unitedtalents24.com",
        redirectSignIn: isLocalhost ? localRedirectSignIn : productionRedirectSignIn,
        redirectSignOut: isLocalhost ? localRedirectSignOut : productionRedirectSignOut,
    },
    DataStore: {
        authModeStrategyType: AuthModeStrategyType.MULTI_AUTH,
    },
    API: {
        graphql_headers: async () => {
            try {
                const session = await Auth.currentSession();
                return { Authorization: session.getIdToken().getJwtToken() };
            } catch (e) {
                if (e !== "No current user") {
                    Sentry.captureException(e);
                } 
                
                return {};
            }
        },
    }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppWrapper />
);
