import { Card, Image, Text, Button, Center, SimpleGrid, useMantineTheme, Stack } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";
import { Login } from "tabler-icons-react";
import { ROUTE_COMPANY_SIGNUP, ROUTE_SIGNIN, ROUTE_USER_SIGNUP } from "../../helpers/Routes";
import image_looking_for_job from "../../images/image_looking_for_job.jpg";
import image_work_together from "../../images/image_work_together.jpg";
import { useTranslation } from 'react-i18next';

/**
 * sign up wrapper to handle redirect either for user or company sign up
 * @returns JSX
 */
export default function PageGeneralSignup() {

    // globals
    const theme = useMantineTheme();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`, true);
    const { t } = useTranslation();

    function renderCard(image, title, text, buttonText, linkTo) {
        return (
            <Card
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
                shadow="md"
                radius="md"
                withBorder
            >
                <Card.Section>
                    <Image
                        src={image}
                        height={mediaQueryLargerSm ? 300 : 160}
                    />
                </Card.Section>
                <Text mt="md" mb="xs" weight={500}>{title}</Text>
                <Text size="sm" c="dimmed" mb="md">{text}</Text>
                <Link style={{ marginTop: "auto" }} to={linkTo}><Button variant="light" color="blue" fullWidth radius="md">{buttonText}</Button></Link>
            </Card>
        );
    };

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Stack>
                <Link to={ROUTE_SIGNIN}><Button variant="outline" color="green" size="xs" leftSection={<Login size={14} />}>{t("auth.signin.question")}</Button></Link>
                <SimpleGrid cols={{ base: 1, md: 2, lg: 2 }} gap="xl">
                    {renderCard(
                        image_looking_for_job,
                        t("auth.signup.user.title"),
                        t("auth.signup.user.text"),
                        t("auth.signup.user.link"),
                        ROUTE_USER_SIGNUP
                    )}
                    {renderCard(
                        image_work_together,
                        t("auth.signup.company.title"),
                        t("auth.signup.company.text"),
                        t("auth.signup.company.link"),
                        ROUTE_COMPANY_SIGNUP
                    )}
                </SimpleGrid>
            </Stack>
        </Center>
    );
}