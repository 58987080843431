import { Accordion, Button, Group, Stack, TextInput } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { useParams } from "react-router-dom";
import { Filter, Reload } from "tabler-icons-react";
import { PREDICATE_CONTAINS, PREDICATE_EQUALS } from "../helpers/Constants";
import { useUserState } from "../helpers/GlobalUserState";
import { getFilterPredicates } from "../helpers/Predicates";
import { JobOffer } from "../models";
import { useForm } from "./Form";
import List, { COLUMN_TYPE_JOBCATEGORY, COLUMN_TYPE_POSITIONLEVEL, COLUMN_TYPE_TIMESTAMP, COLUMN_TYPE_WORKTIMEREGULATION } from "./List";
import SortDirectionInput from "./SortDirectionInput";
import SortFieldInput from "./SortFieldInput";
import { ROUTE_COMPANY_JOBS_EDIT, ROUTE_JOBS_SHOW, ROUTE_SUPPORT_JOBS_EDIT } from "../helpers/Routes";
import { useTranslation } from 'react-i18next';

/**
 * list of jobs of a company
 * @returns JSX
 */
export default function CompanyJobsList() {

    // globals
    const user = useUserState();
    const { companyId } = useParams();
    const { t } = useTranslation();

    // form hook
    const form = useForm({
        initialValues: {
            name: "",
            addressText: "",
            sortField: "name",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        if (!user.companyId && !companyId) {
            throw new Error("no company id provided for jobs list");
        }

        return getFilterPredicates([
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
            { key: "addressText", value: form.values.addressText, type: PREDICATE_CONTAINS },
            { key: "owner", value: companyId ? companyId : user.companyId, type: PREDICATE_EQUALS },
        ]);
    }

    return (
        <Stack>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("general.name")}
                                    placeholder={t("general.name")}
                                    {...form.getInputProps('name')}
                                />

                                <TextInput
                                    label={t("general.address")}
                                    placeholder={t("general.address")}
                                    {...form.getInputProps('addressText')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'name', label: t('general.name') },
                                        { value: 'addressText', label: t('general.address') },
                                        { value: 'createdAt', label: t('date.create_time') },
                                        { value: 'updatedAt', label: t('date.change_time') },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group justify='space-between'>
                                    <Button variant="outline" leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={JobOffer}
                id="id"
                dataStructure={[
                    { key: "name", title: t("general.name") },
                    { key: "addressText", title: t("general.address") },
                    { key: "jobCategoryId", title: t("jobcategory.jobcategory"), type: COLUMN_TYPE_JOBCATEGORY },
                    { key: "workTimeRegulationId", title: t("worktimeregulation.worktimeregulation"), type: COLUMN_TYPE_WORKTIMEREGULATION },
                    { key: "positionLevelId", title: t("positionlevel.positionlevel"), type: COLUMN_TYPE_POSITIONLEVEL },
                    { key: "createdAt", title: t("date.create_time"), type: COLUMN_TYPE_TIMESTAMP },
                    { key: "updatedAt", title: t("date.change_time"), type: COLUMN_TYPE_TIMESTAMP },
                ]}
                showRoute={ROUTE_JOBS_SHOW}
                editRoute={user.companyId ? ROUTE_COMPANY_JOBS_EDIT : ROUTE_SUPPORT_JOBS_EDIT}
                filter={getFilter()}
                sortField={form.values.sortField}
                sortDirection={form.values.sortDirection}
            />
        </Stack>
    );
}