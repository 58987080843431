import { Button, Card, Flex, Stack, Text, ThemeIcon, Title } from "@mantine/core";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";
import Carousel from "./Carousel";
import { List, QuestionMark } from "tabler-icons-react";
import { useTranslation } from "react-i18next";
import BadgeTimestamp from "./BadgeTimestamp";

// consts for type
export const APPLICATION_CAROUSEL_TYPE_USER = "user";
export const APPLICATION_CAROUSEL_TYPE_COMPANY = "company";

/**
 * custom component to render applications in carousel
 * @param {array} applications array of applications to render
 * @param {string} listRoute route to show applications
 * @param {array} detailRoute route for one application
 * @returns 
 */
export default function ApplicationsCarousel({ applications, listRoute, detailRoute, type }) {

    // globals
    const { t } = useTranslation();

    /**
     * wrapper to render application carousel items
     * @param {array} items array of items to render
     * @returns JSX
     */
    const renderedApplications = () => {

        // render applications
        const renderedApplications = applications.map(e => {
            return (
                <Link
                    to={`${detailRoute}/${e.id}`}
                    style={{
                        textDecoration: "none",
                        flexGrow: 1
                    }}
                    key={e.id}
                >
                    <Card withBorder padding="md" radius="md">
                        <Stack
                            gap={3}
                            mb="md"
                        >
                            {type === APPLICATION_CAROUSEL_TYPE_USER && <Title order={5} pb="0px">{e.jobOffer.name}</Title>}
                            {type === APPLICATION_CAROUSEL_TYPE_COMPANY &&
                                (e.name ?
                                    <Title order={5} pb="0px">{e.name}</Title>
                                    :
                                    <ThemeIcon variant="outline" size={24}><QuestionMark /></ThemeIcon>
                                )
                            }

                            {type === APPLICATION_CAROUSEL_TYPE_USER && <Text size="sm" c="dimmed">{e.company.name}</Text>}
                            {type === APPLICATION_CAROUSEL_TYPE_COMPANY && <Text size="sm" c="dimmed">{e.jobOffer.name}</Text>}
                        </Stack>
                        <BadgeTimestamp label={`${t("application.applied_time")}:`} time={e.createdAt} />
                    </Card>
                </Link>
            );
        })

        // add last item with link to search
        renderedApplications.push(
            <Link to={listRoute} style={{ textDecoration: "none", flexGrow: 1 }} key="applications_link">
                <Card withBorder padding="md" radius="md" style={{ height: "100%", display: "flex" }}>
                    <Flex gap="xs" direction="column" align="baseline" style={{ flexGrow: 1, display: "grid" }}>
                        <Flex gap="md" style={{ display: "flex", alignItems: "center" }}>
                            <Avatar size={50} placeholder={List} color="green" />
                            <Text size="sm" c="dimmed">{t("application.all")}</Text>
                        </Flex>
                        <Button
                            size="compact-sm"
                            variant="outline"
                            radius="xl"
                            leftSection={<List size={14} />}
                            style={{ alignSelf: "flex-end" }}
                        >
                            {t("application.applications")}
                        </Button>
                    </Flex>

                </Card>
            </Link>
        );

        // return items
        return renderedApplications;
    }

    return (
        <Carousel>
            {renderedApplications()}
        </Carousel>
    )
}