import { ActionIcon, Flex, Group, Stack, Text, Title } from "@mantine/core";
import { BuildingSkyscraper, ExternalLink } from "tabler-icons-react";
import Avatar from "./Avatar";
import BadgeHours from "./BadgeHours";
import BadgeJobCategory from "./BadgeJobCategory";
import BadgeLocation from "./BadgeLocation";
import BadgeSalary from "./BadgeSalary";
import BadgeTimestamp from "./BadgeTimestamp";
import BadgeWorkTimeRegulation from "./BadgeWorkTimeRegulation";
import { useTranslation } from 'react-i18next';
import BadgePositionLevel from "./BadgePositionLevel";
import { ROUTE_COMPANY_SHOW } from "../helpers/Routes";
import { Link } from "react-router-dom";

/**
 * wrapper for job card
 * @param {object} props component props
 * @returns JSX
 */
export default function Job(props) {

    // globals
    const { job, hideCompanyName, hideCompanyAvatar, hideLocationBadge } = props;
    const { t } = useTranslation();

    return (
        <Stack gap="md">
            <Flex gap="md">
                {!hideCompanyAvatar &&
                    <Avatar
                        size={100}
                        identityId={job.company.id}
                        fileKey={job.company.logo}
                        fileProtectionLevel={"protected"}
                        placeholder={BuildingSkyscraper}
                    />
                }
                <Stack
                    gap={0}
                    style={{
                        alignSelf: "center"
                    }}
                >
                    <Title order={5} pb={0}>{job.name}</Title>
                    {!hideCompanyName &&
                        <Link
                            style={{ textDecoration: "none" }}
                            key={job.id}
                            to={`${ROUTE_COMPANY_SHOW}/${job.company.id}`}
                        >
                            <Group gap={5}>
                            <Text size="sm" c="dimmed">{job.company.name}</Text>
                            <ActionIcon
                                variant="outline"
                                color="blue"
                                size={14}
                            >
                                <ExternalLink />
                            </ActionIcon>
                            </Group>
                        </Link>
                    }

                </Stack>
            </Flex>
            <Group gap="xs">
                {!hideLocationBadge && <BadgeLocation addressText={job.addressText} />}
                <BadgeSalary salary={job.salary} />
                <BadgeHours hours={job.hours} />
                <BadgeJobCategory jobCategory={job.jobCategory} />
                <BadgeWorkTimeRegulation workTimeRegulation={job.workTimeRegulation} />
                <BadgePositionLevel positionLevel={job.positionLevel} />
                <BadgeTimestamp label={`${t("date.create_time")}:`} time={job.createdAt} />
            </Group>
        </Stack>
    );
}
