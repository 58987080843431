import { Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import { useForm } from "../../components/Form";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, LOADING_SHOW_AND_HIDE_CHILDREN, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { useUserState } from "../../helpers/GlobalUserState";
import { User } from "../../models";
import { VALIDATION_SCHEMA_STRING, VALIDATION_SCHEMA_DATE, VALIDATION_SCHEMA_LOCATION, VALIDATION_SCHEMA_ADDRESSTEXT, VALIDATION_SCHEMA_PHONENUMBER } from "../../helpers/Validation";
import * as Yup from 'yup';
import { showNotification } from "@mantine/notifications";
import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
import LocationSearchInput from "../../components/LocationSearchInput";
import FileInput from "../../components/FileInput";
import { FILE_TYPE_IMAGE, FILE_TYPE_PDF, USERGROUP_ADMIN, USERGROUP_SUPPORT, USERGROUP_USER } from "../../helpers/Constants";
import 'dayjs/locale/de';
import DatePickerInput from "../../components/DatePickerInput";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ROUTE_USER_PROFILE } from "../../helpers/Routes";

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_STRING,
    birthdate: VALIDATION_SCHEMA_DATE,
    location: VALIDATION_SCHEMA_LOCATION,
    addressText: VALIDATION_SCHEMA_ADDRESSTEXT,
    phonenumber: VALIDATION_SCHEMA_PHONENUMBER,
});

/**
 * user profile edit page
 * @returns JSX
 */
export default function PageUserProfileEdit() {

    // globals
    const user = useUserState();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [fetched, setFetched] = useState(false);
    const { userId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        setLoading(LOADING_SHOW_AND_HIDE_CHILDREN);

        try {
            // get user data
            const data = await DataStore.query(User, userId ? userId : user.id);
            if (data) {
                // update form
                form.setInitialValues({
                    id: data.id,
                    identityId: data.identityId,
                    name: data.name,
                    birthdate: data.birthdate,
                    phonenumber: data.phonenumber,
                    location: {
                        lat: data.location.lat,
                        lon: data.location.lon,
                    },
                    addressText: data.addressText,
                    documents: data.documents,
                    avatar: data.avatar,
                });
            }

            // set fetched state
            setFetched(true);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user, userId]
    );

    // submit callback
    const submitCallback = async (values) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // save item
            if (!values.id) {
                // create item
                await DataStore.save(new User({
                    id: user.id,
                    identityId: user.identityId,
                    name: values.name,
                    birthdate: values.birthdate,
                    phonenumber: values.phonenumber,
                    location: values.location,
                    addressText: values.addressText,
                    documents: values.documents,
                    avatar: values.avatar,
                }));
            } else {
                // update item
                const original = await DataStore.query(User, values.id);
                await DataStore.save(User.copyOf(original, updated => {
                    updated.name = values.name;
                    updated.birthdate = values.birthdate;
                    updated.phonenumber = values.phonenumber;
                    updated.location = values.location;
                    updated.addressText = values.addressText;
                    updated.documents = values.documents;
                    updated.avatar = values.avatar;
                }));
            }

            // show success
            showNotification({ message: t("general.saved"), color: 'green', icon: <Check /> });

            if (user.userGroup === USERGROUP_USER) {
                navigate(ROUTE_USER_PROFILE);
            }
            else {
                navigate(-1);
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            name: "",
            birthdate: "",
            phonenumber: "",
            location: {
                lat: null,
                lon: null
            },
            addressText: "",
            documents: [],
            avatar: null,
        },
        submitCallback: submitCallback
    });

    if (!fetched) {
        return null;
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{userId ? form.values.name : t("user.profile.profile")}</Title>
                {(user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) &&
                    <Link to={-1}><Button leftSection={<ArrowLeft size={14} />} color="yellow">{t("general.back")}</Button></Link>
                }
            </Group>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label={t("user.name.your")}
                        placeholder={t("user.name.your")}
                        {...form.getInputProps('name')}
                    />
                    <FileInput
                        label={t("user.picture.picture")}
                        fileProtectionLevel="private"
                        fileType={FILE_TYPE_IMAGE}
                        initFiles={form.values.avatar}
                        description={t("user.picture.description")}
                        identityId={form.values.identityId}
                        form={form}
                        path="avatar"
                    />
                    <DatePickerInput
                        withAsterisk
                        label={t("date.birth")}
                        placeholder={t("date.birth")}
                        form={form}
                        inputKey="birthdate"
                    />
                    <TextInput
                        withAsterisk
                        label={t("user.phone")}
                        placeholder={t("user.phone")}
                        {...form.getInputProps('phonenumber')}
                    />
                    <LocationSearchInput
                        label={t("general.address")}
                        form={form}
                        withAsterisk={true}
                    />
                    <FileInput
                        label={t("user.documents.documents")}
                        fileProtectionLevel="private"
                        fileType={FILE_TYPE_PDF}
                        initFiles={form.values.documents}
                        description={t("user.documents.description")}
                        identityId={form.values.identityId}
                        form={form}
                        path="documents"
                        multiple={true}
                    />
                    <Divider />

                    <Group justify='space-between'>
                        <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                        <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.save")}</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}
