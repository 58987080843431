import { Flex, Group, Stack, Text, Title } from "@mantine/core";
import { Cake, Home, Mail, Phone, UserCircle } from "tabler-icons-react";
import Avatar from "./Avatar";
import { formatDate } from "../helpers/Moment";

/**
 * wrapper to render user details
 * @param {object} props component props
 * @returns JSX
 */
export default function UserDetails(props) {

    // globals
    const { userData, size, avatarUrl } = props;

    /**
     * render avatar based on props
     * @returns JSX
     */
    const renderAvatar = () => {
        if (avatarUrl) {
            return (
                <Avatar
                    size={size}
                    placeholder={UserCircle}
                    url={avatarUrl}
                />
            )
        }
        else if (userData.avatar && userData.identityId) {
            return (
                <Avatar
                    size={size}
                    fileKey={userData.avatar}
                    fileProtectionLevel={"private"}
                    identityId={userData.identityId}
                    placeholder={UserCircle}
                />
            )
        }
        else {
            return (
                <Avatar
                    size={size}
                    placeholder={UserCircle}
                />
            )
        }
    }

    return (
        <Flex gap="md" style={{ alignItems: "center" }}>
            {renderAvatar()}
            <Stack gap={1}>
                <Title order={3}>{userData.name}</Title>
                {userData.addressText &&
                    <Group>
                        <Home size={18} />
                        <Text>{userData.addressText}</Text>
                    </Group>
                }
                <Group>
                    <Cake size={18} />
                    <Text>{formatDate(userData.birthdate, "L")}</Text>
                </Group>
                <Group>
                    <Mail size={18} />
                    <Text>{userData.email}</Text>
                </Group>
                <Group>
                    <Phone size={18} />
                    <Text>{userData.phonenumber}</Text>
                </Group>
            </Stack>
        </Flex>
    );
}