import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth, DataStore } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD, VALIDATION_SCHEMA_PASSWORD_CONFIRMATION, VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import { BC_TYPE_USERCHANGE } from "../../helpers/Constants";
import { useForm } from "../../components/Form";
import Broadcaster from "../../components/Broadcaster";
import { ROUTE_HOME, ROUTE_SIGNIN } from "../../helpers/Routes";
import { useTranslation } from "react-i18next";
import { clearDataStore } from "../../helpers/Datastore";
import { fetchUserDetails } from "../../components/AppShellWrapper";
import { USER_SET, useUserDispatch } from "../../helpers/GlobalUserState";

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    oldPassword: VALIDATION_SCHEMA_STRING,
    password: VALIDATION_SCHEMA_PASSWORD,
    passwordConfirm: VALIDATION_SCHEMA_PASSWORD_CONFIRMATION,
});

/**
 * component to handle password change
 * @returns JSX
 */
export default function PageGeneralChangePassword(props) {

    // globals
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const setUser = useUserDispatch();

    // submit callback
    const submitCallback = async (values) => {
        try {
            const loginResult = await Auth.signIn(values.email.toLowerCase(), values.oldPassword);

            if (loginResult.challengeName === "NEW_PASSWORD_REQUIRED") {
                // change the password
                await Auth.completeNewPassword(loginResult, values.password);
                showNotification({ message: t("auth.password.change.changed"), color: 'green', icon: <Check /> });

                // reinit datastore after user change
                await clearDataStore();
                Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: "new" });
                await DataStore.start();
                const user = await fetchUserDetails();
                setUser({
                    action: USER_SET,
                    values: user
                });

                // navigate to home
                navigate(ROUTE_HOME, { state: { email: values.email } });
            }
            else {
                showNotification({ message: t("auth.password.change.notpending"), color: 'red', icon: <X /> });
                navigate(ROUTE_SIGNIN, { state: { email: values.email } });
            }
        }
        catch (e) {
            throw e;
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: location.state?.email ? location.state.email : "",
            oldPassword: "",
            password: "",
            passwordConfirm: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center">{t("auth.password.change.change")}</Title>
                        <Link to={ROUTE_SIGNIN}>
                            <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" mb="md">
                                {t("auth.signin.navigate")}
                            </Text>
                        </Link>

                        <Stack>
                            <TextInput
                                withAsterisk
                                label={t("general.email")}
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                                disabled={location.state?.email}
                            />
                            <PasswordInput
                                withAsterisk
                                label={t("auth.password.current")}
                                placeholder={t("auth.password.current")}
                                {...form.getInputProps('oldPassword')}
                            />
                            <PasswordInput
                                withAsterisk
                                label={t("auth.password.new")}
                                placeholder={t("auth.password.new")}
                                {...form.getInputProps('password')}
                            />
                            <PasswordInput
                                withAsterisk
                                label={t("auth.password.new_confirm")}
                                placeholder={t("auth.password.new_confirm")}
                                {...form.getInputProps('passwordConfirm')}
                            />
                        </Stack>
                        <Button type="submit" fullWidth mt="xl">{t("auth.password.change.change")}</Button>
                    </Paper>
                </form>
            </Container>
        </Center>
    );
}