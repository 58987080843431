import React, { useEffect, useState } from 'react';
import { AppShell, Text, Burger, useMantineTheme, ActionIcon, Group, ScrollArea, Divider, ThemeIcon, UnstyledButton, Anchor, Collapse, Box, Space, Stack, HoverCard, useMantineColorScheme, useComputedColorScheme, Image, Flex, Paper } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { Check, ChevronRight, Login, Logout, MoonStars, Sun, UserPlus } from 'tabler-icons-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { USER_RESET, USER_SET, useUserDispatch, useUserState } from '../helpers/GlobalUserState';
import { Auth, Hub } from 'aws-amplify';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { BC_TYPE_USERCHANGE, USERGROUP_ADMIN, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_SUPPORT, USERGROUP_USER } from '../helpers/Constants';
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from '../helpers/GlobalLoadingState';
import LoadingOverlay from './LoadingOverlay';
import { showNotification } from '@mantine/notifications';
import { clearDataStore } from '../helpers/Datastore';
import Broadcaster from './Broadcaster';
import { ROUTE_HOME, ROUTE_SIGNIN, ROUTE_SIGNUP, navigationItems } from '../helpers/Routes';
import { useTranslation } from 'react-i18next';
import LanguageButton from './LanguageButton';
import UserBadge from './UserBadge';

/**
 * the app wrapper for global states
 * @returns JSX
 */
export default function AppShellWrapper(props) {

    // globals
    const theme = useMantineTheme();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`);
    const { setColorScheme } = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });
    const user = useUserState();
    const setError = useErrorDispatch();
    const setUser = useUserDispatch();
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const [opened, setOpened] = useState(false);
    const [appLoading, setAppLoading] = useState(true);
    const location = useLocation();
    const { t } = useTranslation();

    // footer links
    const footerLinks = [
        { label: t("general.terms_of_use"), link: "https://genius-tech.at/nutzungsbedingungen" },
        { label: t("general.terms_and_conditions"), link: "https://genius-tech.at/agb" },
        { label: t("general.privacy_policy"), link: "https://genius-tech.at/datenschutz" },
        { label: t("general.imprint"), link: "https://genius-tech.at/impressum" },
    ];

    // generate footer links
    const footerItems = footerLinks.map((link) => (
        <Anchor
            as={"a"}
            c="dimmed"
            key={link.label}
            href={link.link}
            size="sm"
            style={{ textDecoration: "underline", lineHeight: 1 }}
        >
            {link.label}
        </Anchor>
    ));

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        fetchUserDetails().then(user => {
            if (!user) {
                return;
            }

            setUser({
                action: USER_SET,
                values: user
            });
        }).catch(e => {
            setError({ action: ERROR_SHOW, error: e });
        }).finally(() => {
            setAppLoading(false);
        });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * Use effect hook to listen for redirect signin
     */
    useEffect(() => {
        const hubAuthListener = Hub.listen("auth", ({ payload }) => {
            if (payload.event !== "signIn") {
                return;
            }

            fetchUserDetails().then(user => {
                if (!user) {
                    return;
                }

                setUser({
                    action: USER_SET,
                    values: user
                });
            }).catch(e => {
                setError({ action: ERROR_SHOW, error: e });
            }).finally(() => {
                setAppLoading(false);
            });
        });

        return hubAuthListener;
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // listener will fire when the history changes
    useEffect(() => {
        setOpened(false);
    }, [location]);

    /**
     * handling sign out of the user
     */
    const signOut = async () => {
        setLoading(LOADING_SHOW);
        Broadcaster.postMessage({ type: BC_TYPE_USERCHANGE, id: user.id });
        await clearDataStore();
        Auth.signOut().then(async () => {
            setUser(USER_RESET);
            navigate(ROUTE_HOME);
            showNotification({ message: t("auth.signout.message"), color: 'green', icon: <Check /> });
        }).finally(() => {
            setLoading(LOADING_RESET);
        });
    }

    /**
     * gets the navigation items for the navigation depending on users group
     */
    function getNavigationItems() {
        var filtered = [];
        navigationItems.forEach(item => {
            if (item.items && item.items.length > 0) {
                var subItems = [];
                item.items.forEach(subitem => {
                    if ((!user.id && !subitem.routeType) || (subitem.routeType && subitem.routeType.includes(user.userGroup))) {
                        subItems.push(subitem);
                    }
                });

                if (subItems.length === 1) {
                    filtered = filtered.concat(subItems);
                }
                else if (subItems.length > 1) {
                    filtered.push({
                        ...item,
                        items: subItems
                    });
                }
            }
            else {
                if ((!user.id && !item.routeType) || (item.routeType && item.routeType.includes(user.userGroup))) {
                    filtered.push(item);
                }
            }
        });

        return (
            <Stack gap={5}>
                {filtered.map((e) => <MenuItem icon={e.icon} key={e.path || e.label} label={e.label} to={e.path} items={e.items} callback={e.redirect} url={e.url} />)}
            </Stack>
        )
    }

    // if the app is loading, show loading screen
    if (appLoading) {
        return (
            <LoadingOverlay loading={true} />
        );
    }

    return (
        <AppShell
            navbar={{ width: 300, breakpoint: "sm", collapsed: { mobile: !opened } }}
            header={{ height: mediaQueryLargerSm ? 0.000000001 : 70 }}
            padding="md"
        >
            {!mediaQueryLargerSm &&
                <AppShell.Header>
                    <Flex
                        align="center"
                        style={{ height: "100%" }}
                    >
                        <Paper bg="white" h={50} px="md" py={5} ml="md" mr="md">
                            <Image h={40} w="auto" src="/Logo_UnitedTalents24.png" fit="contain" />
                        </Paper>
                        <Burger
                            opened={opened}
                            onClick={() => setOpened((o) => !o)}
                            size="sm"
                            color="gray.6"
                            mr="md"
                            style={{ marginLeft: "auto" }}
                        />
                    </Flex>
                </AppShell.Header>
            }

            <AppShell.Navbar pl="md" pr="md">
                {mediaQueryLargerSm &&
                    <AppShell.Section pt="md" pb="md">
                        <Paper bg="white" px="md" py={5}>
                            <Image src="/Logo_UnitedTalents24.png" />
                        </Paper>
                    </AppShell.Section>
                }

                <AppShell.Section grow component={ScrollArea}>
                    {!mediaQueryLargerSm &&
                        <Space h={20} />
                    }
                    {getNavigationItems()}
                    <Space h="sm" />
                </AppShell.Section>

                <AppShell.Section my="xs">
                    {user.id ?
                        <Box mt="xs">
                            <UserBadge />
                        </Box>
                        :
                        <>
                            <UnstyledButton
                                component={Link}
                                to={ROUTE_SIGNIN}
                                className="menu-item"
                                onClick={() => { setOpened(false); }}
                                mb={5}
                            >
                                <Group>
                                    <ThemeIcon color="green" variant="filled"><Login size={16} /></ThemeIcon>
                                    <Text size="sm">{t("auth.signin.text")}</Text>
                                </Group>
                            </UnstyledButton>
                            <UnstyledButton
                                component={Link}
                                to={ROUTE_SIGNUP}
                                className="menu-item"
                                onClick={() => { setOpened(false); }}
                            >
                                <Group>
                                    <ThemeIcon color="green" variant="filled"><UserPlus size={16} /></ThemeIcon>
                                    <Text size="sm">{t("auth.signup.text")}</Text>
                                </Group>
                            </UnstyledButton>
                            <Divider mt="xs" />
                        </>
                    }
                </AppShell.Section>

                <AppShell.Section mt="xs" mb="md">
                    <Group justify="space-between" gap="xs">
                        <Group gap="xs">
                            <LanguageButton />
                            <HoverCard
                                shadow="md"
                                withArrow
                                arrowPosition="center"
                                position="top-start"
                                events={{ hover: true, focus: false, click: false }}
                            >
                                <HoverCard.Target>
                                    <ActionIcon variant="default" onClick={() => setColorScheme(computedColorScheme === 'light' ? 'dark' : 'light')} size={32} bg="transparent" className="border-gray">
                                        {computedColorScheme === 'dark' ? <Sun color="white" size={24} /> : <MoonStars color="white" size={24} />}
                                    </ActionIcon>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Stack>
                                        <Text c="dimmed" size="xs">{computedColorScheme === 'dark' ? t("general.theme_light") : t("general.theme_dark")}</Text>
                                    </Stack>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        </Group>
                        {user.id &&
                            <HoverCard
                                shadow="md"
                                withArrow
                                arrowPosition="center"
                                position="top-start"
                                events={{ hover: true, focus: false, touch: false }}
                            >
                                <HoverCard.Target>
                                    <ActionIcon variant="filled" color="red" onClick={() => signOut()} size={32}>
                                        <Logout size={24} color="white" />
                                    </ActionIcon>
                                </HoverCard.Target>
                                <HoverCard.Dropdown>
                                    <Stack>
                                        <Text c="dimmed" size="xs">{t("auth.signout.text")}</Text>
                                    </Stack>
                                </HoverCard.Dropdown>
                            </HoverCard>
                        }
                    </Group>
                </AppShell.Section>
            </AppShell.Navbar>

            <AppShell.Main>
                {props.children}
            </AppShell.Main>

            <footer>
                <div className="footer-inner">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div><Image height={50} src="/Logo_UnitedTalents24.png" fit="contain" /></div>
                    </div>
                    <Group className="footer-links">{footerItems}</Group>
                </div>
            </footer>
        </AppShell>
    )
}

/**
 * wrapper to get user details
 */
export const fetchUserDetails = async () => {
    // get user
    var authenticatedUser = null;
    var userInfo = null;
    try {
        authenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
        userInfo = await Auth.currentUserInfo();
    }
    catch {
        // an exception is thrown if no user is logged in, in that case just return
        return null;
    }

    // get user group
    var userGroup = getUserGroup(authenticatedUser);

    // set new user data
    return {
        id: authenticatedUser.attributes.sub,
        identityId: userInfo.id,
        email: authenticatedUser.attributes.email,
        userGroup: userGroup,
        companyId: authenticatedUser.attributes["custom:auth_company_id"],
    }
}

/**
 * gets the user group from the user
 * @param {*} authenticatedUser the authenticated user
 * @returns the user group
 */
const getUserGroup = (authenticatedUser) => {
    var userGroups = authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"];
    if (userGroups && userGroups.length > 0) {
        if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_USER)) {
            return USERGROUP_USER;
        }

        if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_COMPANYADMIN)) {
            return USERGROUP_COMPANYADMIN;
        }

        if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_COMPANY)) {
            return USERGROUP_COMPANY;
        }

        if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_ADMIN)) {
            return USERGROUP_ADMIN;
        }

        if (authenticatedUser.signInUserSession.accessToken.payload["cognito:groups"].includes(USERGROUP_SUPPORT)) {
            return USERGROUP_SUPPORT;
        }
    }

    // if the user group is still not set, we can't do anything else then throw an error
    throw new Error("user group could not be determinded");
}

/**
 * generates a menu item
 * @param {Icon} icon the icon to show 
 * @param {string} color the color for the menu item
 * @param {string} label text to show for the menu item
 * @param {string} to the route to move to
 * @param {array} to sub items
 * @returns JSX for menu item
 */
function MenuItem({ icon, color, label, to, items, redirect, url }) {

    // globals
    const location = useLocation();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const { t } = useTranslation();
    var hasSubItems = items && items.length > 0;

    const checkCurrentPath = (path) => {
        return location.pathname.startsWith(path);
    }

    const checkSubItemCurrentPath = () => {
        if (!hasSubItems) {
            return false;
        }

        const found = items.find((subItem) => {
            return checkCurrentPath(subItem.path);
        })
        return found ? true : false;
    }

    var currentPath = checkCurrentPath(to) && !hasSubItems;
    const [opened, setOpened] = useState(checkSubItemCurrentPath());

    return (
        <>
            <UnstyledButton
                component={to ? Link : null}
                to={to ? to : null}
                className={`menu-item ${currentPath && "menu-item-current"}`}
                onClick={async () => {
                    if (hasSubItems) {
                        setOpened(!opened);
                    }
                    if (redirect) {
                        try {
                            setLoading(LOADING_SHOW);
                            const url = await redirect();
                            window.location.assign(url);
                        }
                        catch (err) {
                            setLoading(LOADING_RESET);
                            setError({ action: ERROR_SHOW, error: err });
                        }
                    }
                    if (url) {
                        window.open(url, "_blank", "noreferrer");
                    }
                }}
            >
                <>
                    <Group justify="space-between">
                        <Group>
                            <ThemeIcon color="#005DAA" variant="filled">{icon}</ThemeIcon>
                            <Text fw={700} size="sm">{t(label)}</Text>
                        </Group>
                        {hasSubItems && <ChevronRight size={16} style={{ transform: opened ? "rotate(-90deg)" : "none", transition: "transform 200ms ease" }} />}
                    </Group>
                </>
            </UnstyledButton>
            {hasSubItems &&
                <Collapse in={opened}>
                    <Box className="menu-item-wrapper">
                        <Stack gap={5}>
                            {items.map((e) => {
                                return <MenuItem key={e.path || e.label} icon={e.icon} label={e.label} to={e.path} redirect={e.redirect} url={e.url} />
                            })}
                        </Stack>
                    </Box>
                </Collapse>
            }
        </>
    );
}