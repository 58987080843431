import React, { useEffect, useState } from 'react';
import { MultiSelect, Select } from '@mantine/core';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { getTextFromTranslations } from '../helpers/i18n';

/**
 * generic model select input
 */
const ModelSelectInput = (props) => {

    // globals
    const [items, setItems] = useState([]);
    const setError = useErrorDispatch();

    /**
     * fetches the model entries from db
     * @returns array of results
     */
    const fetchData = async () => {
        try {
            // get items
            const items = await DataStore.query(props.type,
                p => p.active.eq(true),
                { sort: s => s.name(SortDirection.ASCENDING) }
            );

            // get translations
            const itemPromises = items.map((e) => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const translatedText = await getTextFromTranslations(e.translations);
                        resolve({ value: e.id, label: translatedText || e.name });
                    }
                    catch (err) {
                        reject(err);
                    }

                });
            })
            var mappedItems = await Promise.all(itemPromises);

            // sort by label
            mappedItems.sort((a, b) => (a.label.localeCompare(b.label)));

            // set items
            setItems(mappedItems);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    };

    /**
     * use effect hook to listen to fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    if (!props.multiple) {
        return (
            <Select
                searchable
                data={items || []}
                {...props}
                type={null}
            />
        );
    }
    else {
        return (
            <MultiSelect
                searchable
                checkIconPosition="right"
                data={items || []}
                {...props}
                type={null}
            />
        );
    }
}

// Default export of the class
export default ModelSelectInput;