import { Button, Group, Stack, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { Plus } from "tabler-icons-react";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { useNavigate } from "react-router-dom";
import { executeApiCall, ADMIN_API_COMPANY_LISTUSERS, API_MODE_GET, ADMIN_API_NAME } from "../../helpers/APIHelper";
import CompanyUsersList from "../../components/CompanyUsersList";
import { ROUTE_COMPANYADMIN_USERS_NEW } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';

/**
 * page to list users per company for company admins
 * @returns JSX
 */
export default function PageCompanyUsers() {

    // constants
    const [users, setUsers] = useState([]);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        try {
            const users = await executeApiCall(ADMIN_API_NAME, API_MODE_GET, ADMIN_API_COMPANY_LISTUSERS);
            setUsers(users);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("company.user.administration")}</Title>
                <Button
                    color="green"
                    leftSection={<Plus />}
                    onClick={() => navigate(ROUTE_COMPANYADMIN_USERS_NEW)}
                >
                    {t("auth.signup.invite")}
                </Button>
            </Group>

            <CompanyUsersList users={users} />
        </Stack>
    );
}