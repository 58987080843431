import { useTranslation } from "react-i18next";
import { JOBAPPLICATION_COMPANYDATA_STATUS_COLOR, JOBAPPLICATION_COMPANYDATA_STATUS_TEXT } from "../helpers/Constants";
import BadgeAutoHeight from "./BadgeAutoHeight";
import { Text } from "@mantine/core";

/**
 * application status badge
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeApplicationStatus(props) {

    // globals
    const { t } = useTranslation();

    // if application is not set, just return
    if (!props.application) {
        return null;
    }

    return (
        <BadgeAutoHeight
            color={JOBAPPLICATION_COMPANYDATA_STATUS_COLOR[props.application.status]}
            variant={props.variant ? props.variant : null}
            rightSection={props.rightSection ? props.rightSection : null}
            size={props.size ? props.size : "xs"}
        >
            <Text size={props.textSize ? props.textSize : "xs"}>{t(JOBAPPLICATION_COMPANYDATA_STATUS_TEXT[props.application.status])}</Text>
        </BadgeAutoHeight>
    )
}