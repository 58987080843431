import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container, Divider } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD, VALIDATION_SCHEMA_PASSWORD_CONFIRMATION } from "../../helpers/Validation";
import { useForm } from "../../components/Form";
import { USERGROUP_USER } from "../../helpers/Constants";
import { ROUTE_SIGNIN, ROUTE_SIGNUP_CONFIRM } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import i18n from "../../helpers/i18n";
import SignInSocialProvider from "../../components/SignInSocialProvider";

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
    password: VALIDATION_SCHEMA_PASSWORD,
    passwordConfirm: VALIDATION_SCHEMA_PASSWORD_CONFIRMATION,
});

/**
 * user sign up page
 * @returns JSX
 */
export default function PageUserSignup() {

    // globals
    const navigate = useNavigate();
    const { t } = useTranslation();

    // submit callback
    const submitCallback = async (values) => {
        try {
            await Auth.signUp({
                username: values.email.toLowerCase(),
                password: values.password,
                attributes: {
                    email: values.email.toLowerCase(),
                    'custom:signup_group': USERGROUP_USER,
                    "custom:signup_u_language": i18n.language
                }
            });
            showNotification({ message: t("auth.account.created"), color: 'green', icon: <Check /> });
            navigate(ROUTE_SIGNUP_CONFIRM, { state: { email: values.email }});
        }
        catch (e) {
            if (e.code === "UsernameExistsException") {
                showNotification({ message: t("auth.account.exists"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: "",
            password: "",
            passwordConfirm: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <Paper withBorder shadow="md" p="md" radius="md">
                    <Title align="center">{t("auth.account.create.user")}</Title>
                    <Link to={ROUTE_SIGNIN}>
                        <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" mb="md">
                            {t("auth.signin.question")}
                        </Text>
                    </Link>

                    <form
                        onSubmit={form.onSubmit()}
                        onReset={form.onReset}
                    >
                        <Stack>
                            <TextInput
                                withAsterisk
                                label={t("general.email")}
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                            />
                            <PasswordInput
                                withAsterisk
                                label={t("auth.password.password")}
                                placeholder={t("auth.password.password")}
                                {...form.getInputProps('password')}
                            />
                            <PasswordInput
                                withAsterisk
                                label={t(t("auth.password.confirm"))}
                                placeholder={t(t("auth.password.confirm"))}
                                {...form.getInputProps('passwordConfirm')}
                            />
                            <Button type="submit" fullWidth mt="md">{t("auth.account.create.user")}</Button>
                            <Divider mt="md" mb="md" />

                            <SignInSocialProvider />
                        </Stack>
                    </form>
                </Paper>
            </Container>
        </Center>
    );
}