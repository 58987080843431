import { Button, Divider, Group, SimpleGrid, Skeleton, Stack, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Search } from "tabler-icons-react";
import Statistics from "../../components/Statistics";
import { ROUTE_SUPPORT_APPLICATIONS, ROUTE_SUPPORT_COMPANIES, ROUTE_SUPPORT_JOBS_LIST, ROUTE_SUPPORT_USERS } from "../../helpers/Routes";
import { fetchStats } from "../../helpers/Datastore";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useUserState } from "../../helpers/GlobalUserState";
import { Company, JobApplication, JobOffer, User } from "../../models";
import { useTranslation } from 'react-i18next';

/**
 * support home page
 */
export default function PageSupportHome() {

    const user = useUserState();
    const setError = useErrorDispatch();
    const [applicationStats, setApplicationStats] = useState(undefined);
    const [userStats, setUserStats] = useState(undefined);
    const [companyStats, setCompanyStats] = useState(undefined);
    const [jobStats, setJobStats] = useState(undefined);
    const { t } = useTranslation();

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        try {
            // fetch data
            const applicationStats = await fetchStats(JobApplication);
            const userStats = await fetchStats(User);
            const companyStats = await fetchStats(Company);
            const jobStats = await fetchStats(JobOffer);

            // set data
            setApplicationStats(applicationStats);
            setUserStats(userStats);
            setCompanyStats(companyStats);
            setJobStats(jobStats);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    // if still loading, show skeletons
    if (!applicationStats || !userStats || !companyStats || !jobStats) {
        return (
            <Stack>
                <Skeleton height={36} />
                <SimpleGrid
                    cols={{ base: 1, md: 3, lg: 6 }}
                >
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
                <Skeleton height={1} />
                <Skeleton height={36} />
                <SimpleGrid
                    cols={{ base: 1, md: 3, lg: 6 }}
                >
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
                <Skeleton height={1} />
                <Skeleton height={36} />
                <SimpleGrid
                    cols={{ base: 1, md: 3, lg: 6 }}
                >
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
                <Skeleton height={1} />
                <Skeleton height={36} />
                <SimpleGrid
                    cols={{ base: 1, md: 3, lg: 6 }}
                >
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                    <Skeleton height={105} />
                </SimpleGrid>
            </Stack>
        );
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title order={3}>{t("application.applications")}</Title>
                <Link to={ROUTE_SUPPORT_APPLICATIONS}><Button leftSection={<Search size={14} />} color="blue" variant="outline">{t("application.applications")}</Button></Link>
            </Group>
            <Statistics stats={applicationStats} />
            <Divider />

            <Group justify="space-between">
                <Title order={3}>{t("job.jobs")}</Title>
                <Link to={ROUTE_SUPPORT_JOBS_LIST}><Button leftSection={<Search size={14} />} color="blue" variant="outline">{t("job.jobs")}</Button></Link>
            </Group>
            <Statistics stats={jobStats} />
            <Divider />

            <Group justify="space-between">
                <Title order={3}>{t("user.group.user")}</Title>
                <Link to={ROUTE_SUPPORT_USERS}><Button leftSection={<Search size={14} />} color="blue" variant="outline">{t("user.group.user")}</Button></Link>
            </Group>
            <Statistics stats={userStats} />
            <Divider />

            <Group justify="space-between">
                <Title order={3}>{t("company.companies")}</Title>
                <Link to={ROUTE_SUPPORT_COMPANIES}><Button leftSection={<Search size={14} />} color="blue" variant="outline">{t("company.companies")}</Button></Link>
            </Group>
            <Statistics stats={companyStats} />
        </Stack>
    );
}