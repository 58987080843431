import moment from 'moment-timezone';
import 'moment/locale/de';
import i18n, { fallbackLanguage } from './i18n';

// set time zone and language
var tz = moment.tz.guess();
moment.tz.setDefault(tz);
moment.locale(fallbackLanguage);
moment.localeData(fallbackLanguage);


// callback for language change to set new language in moment
i18n.on('languageChanged', () => {
    // try all languages from i18n
    for (let index = 0; index < i18n.languages.length; index++) {
        const language = i18n.languages[index];
        try {
            const newLocale = moment.locale(language);
            moment.localeData(language);

            // check if correctly set, then stop
            if (newLocale === language) {
                break;
            }
        }
        catch { }
    }
});

/**
 * wrapper to format date
 * @param {date} date date to format
 * @param {*} format the format to format the date with
 * @returns formatted date
 */
export function formatDate(date, format) {
    var timestamp = moment(date).local(tz)
    if (timestamp.isValid()) {
        return timestamp.format(format);
    }

    return "";
}

/**
 * gets the long date format "L" localized
 * @returns format text
 */
export function getDateFormat() {
    return moment().local(tz).creationData().locale._longDateFormat.L
}

// default export
export default moment;