import { Stack, Text } from "@mantine/core";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { AppleLoginButton, FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

/**
 * social provider sign in buttons
 * @returns JSX
 */
const SignInSocialProvider = () => {

    // globals
    const { t } = useTranslation();

    return (
        <Stack gap="sm">
            <FacebookLoginButton style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }} preventActiveStyles={true} onClick={() => Auth.federatedSignIn({ provider: 'Facebook' })}>
                <Text>{t("auth.signin.provider.facebook")}</Text>
            </FacebookLoginButton>

            <GoogleLoginButton style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }} preventActiveStyles={true} onClick={() => Auth.federatedSignIn({ provider: 'Google' })}>
                <Text>{t("auth.signin.provider.google")}</Text>
            </GoogleLoginButton>

            <AppleLoginButton style={{ width: "100%", marginLeft: "0px", marginRight: "0px" }} preventActiveStyles={true} onClick={() => Auth.federatedSignIn({ provider: 'SignInWithApple' })}>
                <Text>{t("auth.signin.provider.apple")}</Text>
            </AppleLoginButton>
        </Stack>
    )
}

// Default export of the class
export default SignInSocialProvider;