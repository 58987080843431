import { Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { DataStore } from '@aws-amplify/datastore';
import { useEffect } from "react";
import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
import * as Yup from 'yup';
import { useForm } from "../../components/Form";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, LOADING_SHOW_AND_HIDE_CHILDREN, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { useUserState } from "../../helpers/GlobalUserState";
import { VALIDATION_SCHEMA_LOCATION, VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PHONENUMBER, VALIDATION_SCHEMA_ADDRESSTEXT, VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import { Company } from "../../models";
import LocationSearchInput from "../../components/LocationSearchInput";
import FileInput from "../../components/FileInput";
import { FILE_TYPE_IMAGE, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../../helpers/Constants";
import CustomRichTextEditor from "../../components/RichTextEditor";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ROUTE_COMPANY_USER_PROFILE, ROUTE_SUPPORT_COMPANIES } from "../../helpers/Routes";

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_STRING,
    email: VALIDATION_SCHEMA_EMAIL,
    phonenumber: VALIDATION_SCHEMA_PHONENUMBER,
    location: VALIDATION_SCHEMA_LOCATION,
    addressText: VALIDATION_SCHEMA_ADDRESSTEXT,
});

/**
 * company details page
 * @returns JSX
 */
export default function PageCompanyDetails() {

    // globals
    const setError = useErrorDispatch();
    const user = useUserState();
    const setLoading = useLoadingDispatch();
    const { companyId } = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            // get item
            setLoading(LOADING_SHOW_AND_HIDE_CHILDREN);
            const item = await DataStore.query(Company, companyId ? companyId : user.companyId);
            if (!item) {
                // company user data not found
                throw new Error(t("error.company"));
            }

            // set data
            form.setInitialValues({
                id: item.id,
                name: item.name,
                email: item.email,
                phonenumber: item.phonenumber,
                location: {
                    lat: item.location.lat,
                    lon: item.location.lon,
                },
                addressText: item.addressText,
                logo: item.logo,
                description: item.description,
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // submit callback
    const submitCallback = async (values) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update database 
            const original = await DataStore.query(Company, values.id);
            await DataStore.save(Company.copyOf(original, updated => {
                updated.name = values.name;
                updated.email = values.email;
                updated.phonenumber = values.phonenumber;
                updated.location = values.location;
                updated.addressText = values.addressText;
                updated.logo = values.logo;
                updated.description = values.description;
            }));

            showNotification({ message: t("company.saved"), color: 'green', icon: <Check /> });
            if (user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) {
                navigate(ROUTE_SUPPORT_COMPANIES);
            }
            else {
                navigate(ROUTE_COMPANY_USER_PROFILE);
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            id: "",
            name: "",
            email: "",
            phonenumber: "",
            location: {
                lat: null,
                lon: null,
            },
            addressText: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{companyId ? form.values.name : t("company.my")}</Title>
                {(user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) &&
                    <Button leftSection={<ArrowLeft size={14} />} color="yellow" onClick={() => navigate(ROUTE_SUPPORT_COMPANIES)}>{t("general.back")}</Button>
                }
            </Group>
            <Divider />

            {form?.values.id &&
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Stack>
                        <TextInput
                            withAsterisk
                            label={t("company.name")}
                            placeholder={t("company.name")}
                            {...form.getInputProps('name')}
                        />
                        <TextInput
                            withAsterisk
                            label={t("company.email")}
                            placeholder="office@company.com"
                            {...form.getInputProps('email')}
                        />
                        <TextInput
                            withAsterisk
                            label={t("company.phone")}
                            placeholder="+XXYYY..."
                            {...form.getInputProps('phonenumber')}
                        />
                        <LocationSearchInput
                            label={t("company.address")}
                            form={form}
                            withAsterisk={true}
                        />
                        <FileInput
                            label={t("company.logo")}
                            fileProtectionLevel="protected"
                            identityId={form.values.id}
                            fileType={FILE_TYPE_IMAGE}
                            initFiles={form.values.logo}
                            form={form}
                            path="logo"
                        />
                        <CustomRichTextEditor
                            label={t("company.description.description")}
                            placeholder={t("company.description.description")}
                            form={form}
                            contentField="description"
                            description={t("company.description.message")}
                        />
                        <Divider />

                        <Group justify='space-between'>
                            <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                            <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.save")}</Button>
                        </Group>
                    </Stack>
                </form>
            }
        </Stack>
    )
}