import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Placeholder from '@tiptap/extension-placeholder';
import { Color } from '@tiptap/extension-color';
import { IconColorPicker } from '@tabler/icons-react';
import TextStyle from '@tiptap/extension-text-style';
import { useEffect } from 'react';
import { Input } from '@mantine/core';
import InputBackground from './InputBackground';

/**
 * custom component to wrap rich text editor into form
 * @param {object} props the component props
 * @returns rich text editor JSX
 */
export default function CustomRichTextEditor(props) {

    /**
     * on change handler for the editor component, updates the form value
     * @param {object} value the editor component
     */
    const onChange = (value) => {
        var values = {};
        values[props.contentField] = "";

        if (!value.editor.getText()) {
            // check if any text had been entered, if not, just return an empty string
            props.form.setValues({ ...values });
        }
        else {
            // otherwise get the html from the editor, and set it
            values[props.contentField] = value.editor.getHTML();
            props.form.setValues({ ...values });
        }
    }

    /**
     * the editor component
     */
    const editor = useEditor({
        extensions: [
            StarterKit,
            Underline,
            Link,
            Superscript,
            SubScript,
            Highlight,
            TextAlign.configure({ types: ['heading', 'paragraph'] }),
            Placeholder.configure({ placeholder: props.placeholder ? props.placeholder : "" }),
            TextStyle,
            Color
        ],
        onUpdate: onChange,
        content: props.form.values[props.contentField]
    });

    /**
     * use effect hook to trigger changes to content if value had been changed from form
     */
    useEffect(() => {
        if (editor && editor.getHTML() !== props.form.values[props.contentField]) {
            editor?.commands.setContent(props.form.values[props.contentField]);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.contentField, props.form.values[props.contentField]]
    );

    return (
        <Input.Wrapper error={props.form.errors[props.contentField]}>
            <Input.Label required={props.withAsterisk}>{props.label}</Input.Label>
            <InputBackground style={{ padding: "0px" }} error={props.form.errors[props.contentField]}>
                <RichTextEditor style={{ border: "none" }} editor={editor} >
                    <RichTextEditor.Toolbar sticky stickyOffset={60}>
                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Bold />
                            <RichTextEditor.Italic />
                            <RichTextEditor.Underline />
                            <RichTextEditor.Strikethrough />
                            <RichTextEditor.ClearFormatting />
                            <RichTextEditor.Highlight />
                            <RichTextEditor.Code />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.H1 />
                            <RichTextEditor.H2 />
                            <RichTextEditor.H3 />
                            <RichTextEditor.H4 />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ColorPicker
                            colors={[
                                '#25262b',
                                '#868e96',
                                '#fa5252',
                                '#e64980',
                                '#be4bdb',
                                '#7950f2',
                                '#4c6ef5',
                                '#228be6',
                                '#15aabf',
                                '#12b886',
                                '#40c057',
                                '#82c91e',
                                '#fab005',
                                '#fd7e14',
                            ]}
                        />

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Control interactive={false}>
                                <IconColorPicker size="1rem" stroke={1.5} />
                            </RichTextEditor.Control>
                            <RichTextEditor.Color color="#F03E3E" />
                            <RichTextEditor.Color color="#7048E8" />
                            <RichTextEditor.Color color="#1098AD" />
                            <RichTextEditor.Color color="#37B24D" />
                            <RichTextEditor.Color color="#F59F00" />
                            <RichTextEditor.UnsetColor />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Blockquote />
                            <RichTextEditor.Hr />
                            <RichTextEditor.BulletList />
                            <RichTextEditor.OrderedList />
                            <RichTextEditor.Subscript />
                            <RichTextEditor.Superscript />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.Link />
                            <RichTextEditor.Unlink />
                        </RichTextEditor.ControlsGroup>

                        <RichTextEditor.ControlsGroup>
                            <RichTextEditor.AlignLeft />
                            <RichTextEditor.AlignCenter />
                            <RichTextEditor.AlignJustify />
                            <RichTextEditor.AlignRight />
                        </RichTextEditor.ControlsGroup>
                    </RichTextEditor.Toolbar>

                    <RichTextEditor.Content onChange={onChange} />
                </RichTextEditor>
            </InputBackground>
            {props.description &&
                <Input.Description size="xs" color="dimmed">{props.description}</Input.Description>
            }
        </Input.Wrapper>
    )
}