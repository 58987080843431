import { Center, Container, Fieldset, Paper, Stack, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";
import SignInEmail from "../../components/SignInEmail";
import SignInSocialProvider from "../../components/SignInSocialProvider";
import { useTranslation } from "react-i18next";
import { ROUTE_SIGNUP } from "../../helpers/Routes";

/**
 * page to handle user signin
 * @returns JSX
 */
export default function PageUserSignin() {
    
    // globals
    const { t } = useTranslation();

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <Paper withBorder shadow="md" p="md" radius="md">
                    <Title align="center">{t("auth.signin.user.link")}</Title>
                    <Link to={ROUTE_SIGNUP}>
                        <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" mb="md">
                            {t("auth.signup.question")}
                        </Text>
                    </Link>

                    <Stack>
                        <Fieldset legend={t("auth.signin.provider.email")}>
                            <SignInEmail />
                        </Fieldset>
                        <Fieldset legend={t("auth.signin.provider.socials")}>
                            <SignInSocialProvider />
                        </Fieldset>
                    </Stack>
                </Paper>
            </Container>
        </Center>
    )
}