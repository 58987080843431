import { DataStore } from "aws-amplify";
import { Alert, Button, Group, List, Stack, Text, Title } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlertCircle } from "tabler-icons-react";
import { USERGROUP_USER } from "../helpers/Constants";
import { useUserState } from "../helpers/GlobalUserState";
import { User } from "../models";
import { ROUTE_USER_PROFILE_EDIT } from "../helpers/Routes";
import { useTranslation } from 'react-i18next';

/**
 * Alert for user, if user data is not set
 * @returns JSX
 */
export default function UserDataAlert() {

    // globals
    const navigate = useNavigate();
    const user = useUserState();
    const userDataRef = useRef(null);
    const { t } = useTranslation();
    const [visible, setVisible] = useState(false);

    /**
     * use effect hook to create subscription for user data
     */
    useEffect(() => {
        if (user?.id && user?.userGroup === USERGROUP_USER) {
            userDataRef.current?.unsubscribe();
            userDataRef.current = DataStore.observeQuery(User, p => p.id.eq(user.id)).subscribe(snapshot => {
                const { items, isSynced } = snapshot;

                // if not synced, discard
                if (!isSynced) {
                    return;
                }

                setVisible(items.length === 0)
            });
        }

        // unsubscribe from listeners when unmounting
        return () => {
            userDataRef.current?.unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id, user.userGroup]
    );

    // if data is set, dont show
    if (!visible) {
        return null;
    }

    // no user set, dont show
    if (!user || !user.id) {
        return null;
    }

    // if user is not group user, dont show
    if (user.userGroup !== USERGROUP_USER) {
        return null;
    }

    // otherwise show alert
    return (
        <Alert
            variant="outline"
            icon={<AlertCircle size={16} />}
            color="yellow"
            title={<Title order={3}>{t("user.profile.notset.title")}</Title>}
            mb="xl"
        >
            <Group align="self-end">
                <Stack gap={0} pr={18}>
                    <Text>{t("user.profile.notset.list_header")}</Text>
                    <List size="md">
                        <List.Item>{t("user.profile.notset.text_1")}</List.Item>
                        <List.Item>{t("user.profile.notset.text_2")}</List.Item>
                        <List.Item>{t("user.profile.notset.text_3")}</List.Item>
                    </List>
                </Stack>
                <Button style={{ marginLeft: "auto" }} size="compact-sm" variant="outline" color="green" onClick={() => navigate(ROUTE_USER_PROFILE_EDIT)}>{t("user.profile.notset.button")}</Button>
            </Group>
        </Alert>
    )
}