import { Accordion, Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { Filter, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import List, { COLUMN_TYPE_TIMESTAMP } from "../../components/List";
import SortDirectionInput from "../../components/SortDirectionInput";
import SortFieldInput from "../../components/SortFieldInput";
import { ROUTE_COMPANY_APPLICATIONS_EDIT, ROUTE_SUPPORT_APPLICATIONS_SHOW } from "../../helpers/Routes";
import { PREDICATE_CONTAINS, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { useUserState } from "../../helpers/GlobalUserState";
import { getFilterPredicates } from "../../helpers/Predicates";
import { useTranslation } from 'react-i18next';
import { JobApplication } from "../../models";

/**
 * support page for applications
 * @returns JSX
 */
export default function PageSupportApplications() {

    // globals
    const user = useUserState();
    const { t } = useTranslation();

    // form hook
    const form = useForm({
        initialValues: {
            jobName: "",
            applicantName: "",
            companyName: "",
            addressText: "",
            sortField: "createdAt",
            sortDirection: SortDirection.DESCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "name", value: form.values.applicantName, type: PREDICATE_CONTAINS },
            { key: "jobOffer.name", value: form.values.jobName, type: PREDICATE_CONTAINS },
            { key: "jobOffer.company.name", value: form.values.companyName, type: PREDICATE_CONTAINS },
            { key: "jobOffer.addressText", value: form.values.addressText, type: PREDICATE_CONTAINS },
        ]);
    }

    return (
        <Stack>
            <Title>{t("application.applications")}</Title>
            <Divider />

            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("job.job")}
                                    placeholder={t("job.job")}
                                    {...form.getInputProps('jobName')}
                                />

                                <TextInput
                                    label={t("application.applicant")}
                                    placeholder={t("application.applicant")}
                                    {...form.getInputProps('applicantName')}
                                />

                                <TextInput
                                    label={t("company.name")}
                                    placeholder={t("company.name")}
                                    {...form.getInputProps('companyName')}
                                />

                                <TextInput
                                    label={t("general.address")}
                                    placeholder={t("general.address")}
                                    {...form.getInputProps('addressText')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'status', label: t("general.status") },
                                        { value: 'createdAt', label: t("date.create_time") },
                                        { value: 'updatedAt', label: t("date.change_time") },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group justify='space-between'>
                                    <Button variant="outline" leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={JobApplication}
                id="id"
                dataStructure={[
                    { key: "jobOffer.name", title: t("job.job") },
                    { key: "name", title: t("application.applicant") },
                    { key: "jobOffer.company.name", title: t("company.name") },
                    { key: "jobOffer.addressText", title: t("general.address") },
                    { key: "createdAt", title: t("date.create_time"), type: COLUMN_TYPE_TIMESTAMP },
                    { key: "updatedAt", title: t("date.change_time"), type: COLUMN_TYPE_TIMESTAMP },
                ]}
                showRoute={(user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) ? ROUTE_SUPPORT_APPLICATIONS_SHOW : null}
                editRoute={user.companyId ? ROUTE_COMPANY_APPLICATIONS_EDIT : null}
                filter={getFilter()}
                sortField={form.values.sortField}
                sortDirection={form.values.sortDirection}
            />
        </Stack>
    )
}