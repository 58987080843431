import { Text, Button, Center, Stack, Title, Paper, TextInput, PasswordInput, Container, Divider, Fieldset } from "@mantine/core";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_PASSWORD, VALIDATION_SCHEMA_PASSWORD_CONFIRMATION, VALIDATION_SCHEMA_PHONENUMBER, VALIDATION_SCHEMA_LOCATION, VALIDATION_SCHEMA_ADDRESSTEXT, VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import { USERGROUP_COMPANYADMIN } from "../../helpers/Constants";
import { useForm } from "../../components/Form";
import LocationSearchInput from "../../components/LocationSearchInput";
import { ROUTE_SIGNIN, ROUTE_SIGNUP_CONFIRM } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import i18n from "../../helpers/i18n";

// validation schema with yup
const validationSchema = Yup.object().shape({
    companyName: VALIDATION_SCHEMA_STRING,
    companyEmail: VALIDATION_SCHEMA_EMAIL,
    companyPhonenumber: VALIDATION_SCHEMA_PHONENUMBER,
    companyAddressText: VALIDATION_SCHEMA_ADDRESSTEXT,
    companyLocation: VALIDATION_SCHEMA_LOCATION,
    name: VALIDATION_SCHEMA_STRING,
    email: VALIDATION_SCHEMA_EMAIL,
    password: VALIDATION_SCHEMA_PASSWORD,
    passwordConfirm: VALIDATION_SCHEMA_PASSWORD_CONFIRMATION,
});

/**
 * company sign in page
 * @returns JSX
 */
export default function PageCompanySignup() {

    // globals
    const navigate = useNavigate();
    const { t } = useTranslation();

    // submit callback
    const submitCallback = async (values) => {
        try {
            await Auth.signUp({
                username: values.email.toLowerCase(),
                password: values.password,
                attributes: {
                    email: values.email.toLowerCase(),
                    "custom:signup_group": USERGROUP_COMPANYADMIN,
                    "custom:signup_c_name": values.companyName,
                    "custom:signup_c_email": values.companyEmail,
                    "custom:signup_c_phonenumber": values.companyPhonenumber,
                    "custom:signup_c_loc_lat": values.companyLocation.lat.toString(),
                    "custom:signup_c_loc_lon": values.companyLocation.lon.toString(),
                    "custom:signup_c_addresstext": values.companyAddressText,
                    "custom:signup_u_name": values.name,
                    "custom:signup_u_language": i18n.language
                }
            });
            showNotification({ message: t("auth.account.created"), color: 'green', icon: <Check /> });
            navigate(ROUTE_SIGNUP_CONFIRM, { state: { email: values.email }});
        }
        catch (e) {
            if (e.code === "UsernameExistsException") {
                showNotification({ message: t("auth.account.exists"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            companyName: "",
            companyEmail: "",
            companyPhonenumber: "",
            companyAddressText: "",
            companyLocation: {
                lat: null,
                lon: null,
            },
            name: "",
            email: "",
            password: "",
            passwordConfirm: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <Paper withBorder shadow="md" p="md" radius="md">
                    <Title align="center">{t("auth.account.create.company")}</Title>
                    <Link to={ROUTE_SIGNIN}>
                        <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" mb="md">
                            {t("auth.signin.question")}
                        </Text>
                    </Link>
                    <Divider />

                    <form
                        onSubmit={form.onSubmit()}
                        onReset={form.onReset}
                    >
                        <Fieldset mt="md" legend={t("company.data")}>
                            <Stack>
                                <TextInput
                                    withAsterisk
                                    label={t("company.name")}
                                    placeholder={t("company.name")}
                                    {...form.getInputProps('companyName')}
                                />
                                <TextInput
                                    withAsterisk
                                    label={t("company.email")}
                                    placeholder="office@company.com"
                                    {...form.getInputProps('companyEmail')}
                                />
                                <TextInput
                                    withAsterisk
                                    label={t("company.phone")}
                                    placeholder="+XXYYY..."
                                    {...form.getInputProps('companyPhonenumber')}
                                />
                                <LocationSearchInput
                                    label={t("company.address")}
                                    form={form}
                                    addressTextFieldName="companyAddressText"
                                    locationFieldName="companyLocation"
                                    withAsterisk={true}
                                />
                            </Stack>
                        </Fieldset>

                        <Fieldset mt="md" legend={t("auth.account.personal")}>
                            <Stack>
                                <TextInput
                                    withAsterisk
                                    label={t("user.name.your")}
                                    placeholder={t("user.name.your")}
                                    {...form.getInputProps('name')}
                                />
                                <TextInput
                                    withAsterisk
                                    label={t("company.user.email")}
                                    placeholder="name@company.com"
                                    {...form.getInputProps('email')}
                                />
                                <PasswordInput
                                    withAsterisk
                                    label={t("auth.password.password")}
                                    placeholder={t("auth.password.password")}
                                    {...form.getInputProps('password')}
                                />
                                <PasswordInput
                                    withAsterisk
                                    label={t(t("auth.password.confirm"))}
                                    placeholder={t(t("auth.password.confirm"))}
                                    {...form.getInputProps('passwordConfirm')}
                                />
                            </Stack>
                        </Fieldset>

                        <Button type="submit" fullWidth mt="md">{t("auth.account.create.user")}</Button>
                </form>
            </Paper>
        </Container>
        </Center >
    );
}