import { Accordion, Button, Divider, Group, Stack, Title, Text, Card, Modal, ScrollArea, Grid, Paper, Container, Flex, ActionIcon, TagsInput } from "@mantine/core";
import { API } from "aws-amplify";
import { BuildingSkyscraper, ExternalLink, List, Reload, Search, X } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import LocationSearchInput from "../../components/LocationSearchInput";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_ADDRESSTEXT, VALIDATION_SCHEMA_LOCATION } from "../../helpers/Validation";
import { nearbyJobOffers } from "../../graphql/queries";
import { memo, useEffect, useState } from "react";
import { JobCategory, PositionLevel, User, WorkTimeRegulation } from "../../models";
import { DataStore } from "aws-amplify";
import { Marker, FullscreenControl, GeolocateControl } from 'react-map-gl';
import { MapView } from "@aws-amplify/ui-react";
import Avatar from "../../components/Avatar";
import BadgeLocation from "../../components/BadgeLocation";
import { ROUTE_COMPANY_SHOW, ROUTE_JOBS_SHOW } from "../../helpers/Routes";
import SortFieldInput from "../../components/SortFieldInput";
import { useUserState } from '../../helpers/GlobalUserState';
import SliderInput from "../../components/SliderInput";
import ModelSelectInput from "../../components/ModelSelectInput";
import Job from '../../components/Job';
import { useTranslation } from 'react-i18next';

// WORKAROUND START
// this is an issue with the production build of mapbox-gl, and a temp fix to add a worker
import { workerClass } from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
import workerLoader from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';
import { Link } from "react-router-dom";
// eslint-disable-next-line
workerClass = workerLoader;
// WORKAROUND END

// validation schema with yup
const validationSchema = Yup.object().shape({
    location: VALIDATION_SCHEMA_LOCATION,
    addressText: VALIDATION_SCHEMA_ADDRESSTEXT,
});

/**
 * job search page
 * @returns JSX
 */
export default function PageGeneralJobs() {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [items, setItems] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [itemsTotal, setItemsTotal] = useState(null);
    const [markerPopup, setMarkerPopup] = useState(null);
    const [openedAccordionItems, setOpenedAccordionItems] = useState(["search"]);
    const [flyTo, setFlyTo] = useState(null);
    const [map, setMap] = useState(null);
    const user = useUserState();
    const { t } = useTranslation();

    // initial view state for the map
    const initialViewState = {
        latitude: 50,
        longitude: 11,
        zoom: 3,
    }

    /**
     * use effect hook to fly to position after search
     */
    useEffect(() => {
        if (map && flyTo) {
            map.flyTo({ center: flyTo, zoom: 10 });
            setFlyTo(null);
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [map, flyTo]
    );

    /**
     * Use effect hook to set default address
     */
    useEffect(() => {
        // get user profile
        if (user.id) {
            DataStore.query(User, user.id).then((e) => {
                if (e && !form.values.addressText && !form.values.location.lat && !form.values.location.lon) {
                    form.setInitialValues({
                        ...form.values,
                        addressText: e.addressText,
                        location: {
                            lat: e.location.lat,
                            lon: e.location.lon,
                        }
                    })
                }
            });
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * fetches the jobs
     * @param {object} values search values
     */
    const fetchJobs = async (values) => {
        setLoading(LOADING_SHOW);
        try {
            // construct keywords
            var keywordsArray = null;
            if (values.keywords && values.keywords.length > 0) {
                keywordsArray = values.keywords.map(e => e.toLowerCase());
            }

            // get the job offers
            let results = [];
            let more = true;
            let nextToken = null;
            var total = 0;
            while (more) {
                const result = await API.graphql({
                    query: nearbyJobOffers,
                    variables: {
                        keywords: keywordsArray,
                        location: {
                            lat: values.location.lat,
                            lon: values.location.lon
                        },
                        km: values.km,
                        workTimeRegulationIds: (values.workTimeRegulationIds && values.workTimeRegulationIds.length > 0) ? values.workTimeRegulationIds : null,
                        jobCategoryIds: (values.jobCategoryIds && values.jobCategoryIds.length > 0) ? values.jobCategoryIds : null,
                        positionLevelIds: (values.positionLevelIds && values.positionLevelIds.length > 0) ? values.positionLevelIds : null,
                        sortId: values.sortField,
                        limit: 10000,
                        nextToken: nextToken,
                    },
                    authMode: user.id ? null : 'AWS_IAM'
                });

                results = results.concat(result.data.nearbyJobOffers.items);
                if (result.data.nearbyJobOffers.nextToken) {
                    nextToken = result.data.nearbyJobOffers.nextToken;
                } else {
                    more = false;
                    total = result.data.nearbyJobOffers.total
                }
            }

            // summarize results for markers
            var summarizedMarkers = [];
            results.forEach(e => {
                // try to find an entry for the exact coordinates
                var foundMarker = summarizedMarkers.find(marker => {
                    return marker.location.lat === e.location.lat && marker.location.lon === e.location.lon && marker.addressText === e.addressText;
                });

                if (!foundMarker) {
                    // we have not found a marker, so we need to add a new one
                    summarizedMarkers.push({
                        location: {
                            lat: e.location.lat,
                            lon: e.location.lon,
                        },
                        addressText: e.addressText,
                        jobOffers: [{
                            company: {
                                id: e.company.id,
                                name: e.company.name,
                                logo: e.company.logo,
                            },
                            jobs: [e]
                        }]
                    });
                }
                else {
                    // we have found a marker already, so we need to check if there is a job offer from the company already
                    var company = foundMarker.jobOffers.find(j => j.company.name === e.company.name);

                    if (!company) {
                        // first job offer for that one company, just add it
                        foundMarker.jobOffers.push({
                            company: {
                                id: e.company.id,
                                name: e.company.name,
                                logo: e.company.logo,
                            },
                            jobs: [e]
                        })
                    }
                    else {
                        // there is job from that company already in the list, just add the job
                        company.jobs.push(e)
                    }
                }
            });

            // set items
            setItems(results);
            setMarkers(summarizedMarkers);
            setItemsTotal(total);
            setOpenedAccordionItems(["results"]);
            setFlyTo([values.location.lon, values.location.lat]);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            keywords: [],
            addressText: "",
            location: {
                lat: null,
                lon: null,
            },
            km: 50,
            workTimeRegulationIds: [],
            jobCategoryIds: [],
            positionLevelIds: [],
            sortField: "created_desc",
        },
        submitCallback: fetchJobs
    });

    /**
     * renderer for markers
     */
    const Markers = memo(({ markers }) => {
        return (
            markers.map(e => (
                <Marker
                    key={`marker_${e.location.lat}_${e.location.lon}`}
                    latitude={e.location.lat}
                    longitude={e.location.lon}
                    onClick={(event) => {
                        event.originalEvent.stopPropagation();
                        setMarkerPopup(e);
                    }}
                />
            ))
        )
    });

    return (
        <Stack>
            <Title>{t("job.find")}</Title>
            <Divider />

            <Accordion
                variant="separated"
                multiple
                value={openedAccordionItems}
                onChange={setOpenedAccordionItems}
                transitionDuration={700}
            >
                <Accordion.Item value="search">
                    <Accordion.Control icon={<Search size={20} />}>{t("search.search")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onSubmit={form.onSubmit()}
                            onReset={form.onReset}
                        >
                            <Stack>
                                <LocationSearchInput
                                    label={t("general.address")}
                                    form={form}
                                    withAsterisk={true}
                                />

                                <SliderInput
                                    name={t("job.distance")}
                                    min={1}
                                    max={500}
                                    labelAlwaysOn
                                    {...form.getInputProps('km')}
                                    marks={[
                                        { value: 1, label: '1' },
                                        { value: 100, label: '100' },
                                        { value: 200, label: '200' },
                                        { value: 300, label: '300' },
                                        { value: 400, label: '400' },
                                        { value: 500, label: '500' },
                                    ]}
                                />

                                <TagsInput 
                                    label={t("job.keywords.keywords")}
                                    placeholder={t("job.keywords.placeholder")}
                                    {...form.getInputProps('keywords')}
                                    description={t("job.keywords.description")}
                                    splitChars={[',', ' ', '|']}
                                />

                                <ModelSelectInput
                                    type={WorkTimeRegulation}
                                    label={t("worktimeregulation.worktimeregulations")}
                                    placeholder={t("worktimeregulation.worktimeregulations")}
                                    {...form.getInputProps('workTimeRegulationIds')}
                                    multiple={true}
                                />

                                <ModelSelectInput
                                    type={JobCategory}
                                    label={t("jobcategory.jobcategories")}
                                    placeholder={t("jobcategory.jobcategories")}
                                    {...form.getInputProps('jobCategoryIds')}
                                    multiple={true}
                                />

                                <ModelSelectInput
                                    type={PositionLevel}
                                    label={t("positionlevel.positionlevel")}
                                    placeholder={t("positionlevel.positionlevel")}
                                    {...form.getInputProps('positionLevelIds')}
                                    multiple={true}
                                />

                                <SortFieldInput
                                    withAsterisk
                                    data={[
                                        { value: 'created_desc', label: t("sort.newest") },
                                        { value: 'created_asc', label: t("sort.oldest") },
                                        { value: 'distance_desc', label: t("sort.closest") },
                                        { value: 'distance_asc', label: t("sort.furthest") },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <Group justify='space-between'>
                                    <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                    <Button leftSection={<Search size={14} />} type="submit" color="green">{t("search.search")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="results" hidden={!items}>
                <Accordion.Control icon={<List size={20} />}>{itemsTotal ? t("job.found.value", { value: itemsTotal }) : t("job.jobs")}</Accordion.Control>
                    <Accordion.Panel>
                        <Grid>
                            <Grid.Col span={{ base: 12, md: 12, lg: 4 }}>
                                <Paper withBorder>
                                    <Container fluid p="xs">
                                        <MapView
                                            style={{
                                                height: "300px",
                                                width: "100%"
                                            }}
                                            initialViewState={initialViewState}
                                            attributionControl={false}
                                            doubleClickZoom={false}
                                            language={["de"]}
                                            locale="de"
                                            preserveDrawingBuffer={true}
                                            id="map"
                                            ref={(ref) => setMap(ref)}
                                            onData={(event) => event.target.resize()}
                                        >
                                            <FullscreenControl
                                                style={{ backgroundColor: "darkGray" }}
                                            />

                                            <GeolocateControl
                                                trackUserLocation={true}
                                                style={{ backgroundColor: "darkGray" }}
                                                fitBoundsOptions={{
                                                    maxZoom: 15,
                                                    zoom: 10
                                                }}
                                            />

                                            <Markers markers={markers} />
                                        </MapView>
                                        <Text size="xs" c="dimmed" mt="xs">{t("job.map_tip")}</Text>
                                    </Container>
                                </Paper>
                            </Grid.Col>

                            <Grid.Col span={{ base: 12, md: 12, lg: 8 }} pb={0}>
                                {items && items.map((job) => (
                                    <Link
                                        to={`${ROUTE_JOBS_SHOW}/${job.id}`}
                                        style={{
                                            textDecoration: "none"
                                        }}
                                        key={job.id}
                                    >
                                        <Card mt={0} mb="xs" p="xs" radius="md" withBorder>
                                            <Job job={job} />
                                        </Card>
                                    </Link>
                                ))}
                                {(items && items.length === 0) &&
                                    <Card mt={0} mb="xs" p="xs" radius="md" withBorder>
                                        <Text size="sm">{t("job.found.none")}</Text>
                                    </Card>
                                }
                            </Grid.Col>
                        </Grid>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            {markerPopup &&
                <Modal
                    opened={true}
                    centered={true}
                    closeOnClickOutside={true}
                    withCloseButton={true}
                    onClose={() => setMarkerPopup(null)}
                    size="auto"
                    title={<BadgeLocation addressText={markerPopup.addressText} />}
                >
                    <ScrollArea>
                        {markerPopup.jobOffers.map((e) => {
                            return (
                                <Stack>
                                    <Card key={e.company.id} shadow="sm" p="xs" radius="md" withBorder>
                                        <Stack>
                                            <Flex gap="md" align="center">
                                                <Avatar
                                                    size={100}
                                                    identityId={e.company.id}
                                                    fileKey={e.company.logo}
                                                    fileProtectionLevel={"protected"}
                                                    placeholder={BuildingSkyscraper}
                                                />
                                                <Link
                                                    to={`${ROUTE_COMPANY_SHOW}/${e.company.id}`}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "unset"
                                                    }}
                                                    key={e.company.id}
                                                >
                                                    <Group gap="xs">
                                                        <Title size="sm">{e.company.name}</Title>
                                                        <ActionIcon
                                                            variant="outline"
                                                            color="blue"
                                                            size={18}
                                                        >
                                                            <ExternalLink />
                                                        </ActionIcon>
                                                    </Group>
                                                </Link>

                                            </Flex>
                                            <Stack gap="xs">
                                                {e.jobs.map(job => {
                                                    return (
                                                        <Link
                                                            href={`${ROUTE_JOBS_SHOW}/${job.id}`}
                                                            style={{
                                                                textDecoration: "none"
                                                            }}
                                                            key={job.id}
                                                        >
                                                            <Card p="xs" radius="md" withBorder>
                                                                <Job job={job} hideCompanyAvatar hideCompanyName hideLocationBadge />
                                                            </Card>
                                                        </Link>
                                                    )
                                                })}
                                            </Stack>
                                        </Stack>
                                    </Card>
                                </Stack>
                            )
                        })}
                    </ScrollArea>
                    <Group mt="md" justify="flex-end">
                        <Button size="compact-sm" onClick={() => setMarkerPopup(null)} variant="outline" color="gray" leftSection={<X size={14} />}>{t("general.close")}</Button>
                    </Group>
                </Modal>
            }
        </Stack>
    )
}