import { DataStore } from "aws-amplify";
import { Button, Grid, Stack } from "@mantine/core";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTE_NOT_FOUND, ROUTE_SIGNIN, ROUTE_USER_APPLICATIONS_NEW } from "../../helpers/Routes";
import { HTML_TITLE, USERGROUP_ADMIN, USERGROUP_SUPPORT, USERGROUP_USER } from "../../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { JobApplication, JobOffer } from "../../models";
import { useUserState } from '../../helpers/GlobalUserState';
import JobDetails from "../../components/JobDetails";
import Job from "../../components/Job";
import AlreadyAppliedAlert from "../../components/AlreadyAppliedAlert";
import { ArrowLeft } from "tabler-icons-react";
import { useTranslation } from 'react-i18next';

/**
 * job detail page
 * @returns JSX
 */
export default function PageGeneralJob() {

    // globals
    const [job, setJob] = useState();
    const { id } = useParams();
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const user = useUserState();
    const location = useLocation();
    const [application, setApplication] = useState(null);
    const { t } = useTranslation();

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        setLoading(LOADING_SHOW);
        try {
            if (user.id) {
                // if there is a user logged in, check if the user already applied to it
                const applications = await DataStore.query(JobApplication, (c) => c.and(c => [
                    c.jobOfferId.eq(id),
                    c.userId.eq(user.id)
                ]));
                if (applications.length > 0) {
                    setApplication(applications[0]);
                }
            }

            // fetch job
            const job = await DataStore.query(JobOffer, id);
            if (job) {
                const company = await job.company;
                const jobCategory = await job.jobCategory;
                const workTimeRegulation = await job.workTimeRegulation;
                const positionLevel = await job.positionLevel;
                setJob({
                    ...job,
                    company,
                    jobCategory,
                    workTimeRegulation,
                    positionLevel
                });
                document.title = `${job.name} - ${company.name} - ${HTML_TITLE}`;
            }
            else {
                navigate(ROUTE_NOT_FOUND);
                return;
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /** 
     * use effect hook to fetch job data
     */
    useEffect(() => {
        // no job offer id provided, redirect to 404
        if (!id) {
            navigate(ROUTE_NOT_FOUND)
        }

        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    /**
     * function to trigger application creation
     */
    const applyToJob = () => {
        // check if user is logged in
        if (!user?.id) {
            navigate(ROUTE_SIGNIN, { state: { from: location } })
        }

        // check if user already applied, cancel
        if (application) {
            return;
        }

        // navigate to application page
        navigate(`${ROUTE_USER_APPLICATIONS_NEW}/${id}`);
    }

    /**
     * apply button wrapper
     * @returns JSX
     */
    const applyButton = () => {
        return (
            <Button
                color="green"
                disabled={application}
                onClick={() => applyToJob()}
            >
                {application ? t("job.already_applied") : t("job.apply")}
            </Button>
        )
    }

    // if job is not fetched, hide page
    if (!job) {
        return null;
    }

    return (
        <Stack>
            {application && <AlreadyAppliedAlert application={application} />}

            <Grid>
                <Grid.Col span="auto">
                    <Job job={job} />
                </Grid.Col>

                {(!user.id || user.userGroup === USERGROUP_USER) &&
                    <Grid.Col span="content">
                        {applyButton()}
                    </Grid.Col>
                }

                {(user.userGroup === USERGROUP_ADMIN || user.userGroup === USERGROUP_SUPPORT || user.companyId) &&
                    <Grid.Col span="content">
                        <Button leftSection={<ArrowLeft size={14} />} color="yellow" onClick={() => navigate(-1)}>{t("general.back")}</Button>
                    </Grid.Col>
                }
            </Grid>

            <JobDetails job={job} />

            {(!user.id || user.userGroup === USERGROUP_USER) &&
                applyButton()
            }
        </Stack>
    )
}