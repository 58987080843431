import { Button, Divider, Group, Stack, Title, Tooltip } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTE_JOBS_SHOW, ROUTE_NOT_FOUND, ROUTE_USER_APPLICATIONS } from "../../helpers/Routes";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { JobApplication, JobOffer, User } from "../../models";
import { DataStore } from "aws-amplify";
import * as Yup from 'yup';
import { Check, File, List, Reload } from 'tabler-icons-react';
import { VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import { showNotification } from "@mantine/notifications";
import { useForm } from "../../components/Form";
import CustomRichTextEditor from "../../components/RichTextEditor";
import { useUserState } from "../../helpers/GlobalUserState";
import JobDetails from "../../components/JobDetails";
import { createApplication } from '../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import Job from "../../components/Job";
import AlreadyAppliedAlert from "../../components/AlreadyAppliedAlert";
import { useTranslation } from 'react-i18next';
import { USERGROUP_USER } from "../../helpers/Constants";

// validation schema with yup
const validationSchema = Yup.object().shape({
    applicationText: VALIDATION_SCHEMA_STRING,
});


/**
 * user page for application creation
 * @returns JSX
 */
export default function PageUserApplicationNew() {

    // globals
    const { id } = useParams();
    const [job, setJob] = useState();
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const user = useUserState();
    const [application, setApplication] = useState(null);
    const { t } = useTranslation();
    const [dataSet, setDataSet] = useState(true);
    const userDataRef = useRef(null);

    /**
     * use effect hook to create subscription for user data
     */
    useEffect(() => {
        if (user?.id && user?.userGroup === USERGROUP_USER) {
            userDataRef.current?.unsubscribe();
            userDataRef.current = DataStore.observeQuery(User, p => p.id.eq(user.id)).subscribe(snapshot => {
                const { items, isSynced } = snapshot;

                // if not synced, discard
                if (!isSynced) {
                    return;
                }

                setDataSet(items.length !== 0);
            });
        }

        // unsubscribe from listeners when unmounting
        return () => {
            userDataRef.current?.unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        // fetch job
        setLoading(LOADING_SHOW);
        try {
            // check if the user already applied to it
            const applications = await DataStore.query(JobApplication, (c) => c.and(c => [
                c.jobOfferId.eq(id),
                c.userId.eq(user.id),
            ]));
            if (applications.length > 0) {
                setApplication(applications[0]);
            }

            // get job
            const job = await DataStore.query(JobOffer, id);
            const company = await job.company;
            const jobCategory = await job.jobCategory;
            const workTimeRegulation = await job.workTimeRegulation;
            const positionLevel = await job.positionLevel;
            setJob({
                ...job,
                company,
                jobCategory,
                workTimeRegulation,
                positionLevel
            });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /** 
     * use effect hook to fetch job data
     */
    useEffect(() => {
        // no job offer id provided, redirect to 404
        if (!id) {
            navigate(ROUTE_NOT_FOUND)
        }

        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    /**
     * submit callback for application form
     * @param {object} values form values
     */
    const submitCallback = async (values) => {
        // check if user data is set
        if (!dataSet) {
            return;
        }

        // check if already applied 
        if (application) {
            return;
        }

        try {
            // set loading
            setLoading(LOADING_SHOW);

            // create application
            await API.graphql(graphqlOperation(createApplication, { jobOfferId: id, applicationText: values.applicationText }));

            // show success
            showNotification({ message: t("application.sent"), color: 'green', icon: <Check /> });

            // navigate to applications
            navigate(ROUTE_USER_APPLICATIONS);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            applicationText: "",
        },
        submitCallback: submitCallback
    });

    // if job is not fetched, hide page
    if (!job) {
        return null;
    }

    return (
        <Stack>
            {application && <AlreadyAppliedAlert application={application} />}

            <Title>{t("application.send")}</Title>
            <Divider />

            <Job job={job} />
            <JobDetails job={job} defaultValue={[]} />
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <CustomRichTextEditor
                        label={t("application.letter")}
                        placeholder={t("application.description")}
                        withAsterisk
                        form={form}
                        contentField="applicationText"
                    />
                    <Divider />

                    <Group justify='space-between'>
                        <Group>
                            <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                            <Link to={`${ROUTE_JOBS_SHOW}/${id}`}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
                        </Group>
                        <Tooltip label={t("user.profile.notset.message")} disabled={dataSet}>
                            <Button
                                leftSection={<File size={14} />}
                                type="submit"
                                color={dataSet ? "green" : "gray"}
                                disabled={application}
                            >
                                {t("job.apply")}
                            </Button>
                        </Tooltip>
                    </Group>
                </Stack>
            </form>

        </Stack>
    )
}