import { Accordion, Button, Divider, Group, Stack, Title, Text, Card } from "@mantine/core";
import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeft, Briefcase, BuildingSkyscraper, InfoCircle, List, StatusChange, TextCaption, TextWrap, UserCircle } from "tabler-icons-react";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import CompanyDetails from "../../components/CompanyDetails";
import Job from "../../components/Job";
import UserDetails from "../../components/UserDetails";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { JobApplication, JobApplicationCompanyData, JobApplicationCompanyDataNote, User } from "../../models";
import { useTranslation } from 'react-i18next';
import BadgeApplicationStatus from "../../components/BadgeApplicationStatus";

// html parser for descriptions
const parseHtml = require('html-react-parser');

/**
 * application detail page for support
 */
export default function PageSupportApplication() {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState();
    const { t } = useTranslation();

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        setLoading(LOADING_SHOW);

        try {
            // check if user id is provided 
            if (!id) {
                throw new Error("no application id provided");
            }

            // get application data
            const application = await DataStore.query(JobApplication, id);
            if (!application) {
                throw new Error("Application not found.");
            }

            const jobApplicationCompanyData = await DataStore.query(JobApplicationCompanyData, id);
            if (!jobApplicationCompanyData) {
                throw new Error("Application company data not found.");
            }

            // get job offer data
            const jobOffer = await application.jobOffer;
            if (!jobOffer) {
                throw new Error("Job offer data for application not found.");
            }

            // fetch subtypes
            const workTimeRegulation = await jobOffer.workTimeRegulation;
            const jobCategory = await jobOffer.jobCategory;
            const positionLevel = await jobOffer.positionLevel;

            // get companyData
            const companyData = await jobOffer.company;
            if (!companyData) {
                throw new Error("Company data for application not found.");
            }

            // get user data
            const user = await DataStore.query(User, application.owner);
            if (!user) {
                throw new Error("User data for job application not found.");
            }

            // get notes
            const notes = await DataStore.query(
                JobApplicationCompanyDataNote,
                c => c.jobApplicationCompanyDataId.eq(id),
                {
                    sort: s => s.createdAt(SortDirection.DESCENDING)
                }
            );

            // set fetched state
            setData({
                application: application,
                jobApplicationCompanyData: jobApplicationCompanyData,
                jobOffer: {
                    ...jobOffer,
                    workTimeRegulation: workTimeRegulation,
                    jobCategory: jobCategory,
                    positionLevel: positionLevel,
                },
                companyData: companyData,
                user: {
                    ...user,
                    email: application.email,
                },
                notes: notes,
            });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    if (!data) {
        return null;
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("application.application")}</Title>
                <Group>
                    <Button leftSection={<ArrowLeft size={14} />} onClick={() => { navigate(-1) }} color="yellow">{t("general.back")}</Button>
                </Group>
            </Group>
            <Divider />

            <Accordion
                multiple={true}
                variant="separated"
                defaultValue={[
                    "status",
                    "job",
                    "user",
                    "company",
                    (data.notes && data.notes.length > 0) ? "notes" : null
                ]}
            >
                <Accordion.Item value="status">
                    <Accordion.Control icon={<StatusChange size={20} />}>{t("general.status")}</Accordion.Control>
                    <Accordion.Panel>
                        <Group gap="sm">
                            <BadgeApplicationStatus application={data.jobApplicationCompanyData} />
                            <BadgeTimestamp label={`${t("application.applied_time")}:`} time={data.jobApplicationCompanyData.createdAt} />
                            <BadgeTimestamp label={`${t("date.change_time")}:`} time={data.jobApplicationCompanyData.updatedAt} />
                        </Group>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="job">
                    <Accordion.Control icon={<Briefcase size={20} />}>{t("job.job")}</Accordion.Control>
                    <Accordion.Panel>
                        <Job job={data.jobOffer} hideCompanyName hideCompanyAvatar />
                        <Accordion variant="separated" mt="md">
                            <Accordion.Item value="jobdescription">
                                <Accordion.Control icon={<TextWrap size={20} />}>{t("job.description")}</Accordion.Control>
                                <Accordion.Panel>
                                    {parseHtml(data.jobOffer.description)}
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="user">
                    <Accordion.Control icon={<UserCircle size={20} />}>{t("application.applicant")}</Accordion.Control>
                    <Accordion.Panel>
                        <UserDetails userData={data.application} size={100} />
                        <Accordion variant="separated" mt="md">
                            <Accordion.Item value="applicationtext">
                                <Accordion.Control icon={<TextCaption size={20} />}>{t("application.letter")}</Accordion.Control>
                                <Accordion.Panel>
                                    {parseHtml(data.application.text)}
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="company">
                    <Accordion.Control icon={<BuildingSkyscraper size={20} />}>{t("company.company")}</Accordion.Control>
                    <Accordion.Panel>
                        <CompanyDetails company={data.companyData} />
                        <Accordion variant="separated" mt="md">
                            <Accordion.Item value="companyDescription">
                                <Accordion.Control disabled={!data.companyData.description} icon={<InfoCircle size={20} />}>{t("company.description.description")}</Accordion.Control>
                                <Accordion.Panel>
                                    {data.companyData.description &&
                                        parseHtml(data.companyData.description)
                                    }
                                </Accordion.Panel>
                            </Accordion.Item>
                        </Accordion>
                    </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item value="notes">
                    <Accordion.Control disabled={!(data.notes && data.notes.length > 0)} icon={<List size={20} />}>{t("application.notes.notes")}</Accordion.Control>
                    <Accordion.Panel>
                        {data.notes && data.notes.map((e) => {
                            return (
                                <Card mb="xs" p="xs" radius="md" withBorder key={e.id}>
                                    <Stack>
                                        <Text size="sm">{e.text}</Text>
                                        <Group>
                                            <BadgeTimestamp label={`${t("date.create_time")}:`} time={e.createdAt} />
                                            <BadgeTimestamp label={`${t("date.change_time")}:`} time={e.updatedAt} />
                                        </Group>
                                    </Stack>
                                </Card>
                            )
                        })}
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    )
}