import { Text, Button, Center, Stack, Title, Paper, TextInput, Container } from "@mantine/core";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
import { showNotification } from '@mantine/notifications';
import { Check, X } from "tabler-icons-react";
import { Auth } from 'aws-amplify';
import { VALIDATION_SCHEMA_EMAIL } from "../../helpers/Validation";
import { ROUTE_PASSWORD_FORGOT_CONFIRM, ROUTE_SIGNIN } from "../../helpers/Routes";
import { useForm } from "../../components/Form";
import { useTranslation } from 'react-i18next';

// validation schema with yup
const validationSchema = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL,
});

/**
 * component to handle reset password
 * @returns JSX
 */
export default function PageGeneralResetPassword() {

    // globals
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();

    // submit callback
    const submitCallback = async (values) => {
        try {
            await Auth.forgotPassword(values.email.toLowerCase());
            showNotification({ message: t("auth.confirmation.code.sent"), color: 'green', icon: <Check /> });
            navigate(ROUTE_PASSWORD_FORGOT_CONFIRM, { state: { email: values.email }});
        }
        catch (e) {
            if (e.code === "UserNotFoundException") {
                showNotification({ message: t("auth.error.notfound"), color: 'red', icon: <X /> });
            }
            else if (e.code === "LimitExceededException") {
                showNotification({ message: t("auth.error.max_attempts_reached"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            email: location.state?.email ? location.state.email : "",
        },
        submitCallback: submitCallback
    });

    return (
        <Center style={{ minHeight: "100dvh" }}>
            <Container style={{ width: "600px" }}>
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Paper withBorder shadow="md" p="md" radius="md">
                        <Title align="center">{t("auth.password.forgot")}</Title>
                        <Link to={ROUTE_SIGNIN} state={{ email: form.values.email }}>
                            <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" mb="md">
                                {t("auth.signin.navigate")}
                            </Text>
                        </Link>
                        <Stack>
                            <TextInput
                                withAsterisk
                                label={t("general.email")}
                                placeholder="name@email.com"
                                {...form.getInputProps('email')}
                                disabled={location.state?.email}
                            />
                            <Button type="submit" fullWidth mt="md">{t("auth.password.forgot")}</Button>
                        </Stack>
                    </Paper>
                </form>
            </Container>
        </Center>
    );
}