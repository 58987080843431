import { Button, Center, Group, Loader, Modal, Paper, PasswordInput, Stack, Stepper, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "./Form";
import { VALIDATION_SCHEMA_STRING } from "../helpers/Validation";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Check, X } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import QRCodeCanvas from 'qrcode.react';
import { HTML_TITLE, MFA_NONE, MFA_TOTP } from "../helpers/Constants";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";

// validation schema with yup
const validationSchema = Yup.object().shape({
    code: VALIDATION_SCHEMA_STRING
});

/**
 * modal to activate MFA
 * @param {boolean} opened flag if modal is opened 
 * @param {callback} closeCallback callback on close
 * @returns JSX
 */
export default function ModalActivateMfa({ opened, closeCallback }) {

    // globals
    const { t } = useTranslation();
    const [modalOpened, setModalOpened] = useState(opened);
    const [activeStep, setActiveStep] = useState(0);
    const setLoading = useLoadingDispatch();
    const [authenticatorCode, setAuthenticatorCode] = useState(null);
    const setError = useErrorDispatch();

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        if (modalOpened === false && opened === true) {
            getAuthenticatorCode();
        }
        setModalOpened(opened);
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [opened]
    );

    /**
     * wrapper to get authenticator code
     */
    const getAuthenticatorCode = async () => {
        try {
            setLoading(LOADING_SHOW);
            const user = await Auth.currentAuthenticatedUser();
            const result = await Auth.setupTOTP(user);
            setAuthenticatorCode({
                code: result,
                // JP-6 add icon to totp QR code
                qr: "otpauth://totp/" + HTML_TITLE + ":" + user.attributes.email + "?secret=" + result + "&issuer=" + HTML_TITLE,
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * implementation of close to call close callback if set
     */
    const close = (mfaMode) => {
        if (closeCallback) {
            closeCallback(mfaMode);
        }
        else {
            setModalOpened(false);
        }

        form.reset();
        setAuthenticatorCode(null);
        setActiveStep(0);
    }

    /**
     * submit callback for change email step
     * @param {object} values form values
     */
    const submitCallback = async (values) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.verifyTotpToken(user, values.code);
            await Auth.setPreferredMFA(user, MFA_TOTP);
            showNotification({ message: t("auth.mfa.activated"), color: 'green', icon: <Check /> });
            close(MFA_TOTP);
        } catch (e) {
            if (e.code === "InvalidParameterException" || e.code === "EnableSoftwareTokenMFAException") {
                showNotification({ message: t("auth.error.mfa"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form for change email step
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            code: "",
        },
        submitCallback: submitCallback
    });

    return (
        <Modal opened={modalOpened} onClose={() => close(MFA_NONE)} title={t("auth.mfa.configure")} centered size="xl">
            <Stepper active={activeStep}>
                <Stepper.Step label={t("auth.mfa.app")}>
                    <Stack>
                        <Center><Text ta="center" size="sm">{t("auth.mfa.description_step_1")}</Text></Center>

                        <Paper withBorder p="md">
                            <Stack>
                                {authenticatorCode ?
                                    <>
                                        <Center><QRCodeCanvas value={authenticatorCode.qr} size={200} /></Center>
                                        <Text size="xs" ta="center" lineClamp={5} style={{ lineBreak: "anywhere" }}>{authenticatorCode.code}</Text>
                                    </>
                                    :
                                    <>
                                        <Center><Loader color="blue" size={200} /></Center>
                                        <Center><Text size="xs">...</Text></Center>
                                    </>
                                }
                            </Stack>
                        </Paper>

                        <Group mt="sm" justify="space-between">
                            <Button color="yellow" type="button" onClick={() => close(MFA_NONE)}>{t("general.cancel")}</Button>
                            <Button color="green" onClick={() => setActiveStep(1)}>{t("general.next")}</Button>
                        </Group>
                    </Stack>
                </Stepper.Step>
                <Stepper.Step label={t("auth.mfa.verify")}>
                    <form
                        onSubmit={form.onSubmit()}
                        onReset={form.onReset}
                    >
                        <Stack>
                            <Center><Text ta="center" size="sm">{t("auth.mfa.description_step_2")}</Text></Center>
                            <PasswordInput
                                withAsterisk
                                label={t("general.code")}
                                placeholder={t("general.code")}
                                {...form.getInputProps('code')}
                                description={t("auth.mfa.code")}
                            />
                            <Group mt="sm" justify="space-between">
                                <Button color="yellow" type="button" onClick={() => setActiveStep(0)}>{t("general.back")}</Button>
                                <Button color="green" type="submit">{t("general.verify")}</Button>
                            </Group>
                        </Stack>
                    </form>
                </Stepper.Step>
            </Stepper>
        </Modal>
    )
}