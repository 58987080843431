import { useForm as useFormMantine, yupResolver } from "@mantine/form";
import { showNotification } from '@mantine/notifications';
import { X } from "tabler-icons-react";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import React, { useCallback, useEffect, useRef } from "react";
import { List, Title } from "@mantine/core";
import { useTranslation } from "react-i18next";
import i18next from "../helpers/i18n";

export function useForm(props) {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const additionalOnReset = useRef([]);
    const additionalOnSubmit = useRef([]);
    const { t } = useTranslation();

    // initial values 
    var initialValues = props.initialValues;

    /**
     * handles the submit of the form
     * @param {object} values the values as js object
     */
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);

            // call registered reset callbacks
            for (const cb of additionalOnSubmit.current) {
                await cb()
            }

            await props.submitCallback(values);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * handles the form validation errors
     * @param {array} errors array with errors
     */
    const handleError = (errors) => {
        var errorTexts = [];
        Object.keys(errors).forEach(key => {
            if (errorTexts.findIndex((e) => e === errors[key]) === -1) {
                errorTexts.push(errors[key]);
            }
        });

        if (errorTexts.length > 0) {
            const errorComponents = errorTexts.map((e, i) => {
                return <List.Item key={i}>{e}</List.Item>
            });
            showNotification({
                message: (
                    <>
                        <Title order={6} mb={5}>{t("validation.title")}</Title>
                        <List size="sm" mr="md">{errorComponents}</List>
                    </>
                ),
                color: 'red',
                icon: <X />
            });
        }
        else {
            setError({ action: ERROR_SHOW, error: new Error(t("error.unexpected")) });
        }
    };

    /**
     * construct form
     */
    const form = useFormMantine({
        validate: props.validationSchema ? yupResolver(props.validationSchema) : null,
        initialValues: props.initialValues,
    });

    /**
     * Use effect hook to list for language change
     */
    useEffect(() => {
        i18next.on('languageChanged', () => {
            // if the language changed and we have errors already, we need to validate again
            // only that way we call the yup validation again, and get new translated error texts
            if (Object.keys(form.errors).length > 0) {
                form.validate();
            }
        });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [form]
    );

    // add submit callback
    form.onSubmit = (handleSubmit, handleValidationFailure) => async (event) => {
        event?.preventDefault();
        const results = form.validate();

        if (results.hasErrors) {
            handleValidationFailure ?
                handleValidationFailure(results.errors, form.values, event)
                :
                handleError(results.errors, form.values, event);
        } else {
            handleSubmit ?
                handleSubmit(form.values, event)
                :
                await submitCallback(form.values, event);
        }
    };

    /**
     * sets new inital values for async loading
     * @param {object} values values to set
     */
    form.setInitialValues = (values) => {
        initialValues = values;
        form.setValues(initialValues);
    }

    /**
     * the reset handler
     */
    form.onReset = useCallback(() => {
        form.setValues(initialValues);
        form.clearErrors();
        form.resetTouched();
        if (props.resetCallback) {
            props.resetCallback();
        }

        // call registered reset callbacks
        additionalOnReset.current.forEach(cb => cb());
    },
        // eslint-disable-next-line
        []
    );

    /**
     * registers an additional on reset callback
     * @param {function} cb cb to register
     */
    form.registerAdditionalOnReset = (cb) => {
        additionalOnReset.current.push(cb);
    }

    /**
     * unregisters an additional on reset callback
     * @param {function} cb cb to unregister
     */
    form.unregisterAdditionalOnReset = (cb) => {
        const cbIndex = additionalOnReset.current.findIndex(e => e === cb);
        if (cbIndex > -1) {
            additionalOnReset.current.splice(cbIndex, 1);
        }
    }

    /**
     * registers an additional on submit callback
     * @param {function} cb cb to register
     */
    form.registerAdditionalOnSubmit = (cb) => {
        additionalOnSubmit.current.push(cb);
    }

    /**
     * unregisters an additional on submit callback
     * @param {function} cb cb to unregister
     */
    form.unregisterAdditionalOnSubmit = (cb) => {
        const cbIndex = additionalOnSubmit.current.findIndex(e => e === cb);
        if (cbIndex > -1) {
            additionalOnSubmit.current.splice(cbIndex, 1);
        }
    }

    // return the hook
    return form;
}