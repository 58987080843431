import { Button, Group, Modal, Stack, Stepper, Text, TextInput } from "@mantine/core";
import { useEffect, useState } from "react";
import { useForm } from "./Form";
import { VALIDATION_SCHEMA_EMAIL, VALIDATION_SCHEMA_STRING } from "../helpers/Validation";
import * as Yup from "yup";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { Check, X } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { USER_SET, useUserDispatch } from "../helpers/GlobalUserState";

// validation schema with yup
const validationSchemaChangeEmail = Yup.object().shape({
    email: VALIDATION_SCHEMA_EMAIL
});
const validationSchemaVerifyEmail = Yup.object().shape({
    code: VALIDATION_SCHEMA_STRING
});

/**
 * modal to change email
 * @param {boolean} opened flag if modal is opened 
 * @param {callback} closeCallback call back on close
 * @returns JSX
 */
export default function ModalChangeEmail({ opened, closeCallback }) {

    // globals
    const { t } = useTranslation();
    const [modalOpened, setModalOpened] = useState(opened);
    const [activeStep, setActiveStep] = useState(0);
    const setUser = useUserDispatch();

    /**
     * use effect hook to initially fetch data
     */
    useEffect(() => {
        setModalOpened(opened);
    },
        [opened]
    );

    /**
     * implementation of close to call close callback if set
     */
    const close = () => {
        if (closeCallback) {
            closeCallback();
        }
        else {
            setModalOpened(false);
        }

        formChangeEmail.reset();
        formVerifyEmail.reset();
        setActiveStep(0);
    }

    /**
     * submit callback for change email step
     * @param {object} values form values
     */
    const submitCallbackChangeEmail = async (values) => {
        // get current user
        const user = await Auth.currentAuthenticatedUser();

        // show custom error if email is the same
        if (user.attributes.email === values.email) {
            showNotification({ message: t("auth.update.email.current"), color: 'green', icon: <Check /> });
            close();
            return;
        }

        // request change
        const result = await Auth.updateUserAttributes(user, {
            email: values.email
        });

        // check if update was triggered successfully
        if (result !== "SUCCESS") {
            throw new Error(t("error.unexpected"));
        }

        // change step to verify
        showNotification({ message: t("auth.confirmation.code.sent"), color: 'green', icon: <Check /> });
        setActiveStep(1);
    }

    /**
     * submit callback for verify step
     * @param {object} values form values
     */
    const submitCallbackVerifyEmail = async (values) => {
        try {
            // confirm update
            await Auth.verifyCurrentUserAttributeSubmit('email', values.code);

            // update internal user state
            setUser({
                action: USER_SET,
                values: { email: formChangeEmail.values.email }
            });

            // close modal
            close();
        }
        catch (e) {
            if (e.code === "AliasExistsException") {
                showNotification({ message: t("auth.account.exists"), color: 'red', icon: <X /> });
                close();
            }
            else if (e.code === "CodeMismatchException") {
                showNotification({ message: t("auth.confirmation.code.invalid"), color: 'red', icon: <X /> });
            }
            else if (e.code === "LimitExceededException") {
                showNotification({ message: t("auth.error.max_attempts_reached"), color: 'red', icon: <X /> });
            }
            else if (e.code === "ExpiredCodeException") {
                showNotification({ message: t("auth.confirmation.code.expired"), color: 'red', icon: <X /> });
            }
            else {
                throw e;
            }
        }
    }

    // form for change email step
    const formChangeEmail = useForm({
        validationSchema: validationSchemaChangeEmail,
        initialValues: {
            email: "",
        },
        submitCallback: submitCallbackChangeEmail
    });

    // form for verify email step
    const formVerifyEmail = useForm({
        validationSchema: validationSchemaVerifyEmail,
        initialValues: {
            code: "",
        },
        submitCallback: submitCallbackVerifyEmail
    });

    return (
        <Modal opened={modalOpened} onClose={close} title={t("auth.update.email.change")} centered size="lg">
            <Stepper active={activeStep}>
                <Stepper.Step label={t("auth.update.email.new")}>
                    <form
                        onSubmit={formChangeEmail.onSubmit()}
                        onReset={formChangeEmail.onReset}
                    >
                        <Stack>
                            <TextInput
                                withAsterisk
                                label={t("auth.update.email.new")}
                                placeholder={t("auth.update.email.new")}
                                {...formChangeEmail.getInputProps('email')}
                                description={t("auth.update.email.description")}
                            />
                            <Group mt="sm" justify="space-between">
                                <Button color="yellow" type="button" onClick={() => close()}>{t("general.cancel")}</Button>
                                <Button color="green" type="submit">{t("general.change")}</Button>
                            </Group>
                        </Stack>
                    </form>
                </Stepper.Step>
                <Stepper.Step label={t("auth.update.email.verify")}>
                    <form
                        onSubmit={formVerifyEmail.onSubmit()}
                        onReset={formVerifyEmail.onReset}
                    >
                        <Stack>
                            <div>
                                <TextInput
                                    withAsterisk
                                    label={t("general.code")}
                                    placeholder={t("general.code")}
                                    {...formVerifyEmail.getInputProps('code')}
                                    description={t("auth.update.code.description")}
                                />
                                <Group justify="flex-end" mt={5}>
                                    <Text className="pointer" td="underline" c="dimmed" size="sm" align="center" onClick={() => submitCallbackChangeEmail(formChangeEmail.values)}>
                                        {t("auth.confirmation.code.request")}
                                    </Text>
                                </Group>
                            </div>
                            <Group mt="sm" justify="space-between">
                                <Button color="yellow" type="button" onClick={() => setActiveStep(0)}>{t("general.back")}</Button>
                                <Button color="green" type="submit">{t("general.verify")}</Button>
                            </Group>
                        </Stack>
                    </form>
                </Stepper.Step>
            </Stepper>
        </Modal>
    )
}