import { Accordion, Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { SortDirection } from "aws-amplify";
import { Filter, Reload } from "tabler-icons-react";
import { useForm } from "../../components/Form";
import List, { COLUMN_TYPE_DATE, COLUMN_TYPE_TIMESTAMP } from "../../components/List";
import SortDirectionInput from "../../components/SortDirectionInput";
import SortFieldInput from "../../components/SortFieldInput";
import { ROUTE_SUPPORT_USERS_EDIT, ROUTE_SUPPORT_USERS_SHOW } from "../../helpers/Routes";
import { PREDICATE_CONTAINS } from "../../helpers/Constants";
import { getFilterPredicates } from "../../helpers/Predicates";
import { User } from "../../models";
import { useTranslation } from 'react-i18next';

/**
 * support page to list users
 */
export default function PageSupportUsers() {

    // globals
    const { t } = useTranslation();

    // form hook
    const form = useForm({
        initialValues: {
            name: "",
            sortField: "name",
            sortDirection: SortDirection.ASCENDING,
        }
    });

    /**
     * gets the filter for the list
     * @returns filter predicate
     */
    const getFilter = () => {
        return getFilterPredicates([
            { key: "name", value: form.values.name, type: PREDICATE_CONTAINS },
        ]);
    }

    return (
        <Stack>
            <Title>{t("user.users")}</Title>
            <Divider />

            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("general.name")}
                                    placeholder={t("general.name")}
                                    {...form.getInputProps('name')}
                                />

                                <SortFieldInput
                                    data={[
                                        { value: 'name', label: t("general.name") },
                                        { value: 'createdAt', label: t("date.create_time") },
                                        { value: 'updatedAt', label: t("date.change_time") },
                                    ]}
                                    {...form.getInputProps('sortField')}
                                />

                                <SortDirectionInput
                                    {...form.getInputProps('sortDirection')}
                                />

                                <Group justify='space-between'>
                                    <Button variant="outline" leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <List
                type={User}
                id="id"
                dataStructure={[
                    { key: "name", title: t("general.name") },
                    { key: "addressText", title: t("general.address") },
                    { key: "birthdate", title: t("date.birth"), type: COLUMN_TYPE_DATE },
                    { key: "createdAt", title: t("date.create_time"), type: COLUMN_TYPE_TIMESTAMP },
                    { key: "updatedAt", title: t("date.change_time"), type: COLUMN_TYPE_TIMESTAMP },
                ]}
                showRoute={ROUTE_SUPPORT_USERS_SHOW}
                editRoute={ROUTE_SUPPORT_USERS_EDIT}
                filter={getFilter()}
                sortField={form.values.sortField}
                sortDirection={form.values.sortDirection}
            />
        </Stack>
    )
}