import { Button, Divider, Group, Stack, TextInput, Title } from "@mantine/core";
import { useForm } from "../../components/Form";
import * as Yup from 'yup';
import { VALIDATION_SCHEMA_BOOL, VALIDATION_SCHEMA_STRING } from "../../helpers/Validation";
import { DataStore } from '@aws-amplify/datastore';
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useEffect } from "react";
import { CompanyUser } from "../../models";
import { ArrowLeft, Check, File, Reload } from "tabler-icons-react";
import { useUserState } from "../../helpers/GlobalUserState";
import { showNotification } from "@mantine/notifications";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FILE_TYPE_IMAGE, USERGROUP_ADMIN, USERGROUP_SUPPORT } from "../../helpers/Constants";
import { ROUTE_COMPANY_USER_PROFILE, ROUTE_SUPPORT_COMPANIES_SHOW } from "../../helpers/Routes";
import { useTranslation } from "react-i18next";
import CheckboxInput from "../../components/CheckboxInput";
import FileInput from "../../components/FileInput";

// validation schema with yup
const validationSchema = Yup.object().shape({
    name: VALIDATION_SCHEMA_STRING,
    applicationMailNotification: VALIDATION_SCHEMA_BOOL
});

/**
 * company user profile edit page
 * @returns JSX
 */
export default function PageCompanyUserProfileEdit() {

    // globals
    const setError = useErrorDispatch();
    const user = useUserState();
    const setLoading = useLoadingDispatch();
    const { userId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            // get item
            const item = await DataStore.query(CompanyUser, userId ? userId : user.id);
            if (!item) {
                // company user data not found
                throw new Error(t("error.user"));
            }

            // set data
            form.setInitialValues({
                id: item.id,
                name: item.name,
                companyId: item.companyId,
                avatar: item.avatar,
                applicationMailNotification: item.applicationMailNotification
            });
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        preparePage().finally(() => {
            setLoading(LOADING_RESET);
        });
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    // submit callback
    const submitCallback = async (values) => {
        try {
            setLoading(LOADING_SHOW);
            const original = await DataStore.query(CompanyUser, values.id);
            await DataStore.save(CompanyUser.copyOf(original, updated => {
                updated.name = values.name;
                updated.avatar = values.avatar;
                updated.applicationMailNotification = values.applicationMailNotification
            }));
            showNotification({ message: t("general.saved"), color: 'green', icon: <Check /> });

            if (user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) {
                navigate(-1);
            }
            else {
                navigate(ROUTE_COMPANY_USER_PROFILE);
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            id: "",
            name: "",
            avatar: null,
            applicationMailNotification: true,
        },
        submitCallback: submitCallback
    });

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{userId ? form?.values?.name : t("user.profile.profile")}</Title>
                {(user.userGroup === USERGROUP_ADMIN || user.userGroup === USERGROUP_SUPPORT) &&
                    <Link to={`${ROUTE_SUPPORT_COMPANIES_SHOW}/${form?.values?.companyId}`}><Button leftSection={<ArrowLeft size={14} />} color="yellow">{t("general.back")}</Button></Link>
                }
            </Group>
            <Divider />

            {form?.values?.id &&
                <form
                    onSubmit={form.onSubmit()}
                    onReset={form.onReset}
                >
                    <Stack>
                        <TextInput
                            withAsterisk
                            label={t("user.name.your")}
                            placeholder={t("user.name.your")}
                            {...form.getInputProps('name')}
                        />
                        <FileInput
                            label={t("user.picture.picture")}
                            fileProtectionLevel="private"
                            fileType={FILE_TYPE_IMAGE}
                            initFiles={form.values.avatar}
                            description={t("user.picture.description")}
                            identityId={form.values.companyId}
                            form={form}
                            path="avatar"
                        />
                        <CheckboxInput
                            label={t("application.notification.label")}
                            description={t("application.notification.description")}
                            {...form.getInputProps('applicationMailNotification', { type: 'checkbox' })}
                        />
                        <Divider />

                        <Group justify='space-between'>
                            <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                            <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.save")}</Button>
                        </Group>
                    </Stack>
                </form>
            }
        </Stack>
    )
}