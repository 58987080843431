/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://8m12yb2m4l.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        },
        {
            "name": "PaymentQueries",
            "endpoint": "https://kxzyygu593.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        },
        {
            "name": "StripeWebhook",
            "endpoint": "https://1kmcduck8g.execute-api.eu-central-1.amazonaws.com/test",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://nohspdksozajtcdcghoozwy64i.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:bf219b1e-7ce7-4559-8f5c-653ab2503171",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_0IAd05ujH",
    "aws_user_pools_web_client_id": "136ngkterkeig78cgrtgi3l624",
    "oauth": {
        "domain": "unitedtalents24-test.auth.eu-central-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/,https://app.unitedtalents24.com/",
        "redirectSignOut": "http://localhost:3000/,https://app.unitedtalents24.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "jobportald6cd4732a4df4e0b96d4e197b7ae4802eb038-test",
    "aws_user_files_s3_bucket_region": "eu-central-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-central-1",
            "maps": {
                "items": {
                    "map66aff1c4-test": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "map66aff1c4-test"
            },
            "search_indices": {
                "items": [
                    "placeindexb97a0d73-test"
                ],
                "default": "placeindexb97a0d73-test"
            }
        }
    }
};


export default awsmobile;
