import { Accordion, ActionIcon, Button, Group, Menu, Select, Stack, TextInput, Table, UnstyledButton, Paper, Center } from "@mantine/core";
import { Check, Edit, Filter, Reload, Search, Settings, Star, StarOff, UserCheck, UserOff } from "tabler-icons-react";
import { OPTIONS_YESNO_WITHALL, OPTION_ALL, OPTION_NO, OPTION_YES, TABLE_ACTION_SIZE, TABLE_ACTION_WIDTH, USERGROUPS_ALL, USERGROUP_ADMIN, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_SUPPORT } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL, VALIDATION_SCHEMA_USERGROUP_COMPANY_WITHALL } from "../helpers/Validation";
import * as Yup from 'yup';
import { useForm } from "../components/Form";
import { showNotification } from "@mantine/notifications";
import { executeApiCall, ADMIN_API_COMPANY_SETUSERADMIN, ADMIN_API_COMPANY_SETUSERENABLED, API_MODE_POST, ADMIN_API_SUPPORT_DISABLEUSER, ADMIN_API_SUPPORT_ENABLEUSER, ADMIN_API_SUPPORT_SETCOMPANYADMIN, ADMIN_API_NAME } from "../helpers/APIHelper";
import { formatDate } from "../helpers/Moment";
import { useEffect, useRef, useState } from "react";
import { useUserState } from "../helpers/GlobalUserState";
import { Link } from "react-router-dom";
import { ROUTE_SUPPORT_COMPANIES_USER_EDIT } from "../helpers/Routes";
import { useTranslation } from 'react-i18next';

// initial values for user filter
const initialValuesUserFilter = {
    name: "",
    email: "",
    enabled: OPTION_ALL,
    group: USERGROUPS_ALL,
};

// validation schema with yup for user filter
const validationSchemaUserFilter = Yup.object().shape({
    enabled: VALIDATION_SCHEMA_OPTIONS_YESNO_WITHALL,
    group: VALIDATION_SCHEMA_USERGROUP_COMPANY_WITHALL,
});

/**
 * component to render a user list for a company
 * @returns JSX
 */
export default function CompanyUsersList(props) {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [filteredUsers, setFilteredUsers] = useState([]);
    const usersRef = useRef([]);
    const user = useUserState();
    const { t } = useTranslation();

    /**
     * Use effect hook to set refs
     */
    useEffect(() => {
        usersRef.current = props.users;
        filterUsers();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.users]
    );

    /**
     * filters users depending on set filter in filter form
     */
    const filterUsers = () => {
        // start with all users
        var filteredUsers = [...usersRef.current];
        const values = form.values;

        // filter name
        if (values.name) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.name.toLowerCase().includes(values.name.toLowerCase());
            });
        }

        // filter email
        if (values.email) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.email.toLowerCase().includes(values.email.toLowerCase());
            });
        }

        // filter enabled state
        if (values.enabled === OPTION_YES) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === true;
            });
        }
        else if (values.enabled === OPTION_NO) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.enabled === false;
            });
        }

        // filter groups
        if (values.group === USERGROUP_COMPANYADMIN) {
            filteredUsers = filteredUsers.filter((e) => {
                return e.groups.includes(USERGROUP_COMPANYADMIN) === true;
            });
        }
        else if (values.group === USERGROUP_COMPANY) {
            filteredUsers = filteredUsers.filter((e) => {
                return !e.groups.includes(USERGROUP_COMPANYADMIN) === true;
            });
        }

        setFilteredUsers(filteredUsers);
    }

    /**
     * changes the admin status of a user
     * @param {string}  username  username to update
     * @param {boolean} admin     admin status
     */
    const changeAdminPermission = async (username, admin) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // set new status
            await executeApiCall(
                ADMIN_API_NAME,
                API_MODE_POST,
                user.userGroup === USERGROUP_COMPANYADMIN ? ADMIN_API_COMPANY_SETUSERADMIN : ADMIN_API_SUPPORT_SETCOMPANYADMIN,
                {
                    "username": username,
                    "admin": admin,
                }
            )

            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                if (admin) {
                    newUsers[index].groups.push(USERGROUP_COMPANYADMIN);
                }
                else {
                    newUsers[index].groups = [USERGROUP_COMPANY];
                }
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            if (admin) {
                showNotification({ message: t("user.permissions.admin_added"), color: 'green', icon: <Check /> });
            } else {
                showNotification({ message: t("user.permissions.admin_removed"), color: 'green', icon: <Check /> });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * changes the users enabled status
     * @param {string}  username    username to update
     * @param {boolean} enabled     enabled status
     */
    const changeUserEnabled = async (username, enabled) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // update user
            if (user.userGroup === USERGROUP_COMPANYADMIN) {
                await executeApiCall(ADMIN_API_NAME, API_MODE_POST, ADMIN_API_COMPANY_SETUSERENABLED, {
                    "username": username,
                    "enabled": enabled,
                })
            }
            else {
                await executeApiCall(
                    ADMIN_API_NAME,
                    API_MODE_POST,
                    enabled ? ADMIN_API_SUPPORT_ENABLEUSER : ADMIN_API_SUPPORT_DISABLEUSER,
                    {
                        "username": username,
                    }
                )
            }

            // update local data
            var newUsers = [...usersRef.current];
            var index = newUsers.findIndex((e) => e.username === username);
            if (index > -1) {
                newUsers[index].enabled = enabled;
            }
            usersRef.current = newUsers;
            filterUsers();

            // show notification
            showNotification({ message: enabled ? t("user.status.activated") : t("user.status.deactivated"), color: 'green', icon: <Check /> });

        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * wrapper to map users in table
     */
    const rows = filteredUsers.map((element) => {
        return (
            <Table.Tr key={element.username}>
                <Table.Td
                    style={{
                        padding: 5,
                        width: TABLE_ACTION_WIDTH
                    }}
                >
                    <Menu position="right-start" withinPortal>
                        <Menu.Target>
                            <Center><ActionIcon size={TABLE_ACTION_SIZE} variant="outline"><Settings /></ActionIcon></Center>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {element.enabled === true ?
                                <Menu.Item
                                    leftSection={<UserOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, false)}
                                >
                                    {t("user.status.deactivate")}
                                </Menu.Item>
                                :
                                <Menu.Item
                                    leftSection={<UserCheck size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeUserEnabled(element.username, true)}
                                >
                                    {t("user.status.activate")}
                                </Menu.Item>
                            }

                            {element.groups.includes(USERGROUP_COMPANYADMIN) ?
                                <Menu.Item
                                    leftSection={<StarOff size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, false)}
                                >
                                    {t("user.permissions.admin_remove")}
                                </Menu.Item>
                                :
                                <Menu.Item
                                    leftSection={<Star size={14} />}
                                    component={UnstyledButton}
                                    onClick={() => changeAdminPermission(element.username, true)}
                                >
                                    {t("user.permissions.admin_add")}
                                </Menu.Item>
                            }
                            {(user.userGroup === USERGROUP_ADMIN || user.userGroup === USERGROUP_SUPPORT) &&
                                <Menu.Item
                                    leftSection={<Edit size={14} />}
                                    component={Link}
                                    to={`${ROUTE_SUPPORT_COMPANIES_USER_EDIT}/${element.username}`}
                                >
                                    {t("general.edit")}
                                </Menu.Item>
                            }
                        </Menu.Dropdown>
                    </Menu>
                </Table.Td>
                <Table.Td>{element.name}</Table.Td>
                <Table.Td>{element.email}</Table.Td>
                <Table.Td>{`${element.enabled ? t("general.yes") : t("general.no")}`}</Table.Td>
                <Table.Td>{`${element.groups.includes(USERGROUP_COMPANYADMIN) ? t("user.group.admin") : t("user.group.user")}`}</Table.Td>
                <Table.Td>{formatDate(element.createdAt, "L LT")}</Table.Td>
                <Table.Td>{formatDate(element.updatedAt, "L LT")}</Table.Td>
            </Table.Tr>
        )
    });

    /**
     * submit callback for user filter form
     * @param {object} values form values
     */
    const filterUserSubmitCallback = async () => {
        try {
            setLoading(LOADING_SHOW);
            filterUsers();

        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * reset callback for user filter
     */
    const resetUserFilterCallback = () => {
        filterUsers();
    }

    // form hook for user filter
    const form = useForm({
        validationSchema: validationSchemaUserFilter,
        initialValues: initialValuesUserFilter,
        submitCallback: filterUserSubmitCallback,
        resetCallback: resetUserFilterCallback,
    });

    return (
        <Stack>
            <Accordion variant="separated">
                <Accordion.Item value="filter">
                    <Accordion.Control icon={<Filter />}>{t("general.filter")}</Accordion.Control>
                    <Accordion.Panel>
                        <form
                            onSubmit={form.onSubmit()}
                            onReset={form.onReset}
                        >
                            <Stack>
                                <TextInput
                                    label={t("general.name")}
                                    placeholder={t("general.name")}
                                    {...form.getInputProps('name')}
                                />

                                <TextInput
                                    label={t("general.email")}
                                    placeholder={t("general.email")}
                                    {...form.getInputProps('email')}
                                />

                                <Select
                                    withAsterisk
                                    label={t("general.active")}
                                    placeholder="..."
                                    data={OPTIONS_YESNO_WITHALL}
                                    {...form.getInputProps('enabled')}
                                />

                                <Select
                                    withAsterisk
                                    label={t("user.permissions.permissions")}
                                    placeholder={t("user.permissions.permissions")}
                                    data={[
                                        { value: USERGROUPS_ALL, label: t("general.all") },
                                        { value: USERGROUP_COMPANY, label: t("user.group.user") },
                                        { value: USERGROUP_COMPANYADMIN, label: t("user.group.admin") },
                                    ]}
                                    {...form.getInputProps('group')}
                                />

                                <Group mt="sm" justify="space-between">
                                    <Button variant="outline" color="yellow" leftSection={<Reload />} type="reset">{t("general.reset")}</Button>
                                    <Button variant="outline" color="green" leftSection={<Search />} type="submit">{t("search.search")}</Button>
                                </Group>
                            </Stack>
                        </form>
                    </Accordion.Panel>
                </Accordion.Item>
            </Accordion>

            <Paper radius="sm" withBorder>
                <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover withRowBorders>
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th></Table.Th>
                            <Table.Th>{t("general.name")}</Table.Th>
                            <Table.Th>{t("general.email")}</Table.Th>
                            <Table.Th>{t("general.active")}</Table.Th>
                            <Table.Th>{t("user.permissions.permissions")}</Table.Th>
                            <Table.Th>{t("date.create_time")}</Table.Th>
                            <Table.Th>{t("date.change_time")}</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{rows}</Table.Tbody>
                </Table>
            </Paper>
        </Stack>
    );
}