import { useEffect, useRef, useState } from "react"
import { Company, CompanyUser, User } from "../models";
import { useUserState } from "../helpers/GlobalUserState";
import { USERGROUP_ADMIN, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_SUPPORT, USERGROUP_USER } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { DataStore } from "aws-amplify";
import { Center, Flex, Grid, Text, UnstyledButton } from "@mantine/core";
import Avatar from "./Avatar";
import { ChevronRight, UserCircle } from "tabler-icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTE_COMPANY_USER_PROFILE, ROUTE_SUPPORT_PROFILE, ROUTE_USER_PROFILE } from "../helpers/Routes";

export default function UserBadge() {

    // globals
    const userDataRef = useRef(null);
    const companyUserDataRef = useRef(null);
    const companyDataRef = useRef(null);
    const [avatar, setAvatar] = useState({ key: null, protectionLevel: "private" });
    const [name, setName] = useState(null);
    const user = useUserState();
    const setError = useErrorDispatch();
    const avatarSize = 50;
    const navigate = useNavigate();

    /**
     * wrapper to fetch data for user badge
     */
    const fetchData = async () => {
        // if user id is not set, we cant fetch the users data
        if (!user.id) {
            return;
        }

        // try to fetch data
        try {
            if (user.userGroup === USERGROUP_USER) {
                const userData = await DataStore.query(User, user.id);
                setName(userData?.name);
                setAvatar({ key: userData?.avatar, protectionLevel: "private" });

            }
            else if (user.userGroup === USERGROUP_COMPANY || user.userGroup === USERGROUP_COMPANYADMIN) {
                const companyUserData = await DataStore.query(CompanyUser, user.id);
                setName(companyUserData?.name);

                // get company user or company image
                if (companyUserData.avatar) {
                    // if the company user has an avatar set, show that
                    setAvatar({ key: companyUserData.avatar, protectionLevel: "private" });
                }
                else {
                    // if there is no avatar set on the company user, try to fetch the image from the company
                    const companyData = await DataStore.query(Company, user.companyId);
                    setAvatar({ key: companyData?.logo, protectionLevel: "protected" });
                }

            }
            else {
                setName(null);
                setAvatar({ key: null, protectionLevel: "private" });
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /**
     * use effect hook on user change
     */
    useEffect(() => {
        fetchData();

        // create listeners for changes in user data
        userDataRef.current = DataStore.observe(User, user.id).subscribe(_ => {
            fetchData()
        });
        companyUserDataRef.current = DataStore.observe(CompanyUser, user.id).subscribe(_ => {
            fetchData()
        });
        companyDataRef.current = DataStore.observe(Company, user.companyId).subscribe(_ => {
            fetchData()
        });

        // unsubscribe from listeners when unmounting
        return () => {
            userDataRef.current?.unsubscribe();
            companyUserDataRef.current?.unsubscribe();
            companyDataRef.current?.unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    const getEmailWithBreakes = () => {
        if (!user.email) {
            return "";
        }
        const emailParts = user.email.split(/(?=[.,@+-])|(?<=[.,@+-])/g);
        const email = emailParts.join("​");
        return email;
    }

    /**
     * wrapper to navigate when clicking on user badge
     */
    const navigateOnClick = () => {
        if (user.userGroup === USERGROUP_USER) {
            navigate(ROUTE_USER_PROFILE);
        }
        else if (user.userGroup === USERGROUP_COMPANY || user.userGroup === USERGROUP_COMPANYADMIN) {
            navigate(ROUTE_COMPANY_USER_PROFILE);
        }
        else if (user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) {
            navigate(ROUTE_SUPPORT_PROFILE);
        }
    }

    return (

        <UnstyledButton
            className="menu-item border-gray"
            onClick={() => navigateOnClick()}
        >
            <Grid align="center">
                <Grid.Col span="content">
                    <Avatar
                        size={avatarSize}
                        fileKey={avatar.key}
                        fileProtectionLevel={avatar.protectionLevel}
                        identityId={user.identityId}
                        placeholder={UserCircle}
                    />
                </Grid.Col>
                <Grid.Col span="auto">
                    <Flex direction="column" gap={0}>
                        {name &&
                            <Text
                                lineClamp={4}
                                size="sm"
                                fw={500}
                                >
                                {name}
                            </Text>
                        }
                        <Text
                            lineClamp={4}
                            style={{ wordBreak: "break-word" }}
                            size="xs"
                            dangerouslySetInnerHTML={{ __html: getEmailWithBreakes() }}
                        >
                        </Text>
                    </Flex>
                </Grid.Col>
                <Grid.Col span="content">
                    <Center>
                        <ChevronRight size={16} />
                    </Center>
                </Grid.Col>
            </Grid>
        </UnstyledButton>
    );
}