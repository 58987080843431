import { Select } from "@mantine/core";
import { useTranslation } from 'react-i18next';

/**
 * input for sort field selection
 * @param {object} props component props
 * @returns JSX
 */
export default function SortFieldInput(props) {

    // globals
    const { t } = useTranslation();

    return (
        <Select
            label={t("sort.by")}
            placeholder="..."
            clearable={false}
            {...props}
        />
    )
}