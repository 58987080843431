import { Accordion, Button, Divider, Group, Stack, Title } from "@mantine/core";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ROUTE_NOT_FOUND, ROUTE_USER_APPLICATIONS } from "../../helpers/Routes";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { JobApplication } from "../../models";
import { DataStore } from "aws-amplify";
import { Briefcase, List } from 'tabler-icons-react';
import JobDetails from "../../components/JobDetails";
import BadgeTimestamp from "../../components/BadgeTimestamp";
import Job from "../../components/Job";
import { useTranslation } from 'react-i18next';

// html parser for descriptions
const parseHtml = require('html-react-parser');

/**
 * user page for application creation
 * @returns JSX
 */
export default function PageUserApplicationShow() {

    // globals
    const { id } = useParams();
    const [application, setApplication] = useState();
    const navigate = useNavigate();
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const { t } = useTranslation();

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        // fetch job
        setLoading(LOADING_SHOW);
        try {
            // get job
            const application = await DataStore.query(JobApplication, id);
            const job = await application.jobOffer;
            const company = await job.company;
            const jobCategory = await job.jobCategory;
            const workTimeRegulation = await job.workTimeRegulation;
            const positionLevel = await job.positionLevel;
            setApplication({
                ...application,
                job: {
                    ...job,
                    company: company,
                    jobCategory,
                    workTimeRegulation,
                    positionLevel
                },
            });
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /** 
     * use effect hook to fetch job data
     */
    useEffect(() => {
        // no job offer id provided, redirect to 404
        if (!id) {
            navigate(ROUTE_NOT_FOUND)
        }

        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [id]
    );

    // if job is not fetched, hide page
    if (!application) {
        return null;
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("application.application")}</Title>
                <Link to={ROUTE_USER_APPLICATIONS}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
            </Group>
            <Divider />

            <Job job={application.job} />
            <Divider />

            <Title order={3}>{t("job.job")}</Title>
            <JobDetails job={application.job} defaultValue={[]} />
            <Divider />

            <Group justify="space-between">
                <Title order={3}>{t("application.application")}</Title>
                <BadgeTimestamp label={`${t("application.applied_time")}:`} time={application.createdAt} />
            </Group>
            <Accordion variant="separated" defaultValue={"applicationtext"}>
                <Accordion.Item value="applicationtext">
                    <Accordion.Control icon={<Briefcase size={20} />}>{t("application.letter")}</Accordion.Control>
                    <Accordion.Panel>{parseHtml(application.text)}</Accordion.Panel>
                </Accordion.Item>
            </Accordion>
        </Stack>
    )
}