import { Divider, Stack, Title, Group, Button, TextInput } from "@mantine/core";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "../../components/Form";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { VALIDATION_SCHEMA_BOOL, VALIDATION_SCHEMA_CURRENCY_CODE, VALIDATION_SCHEMA_STRING, VALIDATION_SCHEMA_TRANSLATIONS } from "../../helpers/Validation";
import * as Yup from 'yup';
import { showNotification } from "@mantine/notifications";
import { Check, File, List, Reload } from "tabler-icons-react";
import { DataStore } from "aws-amplify";
import { Currency } from "../../models";
import { useEffect } from "react";
import { ROUTE_ADMIN_CURRENCY } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';
import CheckboxInput from "../../components/CheckboxInput";
import TranslationsInput from "../../components/TranslationsInput";

// validation schema with yup
const validationSchema = Yup.object().shape({
    code: VALIDATION_SCHEMA_CURRENCY_CODE,
    name: VALIDATION_SCHEMA_STRING,
    active: VALIDATION_SCHEMA_BOOL,
    translations: VALIDATION_SCHEMA_TRANSLATIONS
});

/**
 * currency new/edit page
 * @returns JSX
 */
export default function PageAdminCurrency() {

    // globals
    const navigate = useNavigate();
    const { code } = useParams();
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const { t } = useTranslation();

    /**
     * wrapper to prepare the page
     */
    const preparePage = async () => {
        try {
            if (code) {
                // get item
                setLoading(LOADING_SHOW);
                const item = await DataStore.query(Currency, code);
                if (!item) {
                    // currency data not found
                    throw new Error("Currency could not be loaded.");
                }

                // set data
                form.setInitialValues({
                    code: item.code,
                    name: item.name,
                    active: item.active,
                    translations: item.translations.map((e) => ({ ...e })) || []
                });
            }
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e, callback: () => window.location.reload(false) });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        preparePage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [code]
    );

    // submit callback
    const submitCallback = async (values) => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // save item
            if (!code) {
                // check if the item already exists
                const original = await DataStore.query(Currency, values.code);
                if (original) {
                    throw new Error(t("currency.exists"));
                }

                // create item
                await DataStore.save(new Currency({
                    code: values.code,
                    name: values.name,
                    active: values.active,
                    translations: values.translations
                }));
            } else {
                // update item
                const original = await DataStore.query(Currency, code);
                await DataStore.save(Currency.copyOf(original, updated => {
                    updated.name = values.name;
                    updated.active = values.active;
                    updated.translations = values.translations;
                }));
            }

            // show success
            showNotification({ message: t("general.saved"), color: 'green', icon: <Check /> });

            // navigate to list
            navigate(ROUTE_ADMIN_CURRENCY);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    // form hook
    const form = useForm({
        validationSchema: validationSchema,
        initialValues: {
            code: "",
            name: "",
            active: false,
            translations: []
        },
        submitCallback: submitCallback
    });

    // if there is a code provided and we have not fetched the values, just return
    if (code && !form.values.code) {
        return null;
    }

    return (
        <Stack>
            <Title>{form.values.code ? t("currency.edit") : t("currency.create")}</Title>
            <Divider />

            <form
                onSubmit={form.onSubmit()}
                onReset={form.onReset}
            >
                <Stack>
                    <TextInput
                        withAsterisk
                        label={t("general.code")}
                        placeholder={t("general.code")}
                        {...form.getInputProps('code')}
                        disabled={code}
                        description={t("currency.code_description")}
                    />
                    <TextInput
                        withAsterisk
                        label={t("currency.name_english")}
                        placeholder={t("currency.name_english")}
                        {...form.getInputProps('name')}
                    />
                    <CheckboxInput
                        label={t("general.active")}
                        {...form.getInputProps('active', { type: 'checkbox' })}
                    />
                    <TranslationsInput
                        form={form}
                    />
                    <Divider />

                    <Group justify="space-between">
                        <Group>
                            <Button leftSection={<Reload size={14} />} type="reset" color="red">{t("general.reset")}</Button>
                            <Link to={ROUTE_ADMIN_CURRENCY}><Button leftSection={<List size={14} />} color="yellow">{t("general.back")}</Button></Link>
                        </Group>
                        <Button leftSection={<File size={14} />} type="submit" color="green">{t("general.save")}</Button>
                    </Group>
                </Stack>
            </form>
        </Stack>
    )
}