import { Checkbox, Input } from "@mantine/core"
import InputBackground from "./InputBackground";

/**
 * input wrapper for checkbox
 * @param {object} props component props
 * @returns JSX
 */
const CheckboxInput = (props) => {
    return (
        <Input.Wrapper error={props.error}>
            <Input.Label>{props.label}</Input.Label>
            {props.description &&
                <Input.Description mb={5}>{props.description}</Input.Description>
            }
            <InputBackground error={props.error}>
                <Checkbox
                    style={{ height: "20px" }}
                    {...props}
                    label={props.text ? props.text : ""}
                    description=""
                />
            </InputBackground>
        </Input.Wrapper>
    )
}

// Default export of the class
export default CheckboxInput;