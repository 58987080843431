import { Flex, Paper, Text } from '@mantine/core';
import React from 'react';
import { Surface, Symbols } from 'recharts';
import { formatNumber } from '../helpers/i18n';

// globals
const RADIAN = Math.PI / 180;

/**
 * implementation of custom x axis tick component for Recharts
 */
export function CustomizedRechartsXAxisTick(props) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" className="charts-text-color" transform="rotate(-45)" fontSize={12}>
        {payload.value}
      </text>
    </g>
  );
}

/**
 * implementation of custom y axis tick component for Recharts
 */
export function CustomizedRechartsYAxisTick(props) {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={4} textAnchor="end" className="charts-text-color" fontSize={12}>
        {formatNumber(payload.value)}
      </text>
    </g>
  );
}

/**
 * implementation of custom label component for Recharts
 */
export const CustomizedRechartsDot = (props) => {
  const { cx, cy } = props;

  return (
    <g>
      <circle cx={cx} cy={cy} r={4} className="charts-circle-color" stroke="black" />
    </g>
  );
};

/**
 * implementation of custom tooltip component for Recharts
 */
export const CustomizedRechartsLineChartTooltip = (props) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <Paper
        withBorder
        shadow="md"
        p="xs"
        radius="md"
      >
        <Text size="sm">{`${label || payload[0].name}: ${formatNumber(payload[0].value)}`}</Text>
      </Paper>
    );
  }

  return null;
};

/**
 * custom label renderer
 * @param {props} props component props
 * @returns text to render for label
 */
export const RenderCustomizedLabel = (props) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
  const radius = innerRadius + (outerRadius - innerRadius) * 1.27;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} className="charts-text-color" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" fontSize={11}>
      {`${formatNumber(percent * 100, { minimumFractionDigits: 0, maximumFractionDigits: 1 })} %`}
    </text>
  );
};

/**
 * custom legend for pie chart
 * @param {object} props component props
 * @returns legend
 */
export const CustomizedPieLegend = (props) => {
  const { payload } = props;

  return (
    <div className="charts-legend">
      {payload.map((entry) => {
        const { value, color } = entry;

        var text = value;
        if (entry.payload.value !== undefined) {
          text += `, ${formatNumber(entry.payload.value)}`;
        }
        if (entry.payload.percent) {
          text += `, ${formatNumber(entry.payload.percent * 100, { minimumFractionDigits: 0, maximumFractionDigits: 1 })} %`
        }

        return (
          <Flex key={value}>
            <div style={{ width: 14, marginRight: "8px", paddingTop: 2 }}>
              <Surface
                width={14}
                height={14}
                viewBox={{ x: 0, y: 0, width: 32, height: 32 }}
                style={{
                  display: "inline-block",
                  verticalAlign: "text-top"
                }}
              >
                <Symbols
                  cx={16}
                  cy={16}
                  type={payload.type}
                  size={804.2}
                  fill={color}
                  className="charts-circle-color"
                />
              </Surface>
            </div>
            <Text fz={14}>{text}</Text>
          </Flex>
        )
      })}
    </div>
  );
}