import { Briefcase } from "tabler-icons-react";
import BadgeAutoHeight from "./BadgeAutoHeight";
import { getTextFromTranslations } from "../helpers/i18n";
import { useEffect, useState } from "react";

/**
 * job category badge
 * @param {object} props component props
 * @returns JSX
 */
export default function BadgeJobCategory(props) {

    // globals
    const { name, translations } = props.jobCategory;
    const [text, setText] = useState(name);

    /**
     * Use effect hook to get translated text if set
     */
    useEffect(() => {
        getTextFromTranslations(translations).then(translatedText => {
            setText(translatedText || name);
        })
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [name, translations]
    );

    return (
        <BadgeAutoHeight
            color="orange"
            variant="outline"
            leftSection={<Briefcase size={14} />}
            size="xs"
        >
            {text}
        </BadgeAutoHeight>
    )
}