import { DataStore } from "aws-amplify";
import { ActionIcon, Center, Divider, Flex, Group, Paper, Spoiler, Stack, Table, Text } from "@mantine/core";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Edit, ExternalLink, Language, LanguageOff } from "tabler-icons-react";
import { TABLE_ACTION_SIZE, TABLE_ACTION_WIDTH } from "../helpers/Constants";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { JobCategory, PositionLevel, WorkTimeRegulation } from "../models";
import BadgeApplicationStatus from "./BadgeApplicationStatus";
import BadgeJobCategory from "./BadgeJobCategory";
import BadgeWorkTimeRegulation from "./BadgeWorkTimeRegulation";
import { formatDate } from "../helpers/Moment";
import { useTranslation } from 'react-i18next';
import { getTextFromTranslations } from "../helpers/i18n";
import BadgePositionLevel from "./BadgePositionLevel";

// css
import classes from '../styles/Spoiler.module.css';

// export of column types and styles
export const COLUMN_TYPE_TRANSLATION = "translation";
export const COLUMN_TYPE_TRANSLATIONS = "translations";
export const COLUMN_TYPE_COLOR = "color";
export const COLUMN_TYPE_TIMESTAMP = "timestamp";
export const COLUMN_TYPE_DATE = "date";
export const COLUMN_TYPE_JOBCATEGORY = "jobcategory";
export const COLUMN_TYPE_WORKTIMEREGULATION = "worktimeregulation";
export const COLUMN_TYPE_POSITIONLEVEL = "positionlevel";
export const COLUMN_TYPE_APPLICATIONSTATUS = "applicationstatus";
export const COLUMN_TYPE_BOOLEAN = "boolean";

/**
 * list component
 * @param {object} props component props
 * @returns JSX
 */
export default function List(props) {

    // globals
    const subscription = useRef(null);
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [items, setItems] = useState([]);
    const navigate = useNavigate();
    const workTimeRegulationsRef = useRef(null);
    const workTimeRegulationItemsRef = useRef([]);
    const jobCategoriesRef = useRef(null);
    const jobCategoryItemsRef = useRef([]);
    const positionLevelsRef = useRef(null);
    const positionLevelItemsRef = useRef([]);
    const initialFetchRef = useRef(false);
    const { t } = useTranslation();

    /**
     * Use effect hook to fetch data when filter or sorting changes
     */
    useEffect(() => {
        if (initialFetchRef.current) {
            debouncedFetchData(props.filter, props.customFilter, props.sortField, props.sortDirection);
        }

        // unsubscribe from listeners when unmounting
        return () => {
            unsubscribe();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.filter, props.customFilter, props.sortField, props.sortDirection]
    );

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        setLoading(LOADING_SHOW);
        fetchData(props.filter, props.customFilter, props.sortField, props.sortDirection);

        return () => {
            jobCategoriesRef.current?.unsubscribe();
            jobCategoriesRef.current = null;
            workTimeRegulationsRef.current?.unsubscribe();
            workTimeRegulationsRef.current = null;
            positionLevelsRef.current?.unsubscribe();
            positionLevelsRef.current = null;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to fetch data
     */
    const fetchData = async (filter, customFilter, sortField, sortDirection) => {
        // fist unsubscribe of already running
        unsubscribe();

        // if this is the first fetch, we may need to fetch categories and work time regulations
        if (!initialFetchRef.current) {
            await fetchJobCategories();
            await fetchWorkTimeRegulations();
            await fetchPositionLevels();
        }

        // construct sorting
        var sort = null;
        if (sortField && sortDirection) {
            sort = s => s[sortField](sortDirection);
        }

        // fetch data
        const items = await DataStore.query(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        );
        setItemsHandler(items);

        // subscription for changes
        subscription.current = DataStore.observeQuery(
            props.type,
            filter ? p => p.and(filter) : null,
            {
                sort: sort ? sort : null,
            }
        ).subscribe({
            next(snapshot) {
                setItemsHandler(snapshot.items, customFilter);
            },
            error(err) {
                setLoading(LOADING_RESET);
                setError({ action: ERROR_SHOW, error: err });
            }
        });
    }

    /**
     * fetches the job categories if needed
     */
    const fetchJobCategories = async () => {
        // check if we need to fetch 
        const foundItem = props.dataStructure.find((e) => {
            return e.key.toLowerCase().includes(COLUMN_TYPE_JOBCATEGORY);
        })

        // no item found, we do not need to fetch
        if (!foundItem) {
            return;
        }

        // we have found the key, so we need to fetch
        jobCategoriesRef.current = DataStore.observeQuery(JobCategory).subscribe(snapshot => {
            const { items, isSynced } = snapshot;

            // if not synced, discard
            if (!isSynced) {
                return;
            }

            // set items
            jobCategoryItemsRef.current = items;
        });
    }

    /**
     * fetches the work time regulations if needed
     */
    const fetchWorkTimeRegulations = async () => {
        // check if we need to fetch
        const foundItem = props.dataStructure.find((e) => {
            return e.key.toLowerCase().includes(COLUMN_TYPE_WORKTIMEREGULATION);
        })

        // no item found, we do not need to fetch
        if (!foundItem) {
            return;
        }

        // we have found the key, so we need to fetch
        workTimeRegulationsRef.current = DataStore.observeQuery(WorkTimeRegulation).subscribe(snapshot => {
            const { items, isSynced } = snapshot;

            // if not synced, discard
            if (!isSynced) {
                return;
            }

            // set items
            workTimeRegulationItemsRef.current = items;
        });
    }

    /**
     * fetches the work time regulations if needed
     */
    const fetchPositionLevels = async () => {
        // check if we need to fetch
        const foundItem = props.dataStructure.find((e) => {
            return e.key.toLowerCase().includes(COLUMN_TYPE_POSITIONLEVEL);
        })

        // no item found, we do not need to fetch
        if (!foundItem) {
            return;
        }

        // we have found the key, so we need to fetch
        positionLevelsRef.current = DataStore.observeQuery(PositionLevel).subscribe(snapshot => {
            const { items, isSynced } = snapshot;

            // if not synced, discard
            if (!isSynced) {
                return;
            }

            // set items
            positionLevelItemsRef.current = items;
        });
    }

    /**
     * wrapper to check and fetch sub objects if needed
     * @param {array} items the items to set
     */
    const setItemsHandler = async (items, customFilter) => {
        try {
            // get all columns in subobjects
            const filteredSubColumns = props.dataStructure.filter((e) => {
                return e.key.includes(".");
            });

            // get columns to translate if there are any
            const columnsToTranslate = props.dataStructure.filter((e) => {
                return e.type === COLUMN_TYPE_TRANSLATION;
            }).map((e) => {
                return e.key;
            })

            const subColumns = filteredSubColumns.map((e) => {
                const split = e.key.split(".");
                return {
                    key: e.key,
                    path: split,
                }
            });

            // iterate over all items and create promises
            var promises = [];
            items.forEach(item => {
                promises.push(new Promise(async (resolve, reject) => {
                    var subPromises = [];
                    subColumns.forEach(subColumn => {
                        subPromises.push(new Promise(async (resolve, reject) => {
                            try {
                                const subLength = subColumn.path.length;
                                var subObject = await item[subColumn.path[0]];
                                for (var i = 1; i < subLength - 1; i++) {
                                    if (!subObject[subColumn.path[i]]) {
                                        resolve({ key: subColumn.key, value: "" });
                                    }
                                    subObject = await subObject[subColumn.path[i]]
                                }
                                resolve({ key: subColumn.key, value: subObject[subColumn.path[subLength - 1]] });
                            }
                            catch (err) {
                                reject(err);
                            }
                        }));
                    });

                    // wait for subpromises
                    try {
                        // get sub values if needed
                        const subPromiseResult = await Promise.all(subPromises);
                        var newItem = { ...item };
                        subPromiseResult.forEach(result => {
                            newItem[result.key] = result.value;
                        });

                        // translate fields
                        const translatePromises = [];
                        columnsToTranslate.forEach(columnToTranslate => {
                            translatePromises.push(new Promise(async (resolve) => {
                                const translatedText = await getTextFromTranslations(newItem.translations);
                                if (!translatedText) {
                                    resolve(null);
                                }

                                resolve({
                                    key: columnToTranslate,
                                    text: translatedText
                                });
                                resolve(null);
                            }))
                        });
                        const translationPromiseResults = await Promise.all(translatePromises);
                        translationPromiseResults.forEach(result => {
                            if (result) {
                                newItem[result.key] = result.text;
                            }
                        });

                        resolve(newItem);
                    }
                    catch (err) {
                        reject(err);
                    }
                }));
            });

            // wait for promises to execute
            var promiseResult = await Promise.all(promises);

            // set items
            setItems(promiseResult);

            // set intial fetch to true
            initialFetchRef.current = true;
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * debounce wrapper for the fetch
     */
    const debouncedFetchData = useRef(debounce(async (filter, customFilter, sortField, sortDirection) => {
        fetchData(filter, customFilter, sortField, sortDirection);
    }, 300)).current;

    /**
     * wrapper to unsubscribe
     */
    const unsubscribe = () => {
        if (subscription.current) {
            subscription.current.unsubscribe();
            subscription.current = null;
        }
    }

    /**
     * table header JSX
     */
    const tableHeader = <Table.Tr>
        {(props.showRoute || props.editRoute) && <Table.Th key="th_controls"></Table.Th>}
        {props.dataStructure.map((e) => (
            <Table.Th key={e.key}>{e.title}</Table.Th>
        ))}
    </Table.Tr>

    /**
     * renders a field in a column
     * @param {object} element data column info
     * @param {*} item item to render
     * @returns JSX
     */
    const renderField = (element, item) => {
        switch (element.type) {
            case COLUMN_TYPE_COLOR:
                return <Group>
                    <div style={{ backgroundColor: item[element.key], height: "25px", width: "25px", borderRadius: "25px" }}></div>
                    <Text>{item[element.key]}</Text>
                </Group>;
            case COLUMN_TYPE_TIMESTAMP:
                return <Text size="sm">{formatDate(item[element.key], "L LT")}</Text>;
            case COLUMN_TYPE_DATE:
                return <Text size="sm">{formatDate(item[element.key], "L")}</Text>;
            case COLUMN_TYPE_JOBCATEGORY:
                var jobCategory = jobCategoryItemsRef.current.find((e) => e.id === item[element.key]);
                if (jobCategory) {
                    return <BadgeJobCategory jobCategory={jobCategory} />
                }
                return "";
            case COLUMN_TYPE_WORKTIMEREGULATION:
                var workTimeRegulation = workTimeRegulationItemsRef.current.find((e) => e.id === item[element.key]);
                if (workTimeRegulation) {
                    return <BadgeWorkTimeRegulation workTimeRegulation={workTimeRegulation} />
                }
                return "";
            case COLUMN_TYPE_POSITIONLEVEL:
                var positionLevel = positionLevelItemsRef.current.find((e) => e.id === item[element.key]);
                if (positionLevel) {
                    return <BadgePositionLevel positionLevel={positionLevel} />
                }
                return "";
            case COLUMN_TYPE_APPLICATIONSTATUS:
                return <BadgeApplicationStatus application={item} />
            case COLUMN_TYPE_BOOLEAN:
                return <Text size="sm">{item[element.key] === true ? t("general.yes") : t("general.no")}</Text>;
            case COLUMN_TYPE_TRANSLATIONS:
                const text = <Text>{item[element.key]}</Text>;
                if (item.translations && item.translations.length > 0) {
                    return (
                        <Spoiler
                            maxHeight={25}
                            showLabel={<Language size={21.69} />}
                            hideLabel={<LanguageOff size={21.69} />}
                            classNames={{
                                root: classes.spoilerRootFlex,
                                content: classes.spoilerContentFlex,
                                control: classes.spoilerControlFlex,
                            }}
                        >
                            {text}
                            <Paper p={5} withBorder mt="xs" bg="transparent" px={0} py={1}>
                                <Flex key={`translation_${element.key}}_keys`}>
                                    <Stack gap={0}>
                                        {item.translations.map((e, index) => (
                                            <div key={`translation_${element.key}}_${e.languageCode}_code`}>
                                                <Text fs="italic" size="xs" ml={5}>{e.languageCode}</Text>
                                                {index < item.translations.length - 1 && <Divider />}
                                            </div>
                                        ))}
                                    </Stack>
                                    <Stack gap={0} style={{ flexGrow: 1 }}>
                                        {item.translations.map((e, index) => (
                                            <div key={`translation_${element.key}}_${e.languageCode}_text`}>
                                                <Text size="xs" ml={5} mr={5}>{e.text}</Text>
                                                {index < item.translations.length - 1 && <Divider />}
                                            </div>
                                        ))}
                                    </Stack>
                                </Flex>
                            </Paper>
                        </Spoiler>
                    )
                }
                else {
                    return text;
                }
            default:
                return item[element.key] ? <Text size="sm">{item[element.key]}</Text> : <Center style={{ justifyContent: "flex-start" }}>{element.placeholder}</Center>;
        }
    }

    /**
     * table rows JSX
     */
    const rows = items.map((item) => (
        <Table.Tr key={item.id || item.code}>
            {(props.showRoute || props.editRoute) &&
                <Table.Td
                    key={`${item[props.id]}_${"td_control"}`}
                    style={{
                        width: (props.showRoute && props.editRoute) ? (TABLE_ACTION_SIZE * 2) + 42 : TABLE_ACTION_WIDTH
                    }}
                >
                    <Center>
                        <Group gap={8} px={5}>
                            {props.showRoute &&
                                <ActionIcon
                                    color="blue"
                                    variant="outline"
                                    onClick={() => navigate(`${props.showRoute}/${item[props.id]}`)}
                                    size={TABLE_ACTION_SIZE}
                                >
                                    <ExternalLink />
                                </ActionIcon>
                            }
                            {props.editRoute &&
                                <ActionIcon
                                    color="blue"
                                    variant="outline"
                                    onClick={() => navigate(`${props.editRoute}/${item[props.id]}`)}
                                    size={TABLE_ACTION_SIZE}
                                >
                                    <Edit />
                                </ActionIcon>
                            }
                        </Group>
                    </Center>
                </Table.Td>
            }
            {props.dataStructure.map((element) => (
                <Table.Td key={`${item[props.id]}_${element.key}`}>{renderField(element, item)}</Table.Td>
            ))}
        </Table.Tr>
    ));

    return (
        <Paper radius="sm" withBorder>
            <Table verticalSpacing="sm" horizontalSpacing="sm" highlightOnHover withRowBorders>
                <Table.Thead>{tableHeader}</Table.Thead>
                <Table.Tbody>{rows}</Table.Tbody>
            </Table>
        </Paper>
    )
}