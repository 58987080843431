import { useEffect, useRef, useState } from "react";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../../helpers/GlobalLoadingState";
import { Button, Card, Center, Divider, Grid, Group, Image, Modal, Paper, Stack, Text, Title } from "@mantine/core";
import { API_MODE_GET, QUERIES_API_CHECKOUT_SESSION, QUERIES_API_NAME, QUERIES_API_PRODUCTS_LIST, executeApiCall } from "../../helpers/APIHelper";
import { DataStore } from "aws-amplify";
import { CompanyPaymentData } from "../../models";
import { useUserState } from "../../helpers/GlobalUserState";
import { useTranslation } from 'react-i18next';
import { formatNumber } from "../../helpers/i18n";
import BadgeAutoHeight from "../../components/BadgeAutoHeight";
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { X } from "tabler-icons-react";

// globals consts
const stripePromise = loadStripe("pk_test_51NyaRrATRfT3A0Nfp7BehMtq5v8y4WZ1bs6IG3ciX55xthJB4sLjtkINd7IdJJPVjty2bqHaASk9uk0VP2X59Cnf00aXNF0C1C");

/**
 * page for companies to view packages and buy new packages
 * @returns JSX
 */
export default function PageCompanyPackages() {

    // globals
    const setLoading = useLoadingDispatch();
    const setError = useErrorDispatch();
    const [products, setProducts] = useState(null);
    const [companyPaymentData, setCompanyPaymentData] = useState(null);
    const user = useUserState();
    const companyPaymentDataRef = useRef(null);
    const { t } = useTranslation();
    const [buyModal, setBuyModal] = useState(null);

    /**
     * wrapper to fetch data
     */
    const fetchData = async () => {
        try {
            const companyPaymentData = await DataStore.query(CompanyPaymentData, user.companyId);
            const products = await executeApiCall(QUERIES_API_NAME, API_MODE_GET, QUERIES_API_PRODUCTS_LIST, null);
            setProducts(products);
            setCompanyPaymentData(companyPaymentData);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        // fetch data
        setLoading(LOADING_SHOW);
        fetchData().finally(() => {
            setLoading(LOADING_RESET);
        });

        // observer for company payment data
        companyPaymentDataRef.current = DataStore.observe(CompanyPaymentData, user.companyId).subscribe(msg => {
            fetchData();
        });

        return () => {
            companyPaymentDataRef.current?.unsubscribe();
            companyPaymentDataRef.current = null;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * wrapper to fetch client secret of payment provider and show modal
     * @param {string} id the payment providers id
     */
    const showBuyModal = async (id) => {
        try {
            setLoading(LOADING_SHOW);
            const clientSecret = await executeApiCall(QUERIES_API_NAME, API_MODE_GET, QUERIES_API_CHECKOUT_SESSION, { priceId: id });
            setBuyModal(clientSecret);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }
    }

    /**
     * wrapper to reset and close buy modal
     */
    const closeBuyModal = () => {
        setBuyModal(null);
    }

    // wait for data to be fetched
    if (!products || !companyPaymentData) {
        return null;
    }

    return (
        <Stack>
            <Title>{t("unlock.unlocks")}</Title>
            <Divider mb="xs" />

            <Title order={3}>{t("unlock.available")}</Title>
            <Paper withBorder radius="md" p="md">
                <Center>
                    <Stack gap={0}>
                        <Center>
                            <BadgeAutoHeight
                                style={{
                                    minHeight: 127.5,
                                    minWidth: 127.5
                                }}
                                color={companyPaymentData.applicationsLeftToUnlock > 0 ? "green" : "red"}
                                mb="md"
                            >
                                <Text size={50} lh={1.5} fw={700} c="white">{companyPaymentData.applicationsLeftToUnlock}</Text>
                            </BadgeAutoHeight>
                        </Center>
                        <Center>
                            <Text tt="uppercase" fw={700}>{t("unlock.available")}</Text>
                        </Center>
                        <Center>
                            <Text c="dimmed" size="sm" fw={700}>
                                {companyPaymentData.applicationsLeftToUnlock > 0 ?
                                    t("unlock.value_message", { value: companyPaymentData.applicationsLeftToUnlock })
                                    :
                                    t("unlock.more")
                                }
                            </Text>
                        </Center>
                    </Stack>
                </Center>
            </Paper>

            <Stack gap={0}>
                <Title mt="md" order={3}>{t("unlock.get")}</Title>
                <Text>{t("unlock.more")}</Text>
            </Stack>
            <Grid>
                {products.map(product => {
                    return (
                        <Grid.Col span={{ base: 12, md: 12, xl: 4 }} key={product.id}>
                            <Card shadow="sm" padding="lg" radius="md" withBorder>
                                <Card.Section>
                                    <Paper m="md" withBorder bg="white">
                                        <Image
                                            src={product.images[0]}
                                            fit="contain"
                                            h={200}
                                            alt={product.id}
                                            w="100%"
                                            radius="md"
                                        />
                                    </Paper>
                                </Card.Section>

                                <Group justify="space-between">
                                    <Text fw={500}>
                                        {product.metadata?.unlockQuantity ?
                                            t("unlock.value", { value: product.metadata.unlockQuantity })
                                            :
                                            product.name
                                        }
                                    </Text>
                                    <Text fw={500}>{formatNumber(product.default_price.unit_amount / 100, { style: 'currency', currency: product.default_price.currency })}</Text>
                                </Group>
                                <Button
                                    color="green"
                                    fullWidth mt="md" radius="md"
                                    onClick={() => showBuyModal(product.default_price.id)}
                                >
                                    {t("unlock.buy")}
                                </Button>
                            </Card>
                        </Grid.Col>
                    )
                })}
            </Grid>

            {buyModal &&
                <Modal
                    opened={true}
                    onClose={() => closeBuyModal()}
                    centered
                    fullScreen
                    closeOnEscape={false}
                    padding="md"
                    withCloseButton={false}
                    title={
                        <Button leftSection={<X size={14} />} bg="#005DAA" onClick={() => closeBuyModal()}>{t("general.close")}</Button>
                    }
                    styles={{
                        root: {
                            backgroundColor: "#007F86",
                        },
                        content: {
                            backgroundColor: "#007F86",
                            height: "100%",
                            width: "100%",
                        },
                        inner: {
                            padding: 0,
                            height: "100%",
                            width: "100%"
                        },
                        header: {
                            backgroundColor: "#007F86",
                            width: "100%",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                        },
                        body: {
                            width: "100%",
                        }
                    }}
                >
                    <Paper bg="transparent">
                        <EmbeddedCheckoutProvider
                            stripe={stripePromise}
                            options={{ clientSecret: buyModal }}
                        >
                            <EmbeddedCheckout />
                        </EmbeddedCheckoutProvider>
                    </Paper>
                </Modal>
            }
        </Stack>
    );
}