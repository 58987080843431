import { Button, Divider, Group, Stack, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Plus } from "tabler-icons-react";
import CompanyJobsList from "../../components/CompanyJobsList";
import { ROUTE_COMPANY_JOBS_NEW } from "../../helpers/Routes";
import { useTranslation } from 'react-i18next';

/**
 * company job list page
 * @returns JSX
 */
export default function PageCompanyJobs() {

    // globals
    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Stack>
            <Group justify="space-between">
                <Title>{t("job.jobs")}</Title>
                <Button
                    color="green"
                    leftSection={<Plus />}
                    onClick={() => navigate(ROUTE_COMPANY_JOBS_NEW)}
                >
                    {t("job.create")}
                </Button>
            </Group>
            <Divider />

            <CompanyJobsList />
        </Stack>
    )
}