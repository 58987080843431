import { Flex, Group, Stack, Title } from "@mantine/core";
import { BuildingSkyscraper } from "tabler-icons-react";
import Avatar from "./Avatar";
import BadgeLocation from "./BadgeLocation";

/**
 * company details component
 */
export default function CompanyDetails(props) {

    // globals
    const { company } = props;

    return (
        <Flex gap="md">
            <Avatar
                size={100}
                identityId={company.id}
                fileKey={company.logo}
                fileProtectionLevel={"protected"}
                placeholder={BuildingSkyscraper}
            />
            <Stack gap={0}>
                <Group gap="xs">
                    <Title order={3} pb="0px">{company.name}</Title>
                </Group>

                <Group mt="xs" gap="xs">
                    <BadgeLocation addressText={company.addressText} />
                </Group>
            </Stack>
        </Flex>
    );
}