import { useTranslation } from "react-i18next";
import { Currency } from "../models";
import { useEffect, useRef, useState } from "react";
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { DataStore, SortDirection } from "aws-amplify";
import { getTextFromTranslations } from "../helpers/i18n";
import { Select } from "@mantine/core";

/**
 * custom input for currency selection
 * @param {string}  fieldName the name of the field on the form values 
 * @param {object}  form the form object itself 
 * @param {string?} label the label to show, optional 
 * @param {string?} description the description to show, optional
 * @param {string?} placeholder the placeholder to show, optional
 * @param {bool?}   withAsterisk if the component should show an asterisk
 * @returns JSX
 */
export default function CurrencySelect({ fieldName, form, label, description, placeholder, withAsterisk }) {

    // globals
    const [currencies, setCurrencies] = useState();
    const currenciesSubscriptionRef = useRef([]);
    const setError = useErrorDispatch();
    const { t } = useTranslation();

    /**
     * Use effect hook to initially fetch data
     */
    useEffect(() => {
        currenciesSubscriptionRef.current = DataStore.observeQuery(
            Currency,
            null,
            { sort: s => s.code(SortDirection.ASCENDING) }
        ).subscribe({
            next(snapshot) {
                const promises = snapshot.items.map(async (currency) => {
                    const test = getTextFromTranslations(currency.translations).then((translatedText) => {
                        return {
                            value: currency.code,
                            label: `${translatedText || currency.name} (${currency.code})`
                        }
                    })
                    return test;
                });

                Promise.all(promises).then((currencies) => {
                    currencies.sort((a, b) => (a.label.localeCompare(b.label)));
                    setCurrencies(currencies);
                }).catch(err => {
                    setError({ action: ERROR_SHOW, error: err });
                })
            },
            error(err) {
                setError({ action: ERROR_SHOW, error: err });
            }
        });

        return () => {
            currenciesSubscriptionRef.current?.unsubscribe();
            currenciesSubscriptionRef.current = null;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Select
            label={label || t("currency.currency")}
            data={currencies || []}
            {...form.getInputProps(fieldName)}
            description={description}
            placeholder={placeholder || t("currency.currency")}
            withAsterisk={withAsterisk}
        />
    )
}