/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const nearbyJobOffers = /* GraphQL */ `
  query NearbyJobOffers(
    $location: LocationInput
    $km: Int
    $keywords: [String!]
    $workTimeRegulationIds: [ID!]
    $jobCategoryIds: [ID!]
    $positionLevelIds: [ID!]
    $sortId: String
    $limit: Int
    $nextToken: [String]
  ) {
    nearbyJobOffers(
      location: $location
      km: $km
      keywords: $keywords
      workTimeRegulationIds: $workTimeRegulationIds
      jobCategoryIds: $jobCategoryIds
      positionLevelIds: $positionLevelIds
      sortId: $sortId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      total
      nextToken
      __typename
    }
  }
`;
export const getCurrency = /* GraphQL */ `
  query GetCurrency($code: String!) {
    getCurrency(code: $code) {
      code
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCurrencies = /* GraphQL */ `
  query ListCurrencies(
    $code: String
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCurrencies(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCurrencies = /* GraphQL */ `
  query SyncCurrencies(
    $filter: ModelCurrencyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCurrencies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        code
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getLanguage = /* GraphQL */ `
  query GetLanguage($code: String!) {
    getLanguage(code: $code) {
      code
      name
      nameNative
      countryCode
      currencyCode
      currency {
        code
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listLanguages = /* GraphQL */ `
  query ListLanguages(
    $code: String
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLanguages(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        name
        nameNative
        countryCode
        currencyCode
        currency {
          code
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncLanguages = /* GraphQL */ `
  query SyncLanguages(
    $filter: ModelLanguageFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLanguages(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        code
        name
        nameNative
        countryCode
        currencyCode
        currency {
          code
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      owner
      name
      location {
        lat
        lon
        __typename
      }
      addressText
      phonenumber
      email
      logo
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCompanies = /* GraphQL */ `
  query SyncCompanies(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanies(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCompanyPaymentData = /* GraphQL */ `
  query GetCompanyPaymentData($id: ID!) {
    getCompanyPaymentData(id: $id) {
      id
      owner
      paymentProviderId
      applicationsLeftToUnlock
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCompanyPaymentData = /* GraphQL */ `
  query ListCompanyPaymentData(
    $filter: ModelCompanyPaymentDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyPaymentData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        paymentProviderId
        applicationsLeftToUnlock
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCompanyPaymentData = /* GraphQL */ `
  query SyncCompanyPaymentData(
    $filter: ModelCompanyPaymentDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanyPaymentData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        paymentProviderId
        applicationsLeftToUnlock
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCompanyUser = /* GraphQL */ `
  query GetCompanyUser($id: ID!) {
    getCompanyUser(id: $id) {
      id
      owner
      companyId
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      applicationMailNotification
      language
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCompanyUsers = /* GraphQL */ `
  query ListCompanyUsers(
    $id: ID
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanyUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        companyId
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        applicationMailNotification
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCompanyUsers = /* GraphQL */ `
  query SyncCompanyUsers(
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCompanyUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        companyId
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        applicationMailNotification
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listCompanyUsersByCompany = /* GraphQL */ `
  query ListCompanyUsersByCompany(
    $companyId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyUsersByCompany(
      companyId: $companyId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        companyId
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        applicationMailNotification
        language
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      owner
      identityId
      name
      birthdate
      phonenumber
      location {
        lat
        lon
        __typename
      }
      addressText
      documents
      avatar
      language
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        identityId
        name
        birthdate
        phonenumber
        location {
          lat
          lon
          __typename
        }
        addressText
        documents
        avatar
        language
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        identityId
        name
        birthdate
        phonenumber
        location {
          lat
          lon
          __typename
        }
        addressText
        documents
        avatar
        language
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobApplication = /* GraphQL */ `
  query GetJobApplication($id: ID!) {
    getJobApplication(id: $id) {
      id
      userId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listJobApplications = /* GraphQL */ `
  query ListJobApplications(
    $id: ID
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobApplications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userId
        jobOfferId
        jobOffer {
          id
          owner
          company {
            id
            owner
            name
            addressText
            phonenumber
            email
            logo
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          description
          visibleInSearch
          workTimeRegulationId
          workTimeRegulation {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          jobCategoryId
          jobCategory {
            id
            name
            color
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          positionLevelId
          positionLevel {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          location {
            lat
            lon
            __typename
          }
          addressText
          salary {
            salary
            currencyCode
            timeUnit
            __typename
          }
          hours {
            hours
            timeUnit
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        text
        name
        email
        birthdate
        phonenumber
        documents
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobApplications = /* GraphQL */ `
  query SyncJobApplications(
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobApplications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userId
        jobOfferId
        jobOffer {
          id
          owner
          company {
            id
            owner
            name
            addressText
            phonenumber
            email
            logo
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          description
          visibleInSearch
          workTimeRegulationId
          workTimeRegulation {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          jobCategoryId
          jobCategory {
            id
            name
            color
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          positionLevelId
          positionLevel {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          location {
            lat
            lon
            __typename
          }
          addressText
          salary {
            salary
            currencyCode
            timeUnit
            __typename
          }
          hours {
            hours
            timeUnit
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        text
        name
        email
        birthdate
        phonenumber
        documents
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listJobApplicationsByOwner = /* GraphQL */ `
  query ListJobApplicationsByOwner(
    $userId: ID!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelJobApplicationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplicationsByOwner(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        jobOfferId
        jobOffer {
          id
          owner
          company {
            id
            owner
            name
            addressText
            phonenumber
            email
            logo
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          description
          visibleInSearch
          workTimeRegulationId
          workTimeRegulation {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          jobCategoryId
          jobCategory {
            id
            name
            color
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          positionLevelId
          positionLevel {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          location {
            lat
            lon
            __typename
          }
          addressText
          salary {
            salary
            currencyCode
            timeUnit
            __typename
          }
          hours {
            hours
            timeUnit
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        text
        name
        email
        birthdate
        phonenumber
        documents
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobApplicationCompanyData = /* GraphQL */ `
  query GetJobApplicationCompanyData($id: ID!) {
    getJobApplicationCompanyData(id: $id) {
      id
      companyId
      jobOfferId
      jobOffer {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      text
      name
      email
      birthdate
      phonenumber
      documents
      avatar
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listJobApplicationCompanyData = /* GraphQL */ `
  query ListJobApplicationCompanyData(
    $filter: ModelJobApplicationCompanyDataFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobApplicationCompanyData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        jobOfferId
        jobOffer {
          id
          owner
          company {
            id
            owner
            name
            addressText
            phonenumber
            email
            logo
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          description
          visibleInSearch
          workTimeRegulationId
          workTimeRegulation {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          jobCategoryId
          jobCategory {
            id
            name
            color
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          positionLevelId
          positionLevel {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          location {
            lat
            lon
            __typename
          }
          addressText
          salary {
            salary
            currencyCode
            timeUnit
            __typename
          }
          hours {
            hours
            timeUnit
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        text
        name
        email
        birthdate
        phonenumber
        documents
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobApplicationCompanyData = /* GraphQL */ `
  query SyncJobApplicationCompanyData(
    $filter: ModelJobApplicationCompanyDataFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobApplicationCompanyData(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        companyId
        jobOfferId
        jobOffer {
          id
          owner
          company {
            id
            owner
            name
            addressText
            phonenumber
            email
            logo
            description
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          name
          description
          visibleInSearch
          workTimeRegulationId
          workTimeRegulation {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          jobCategoryId
          jobCategory {
            id
            name
            color
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          positionLevelId
          positionLevel {
            id
            name
            active
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          location {
            lat
            lon
            __typename
          }
          addressText
          salary {
            salary
            currencyCode
            timeUnit
            __typename
          }
          hours {
            hours
            timeUnit
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        text
        name
        email
        birthdate
        phonenumber
        documents
        avatar
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobApplicationCompanyDataNote = /* GraphQL */ `
  query GetJobApplicationCompanyDataNote($id: ID!) {
    getJobApplicationCompanyDataNote(id: $id) {
      id
      owner
      companyId
      jobApplicationCompanyDataId
      text
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listJobApplicationCompanyDataNotes = /* GraphQL */ `
  query ListJobApplicationCompanyDataNotes(
    $id: ID
    $filter: ModelJobApplicationCompanyDataNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobApplicationCompanyDataNotes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        companyId
        jobApplicationCompanyDataId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobApplicationCompanyDataNotes = /* GraphQL */ `
  query SyncJobApplicationCompanyDataNotes(
    $filter: ModelJobApplicationCompanyDataNoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobApplicationCompanyDataNotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        companyId
        jobApplicationCompanyDataId
        text
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getWorkTimeRegulation = /* GraphQL */ `
  query GetWorkTimeRegulation($id: ID!) {
    getWorkTimeRegulation(id: $id) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listWorkTimeRegulations = /* GraphQL */ `
  query ListWorkTimeRegulations(
    $filter: ModelWorkTimeRegulationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkTimeRegulations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncWorkTimeRegulations = /* GraphQL */ `
  query SyncWorkTimeRegulations(
    $filter: ModelWorkTimeRegulationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkTimeRegulations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobCategory = /* GraphQL */ `
  query GetJobCategory($id: ID!) {
    getJobCategory(id: $id) {
      id
      name
      color
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listJobCategories = /* GraphQL */ `
  query ListJobCategories(
    $filter: ModelJobCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJobCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        color
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobCategories = /* GraphQL */ `
  query SyncJobCategories(
    $filter: ModelJobCategoryFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobCategories(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        color
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPositionLevel = /* GraphQL */ `
  query GetPositionLevel($id: ID!) {
    getPositionLevel(id: $id) {
      id
      name
      translations {
        languageCode
        text
        __typename
      }
      active
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPositionLevels = /* GraphQL */ `
  query ListPositionLevels(
    $filter: ModelPositionLevelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositionLevels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPositionLevels = /* GraphQL */ `
  query SyncPositionLevels(
    $filter: ModelPositionLevelFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPositionLevels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getJobOffer = /* GraphQL */ `
  query GetJobOffer($id: ID!) {
    getJobOffer(id: $id) {
      id
      owner
      company {
        id
        owner
        name
        location {
          lat
          lon
          __typename
        }
        addressText
        phonenumber
        email
        logo
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      name
      description
      visibleInSearch
      workTimeRegulationId
      workTimeRegulation {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      jobCategoryId
      jobCategory {
        id
        name
        color
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      positionLevelId
      positionLevel {
        id
        name
        translations {
          languageCode
          text
          __typename
        }
        active
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      location {
        lat
        lon
        __typename
      }
      addressText
      salary {
        salary
        currencyCode
        timeUnit
        __typename
      }
      hours {
        hours
        timeUnit
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listJobOffers = /* GraphQL */ `
  query ListJobOffers(
    $id: ID
    $filter: ModelJobOfferFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listJobOffers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncJobOffers = /* GraphQL */ `
  query SyncJobOffers(
    $filter: ModelJobOfferFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncJobOffers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const searchJobOffers = /* GraphQL */ `
  query SearchJobOffers(
    $filter: SearchableJobOfferFilterInput
    $sort: [SearchableJobOfferSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableJobOfferAggregationInput]
  ) {
    searchJobOffers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        owner
        company {
          id
          owner
          name
          location {
            lat
            lon
            __typename
          }
          addressText
          phonenumber
          email
          logo
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        name
        description
        visibleInSearch
        workTimeRegulationId
        workTimeRegulation {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        jobCategoryId
        jobCategory {
          id
          name
          color
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        positionLevelId
        positionLevel {
          id
          name
          translations {
            languageCode
            text
            __typename
          }
          active
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        location {
          lat
          lon
          __typename
        }
        addressText
        salary {
          salary
          currencyCode
          timeUnit
          __typename
        }
        hours {
          hours
          timeUnit
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
              __typename
            }
          }
        }
        __typename
      }
      __typename
    }
  }
`;
