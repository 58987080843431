import { DatePickerInput as DatePickerInputMantine } from "@mantine/dates";
import { IconCalendar } from "@tabler/icons";
import moment, { formatDate, getDateFormat } from "../helpers/Moment";
import { useTranslation } from 'react-i18next';
import i18n from '../helpers/i18n';

/**
 * wrapper for date input in forms
 * @param {object} props component props
 * @returns JSX
 */
export default function DatePickerInput(props) {

    // globals
    const { t } = useTranslation();

    /**
     * handler for date changes, automatically formats date
     * @param {date} e 
     */
    const onChange = (e) => {
        var values = {};

        if (!e) {
            // if no date was selected return empty string
            values[props.inputKey] = "";
        }
        else {
            // if a date was selected, format it
            values[props.inputKey] = formatDate(e, "YYYY-MM-DD");
        }

        props.form.setValues({ ...values });
    }

    /**
     * maps the value from the field to a date for the picker
     * @returns Date or empty string
     */
    const getValueAsDate = () => {
        const value = props.form.values[props.inputKey];

        // check if not set
        if (!value) {
            return null;
        }

        // otherwise format date and return
        return moment(value).toDate();
    }

    return (
        <DatePickerInputMantine
            withAsterisk={props.withAsterisk}
            label={props.label ? props.label : t("date.date")}
            placeholder={props.placeholder ? props.placeholder : t("date.pick")}
            locale={i18n.language}
            valueFormat={getDateFormat()}
            leftSection={<IconCalendar size={16} />}
            {...props.form.getInputProps('phonenumber')}
            onChange={(e) => onChange(e)}
            value={getValueAsDate()}
            defaultLevel="year"
        />
    )
}