import React, { useEffect, useState } from 'react';
import { Select } from '@mantine/core';
import { ERROR_SHOW, useErrorDispatch } from '../helpers/GlobalErrorState';
import { DataStore, SortDirection } from '@aws-amplify/datastore';
import { Language } from '../models';

/**
 * generic model select input
 */
const LanguageSelectInput = (props) => {

    // globals
    const [items, setItems] = useState([]);
    const setError = useErrorDispatch();

    /**
     * fetches the model entries from db
     * @returns array of results
     */
    const fetchData = async () => {
        try {
            // get items
            const items = await DataStore.query(Language,
                p => p.active.eq(true),
                { sort: s => s.nameNative(SortDirection.ASCENDING) }
            );

            const mappedItems = items.map((e) => {
                return { value: e.code, label: `${e.nameNative} (${e.code})` };
            })

            // set items
            setItems(mappedItems);
        }
        catch (e) {
            setError({ action: ERROR_SHOW, error: e });
        }
    };

    /**
     * use effect hook to listen to fetch data
     */
    useEffect(() => {
        fetchData();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <Select
            searchable
            data={items || []}
            {...props}
        />
    );
}

// Default export of the class
export default LanguageSelectInput;