import { ActionIcon, Button, HoverCard, Stack, Text } from "@mantine/core";
import { DataStore, SortDirection } from "aws-amplify";
import { useEffect, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useTranslation } from "react-i18next"
import { ERROR_SHOW, useErrorDispatch } from "../helpers/GlobalErrorState";
import { CompanyUser, Language, User } from "../models";
import { changeLanguage } from "i18next";
import i18next, { fallbackLanguage } from "../helpers/i18n";
import { useUserState } from "../helpers/GlobalUserState";
import { LOADING_RESET, LOADING_SHOW, useLoadingDispatch } from "../helpers/GlobalLoadingState";
import { USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_USER } from "../helpers/Constants";

export default function LanguageButton() {

    // globals
    const { t } = useTranslation();
    const [languages, setLanguages] = useState([]);
    const [activeLanguage, setActiveLanguage] = useState("GB");
    const setError = useErrorDispatch();
    const setLoading = useLoadingDispatch();
    const subscription = useRef(null);
    const user = useUserState();

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        subscription.current = DataStore.observeQuery(
            Language,
            p => p.active.eq(true),
            { sort: s => s.nameNative(SortDirection.ASCENDING) }
        ).subscribe({
            next(snapshot) {
                setLanguages(snapshot.items);
            },
            error(err) {
                setError({ action: ERROR_SHOW, error: err });
            }
        });

        return () => {
            subscription.current?.unsubscribe();
            subscription.current = null;
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    /**
     * Use effect hook to list for language change
     */
    useEffect(() => {
        i18next.on('languageChanged', () => {
            setCurrentLanguage();
        });

        setCurrentLanguage();
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );


    /**
     * fetches the languages from backend which are registered by i18n and sets the first found as current language
     */
    const setCurrentLanguage = async () => {
        try {
            // set loading
            setLoading(LOADING_SHOW);

            // default language
            var newLanguage = fallbackLanguage;

            // change language on UI
            for (let index = 0; index < i18next.languages.length; index++) {
                var locale = i18next.languages[index];
                const language = await DataStore.query(Language, locale);
                if (language) {
                    newLanguage = language.code;
                    setActiveLanguage(language.countryCode);
                    break;
                }
            }

            // update language in user profile
            if (user.id && (user.userGroup === USERGROUP_COMPANY || user.userGroup === USERGROUP_COMPANYADMIN)) {
                const original = await DataStore.query(CompanyUser, user.id);
                if (original && original.language !== newLanguage) {
                    await DataStore.save(CompanyUser.copyOf(original, updated => {
                        updated.language = newLanguage;
                    }));
                }
            }
            else if (user.id && user.userGroup === USERGROUP_USER) {
                const original = await DataStore.query(User, user.id);
                if (original && original.language !== newLanguage) {
                    await DataStore.save(User.copyOf(original, updated => {
                        updated.language = newLanguage;
                    }));
                }
            }
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
        finally {
            setLoading(LOADING_RESET);
        }

    }

    return (
        <HoverCard
            shadow="md"
            withArrow
            arrowPosition="center"
            position="top-start"
        >
            <HoverCard.Target>
                <ActionIcon variant="default" size={32} bg="transparent" className="border-gray">
                    <ReactCountryFlag
                        countryCode={activeLanguage}
                        svg
                        style={{
                            fontSize: "24px",
                            lineHeight: "24px",
                        }}
                    />
                </ActionIcon>
            </HoverCard.Target>
            <HoverCard.Dropdown>
                <Stack gap={5}>
                    <Text c="dimmed" size="xs">{t("language.select")}</Text>
                    {languages.map(language => (
                        <Button
                            key={language.code}
                            variant="default"
                            size="xs"
                            leftSection={
                                <ReactCountryFlag
                                    countryCode={language.countryCode}
                                    svg
                                    style={{
                                        fontSize: "20px",
                                        lineHeight: "20px",
                                    }}
                                />
                            }
                            styles={() => ({
                                inner: {
                                    justifyContent: "flex-start"
                                }
                            })}
                            onClick={() => {
                                changeLanguage(language.code);
                            }}
                        >
                            {`${language.nameNative} (${language.code})`}
                        </Button>
                    ))}
                </Stack>
            </HoverCard.Dropdown>
        </HoverCard>
    )
}