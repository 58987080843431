import { Input, Slider } from "@mantine/core"
import InputBackground from "./InputBackground";

/**
 * input wrapper for slider
 * @param {object} props component props
 * @returns JSX
 */
const SliderInput = (props) => {
    return (
        <Input.Wrapper error={props.error}>
            <Input.Label>{props.name}</Input.Label>
            <InputBackground 
                error={props.error}
                style={{
                    paddingBottom: props.marks ? "25px" : "12px",
                    paddingTop: props.labelAlwaysOn ? "38px" : "12px",
                }}
            >
                <Slider
                    style={{ height: "20px" }}
                    {...props}
                />
            </InputBackground>
            {props.description &&
                <Input.Description>{props.description}</Input.Description>
            }
        </Input.Wrapper>
    )
}

// Default export of the class
export default SliderInput;