import { Button, Card, Group, Image, SimpleGrid, Skeleton, Stack, Text, Title, useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Search } from "tabler-icons-react";
import { nearbyJobOffers } from "../../graphql/queries";
import { USERGROUP_ADMIN, USERGROUP_COMPANY, USERGROUP_COMPANYADMIN, USERGROUP_SUPPORT, USERGROUP_USER } from "../../helpers/Constants";
import { ROUTE_COMPANY_HOME, ROUTE_JOBS, ROUTE_SIGNIN, ROUTE_SIGNUP, ROUTE_SUPPORT_HOME, ROUTE_USER_HOME } from "../../helpers/Routes";
import { ERROR_SHOW, useErrorDispatch } from "../../helpers/GlobalErrorState";
import { useUserState } from "../../helpers/GlobalUserState";
import image_looking_for_job from "../../images/image_looking_for_job.jpg";
import image_work_together from "../../images/image_work_together.jpg";
import { useTranslation } from 'react-i18next';
import JobsCarousel from "../../components/JobsCarousel";

/**
 * general home page without login
 * @returns JSX
 */
export default function PageGeneralHome() {

    // globals
    const theme = useMantineTheme();
    const user = useUserState();
    const navigate = useNavigate();
    const [jobs, setJobs] = useState(undefined);
    const setError = useErrorDispatch();
    const mediaQueryLargerSm = useMediaQuery(`(min-width: ${theme.breakpoints.sm}`, true);
    const { t } = useTranslation();

    /** 
     * use effect hook to fetch data
     */
    useEffect(() => {
        if (user.userGroup === USERGROUP_USER) {
            navigate(ROUTE_USER_HOME);
        }
        else if (user.userGroup === USERGROUP_COMPANY || user.userGroup === USERGROUP_COMPANYADMIN) {
            navigate(ROUTE_COMPANY_HOME);
        }
        else if (user.userGroup === USERGROUP_SUPPORT || user.userGroup === USERGROUP_ADMIN) {
            navigate(ROUTE_SUPPORT_HOME);
        }
        else {
            fetchData();
        }
    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [user.id]
    );

    /**
     * wrapper to fetch data for page
     */
    const fetchData = async () => {
        try {
            // fetch data
            const jobs = await fetchJobs();

            // set data
            setJobs(jobs);
        }
        catch (err) {
            setError({ action: ERROR_SHOW, error: err });
        }
    }

    /**
     * fetches the latest jobs
     */
    const fetchJobs = async () => {
        const result = await API.graphql({
            query: nearbyJobOffers,
            variables: {
                sortId: "created_desc",
                limit: 20,
            },
            authMode: user.id ? null : 'AWS_IAM'
        });
        return result.data.nearbyJobOffers.items;
    }

    /**
     * renders the card
     * @param {object} image the image to render
     * @param {string} title title for card
     * @param {string} text the description for the card
     * @param {string} buttonText button text
     * @param {string} linkTo link to navigate to
     */
    const renderCard = (image, title, text, buttonText, linkTo) => {
        return (
            <Card
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column"
                }}
                shadow="md"
                radius="md"
                withBorder
            >
                <Card.Section>
                    <Image
                        src={image}
                        height={mediaQueryLargerSm ? 300 : 160}
                    />
                </Card.Section>
                <Text mt="md" mb="xs" weight={500}>{title}</Text>
                <Text size="sm" c="dimmed" mb="md">{text}</Text>
                <Link style={{ marginTop: "auto" }} to={linkTo}><Button variant="light" color="blue" fullWidth radius="md">{buttonText}</Button></Link>
            </Card>
        );
    }

    // if still loading, show skeletons
    if (!jobs) {
        return (
            <Stack>
                <Skeleton height={36} />
                <Skeleton height={415} />
                <SimpleGrid cols={{ base: 1, md: 2, lg: 2 }}>
                    <Skeleton height={332} />
                    <Skeleton height={332} />
                </SimpleGrid>
            </Stack>
        );
    }

    return (
        <Stack>
            <Group justify="space-between">
                <Title order={3}>{t("job.new")}</Title>
                <Link to={ROUTE_JOBS}><Button leftSection={<Search size={14} />} color="blue" variant="outline">{t("job.find")}</Button></Link>
            </Group>
            <JobsCarousel jobs={jobs} />

            <Title mt="md" order={3}>{t("auth.signin.text")}</Title>
            <SimpleGrid
                cols={{ base: 1, md: 1, lg: 2 }}
                spacing="md"
            >
                {renderCard(
                    image_work_together,
                    t("auth.signup.question"),
                    t("auth.signup.description"),
                    t("auth.signup.navigate"),
                    ROUTE_SIGNUP
                )}
                {renderCard(
                    image_looking_for_job,
                    t("auth.signin.question"),
                    t("auth.signin.description"),
                    t("auth.signin.navigate"),
                    ROUTE_SIGNIN
                )}
            </SimpleGrid>
        </Stack>
    )
}